import AppListHeader from "@/features/ui/app-list-header";
import React, { use, useCallback, useEffect, useMemo, useState } from "react";
import useIdentity from "@/features/ui/hooks/use-identity";
import { PersistedLineSheetSetDetailPageQuery } from "@/features/line-sheet-sets/detail/use-persisted-line-sheet-sheet-set-detail-page-query";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { downloadUnoFileKey } from "@/hooks/use-download-uno-file";
import { FormikProps } from "formik";
import {
  isLineSheetFormField,
  isOrderSheetFormField,
  LineSheetSetFormFields,
} from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import AppButton from "@/features/line-sheet-sets/app-button";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useUpdateOrderSheetSetStatusInfinite from "@/features/order-status/hooks/use-update-order-sheet-set-status-infinite";
import { KeyedMutator } from "swr";
import { GetDenseLineSheetSetResponse } from "@/features/line-sheet-sets/hooks/use-get-dense-line-sheet-set";
import { useLineSheetSetDumbTransaction } from "@/features/line-sheet-sets/hooks/use-line-sheet-set-dumb-transaction";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useReportLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-report-line-sheet-set-infinite";
import useReparseLineSheetSet from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet-set";
import LineSheetSetReviewStatusUpdater from "@/features/line-sheet-sets/line-sheet-set-review-status-updater";
import OrderSheetStatusUpdater, {
  UpdateOrderSheetSetStatusItem,
  UpdateOrderSheetStatusItem,
} from "@/features/order-status/order-sheet-status-updater";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import OrderOrderSheetSetModal, {
  OrderOrderSheetSetModalProps,
} from "@/features/order-sheet-sets/order-order-sheet-set-modal";
import { mutateIfMatched } from "@/features/ui/utils/swr-utils";
import useDeleteOrderSheetSet from "@/features/order-sheet-sets/hooks/use-delete-order-sheet-set";
import useAppDialog from "@/features/ui/hooks/use-app-dialog";
import useBulkUpdateOrderSheetStatus from "@/features/order-status/hooks/use-bulk-update-order-sheet-status";
import AppCompanySelect from "../app-company-select";
import { toTitle } from "@/utils/case";
import { t } from "i18next";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import AppSeasonMultiSelect from "../app-season-multi-select";

interface LineSheetSetDetailPageHeaderProps {
  initialQuery: PersistedLineSheetSetDetailPageQuery;
  formik: FormikProps<LineSheetSetFormFields>;
  mutate: KeyedMutator<GetDenseLineSheetSetResponse>;
  onPublishClick: () => void;
  isEditing: boolean;
}

export default function LineSheetSetDetailPageHeader({
  initialQuery,
  formik,
  mutate,
  isEditing,
  onPublishClick,
}: LineSheetSetDetailPageHeaderProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { tTitle, tSentence } = useI18nHelper();
  const { navigate } = useRouterHelper();
  const { openDialog } = useAppDialog();

  const emptyStackPage = useMemo(() => {
    const companyType = identity?.company?.type;
    const infer = identity?.company?.type === "BUYER";
    if (companyType === "AGENCY") {
      const isOrderSheetSet = infer || initialQuery.orderSheetSetId;
      if (isOrderSheetSet) {
        return "/order-sheets";
      } else {
        return "/line-sheets";
      }
    } else if (companyType === "BUYER") {
      return "/line-sheets";
    }
  }, [identity?.company?.type, initialQuery.orderSheetSetId]);

  const [query, setQuery] =
    useTypedSearchParams<PersistedLineSheetSetDetailPageQuery>();
  const [isQuerySet, setIsQuerySet] = useState(false);

  useEffect(() => {
    if (!isQuerySet && !query && initialQuery) {
      setQuery(initialQuery);
      setIsQuerySet(true);
    }
  }, [initialQuery, query, setQuery]);

  // API : Download Uno File
  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    clear: clearDownloadUnoFile,
  } = useDownloadUnoFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      downloadUnoFileData,
      clearDownloadUnoFile,
      showError,
      () => {}
    );
  }, [downloadUnoFileData, clearDownloadUnoFile, showError]);

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId: unoFileId,
      });
    },
    [downloadUnoFileData]
  );

  // END: API : Download Uno File

  // --- API: Update Order Sheet Set Status ---
  const {
    fire: fireUpdateOrderSheetSetStatus,
    data: updateOrderSheetSetStatusData,
    clear: clearUpdateOrderSheetSetStatus,
    error: updateOrderSheetSetStatusError,
  } = useUpdateOrderSheetSetStatusInfinite();

  useEffect(() => {
    if (updateOrderSheetSetStatusError) {
      showError(updateOrderSheetSetStatusError);
    }
  }, [showError, updateOrderSheetSetStatusError]);

  useEffect(() => {
    postProcessYWRInfiniteData(
      updateOrderSheetSetStatusData,
      clearUpdateOrderSheetSetStatus,
      showError,
      (response) => {
        mutate();
      }
    );
  }, [
    updateOrderSheetSetStatusData,
    clearUpdateOrderSheetSetStatus,
    showError,
    mutate,
  ]);

  // --- END: API: Update Order Sheet Set Status ---

  const {
    isLoading: isDeleteOrderSheetSetLoading,
    fire: fireDeleteOrderSheetSet,
    data: fireDeleteOrderSheetSetData,
    error: fireDeleteOrderSheetSetError,
    clear: clearDeleteOrderSheetSet,
  } = useDeleteOrderSheetSet();

  useEffect(() => {
    if (fireDeleteOrderSheetSetError) {
      showError(fireDeleteOrderSheetSetError);
    }
  }, [showError, fireDeleteOrderSheetSetError]);

  // API: Force Parsing Fail Line Sheet Set
  const {
    data: reportLineSheetSetData,
    isLoading: isReportLineSheetSetLoading,
    fire: fireReportLineSheetSet,
    clear: clearReportLineSheetSet,
    error: reportLineSheetSetError,
  } = useReportLineSheetSetInfinite();

  useEffect(() => {
    if (reportLineSheetSetError) {
      showError(reportLineSheetSetError);
    }
  }, [showError, reportLineSheetSetError]);

  useEffect(() => {
    postProcessYWRInfiniteData(
      reportLineSheetSetData,
      clearReportLineSheetSet,
      showError,
      () => {
        mutate();
      }
    );
  }, [reportLineSheetSetData, clearReportLineSheetSet, showError, mutate]);

  // END: API: Force Parsing Fail Line Sheet Set

  // API: Reparse Line Sheet Set
  const {
    fire: fireReparseLineSheetSet,
    isLoading: isReparseLineSheetSetLoading,
    data: reparseLineSheetSetData,
    error: reparseLineSheetSetError,
    clear: clearReparseLineSheetSet,
  } = useReparseLineSheetSet();

  useEffect(() => {
    if (reparseLineSheetSetError) {
      showError(reparseLineSheetSetError);
    }
  }, [showError, reparseLineSheetSetError]);

  useEffect(() => {
    if (reparseLineSheetSetData) {
      mutate();
      clearReparseLineSheetSet();
    }
  }, [clearReparseLineSheetSet, mutate, reparseLineSheetSetData]);

  // END: API: Reparse Line Sheet Set

  const downloadOrderSheetSetAction = useMemo(() => {
    const orderSheetSet = formik.values.orderSheetSet;

    const unoFileId = orderSheetSet?.compressedExportedFile?.id;

    if (unoFileId) {
      return (
        <AppButton
          key={"LineSheetSetDetailAction_OrderSheetSetDownload"}
          variant={"white"}
          isLoading={findDownloadUnoFileSegment(unoFileId)?.isLoading || false}
          isDisabled={!orderSheetSet?.compressedExportedFile}
          onClick={() => {
            fireDownloadUnoFile(
              {
                unoFileId: unoFileId,
              },
              {}
            );
          }}
        >
          {tTitle("line_sheet_download")}
        </AppButton>
      );
    }
  }, [
    findDownloadUnoFileSegment,
    fireDownloadUnoFile,
    formik.values.orderSheetSet,
    tTitle,
  ]);

  const downloadLineSheetSetAction = useMemo(() => {
    const unoFileId = formik.values.compressedImportedFile?.id;

    if (unoFileId) {
      return (
        <AppButton
          key={"LineSheetSetDetailAction_OriginDownload"}
          variant={"white"}
          isLoading={findDownloadUnoFileSegment(unoFileId)?.isLoading || false}
          isDisabled={!unoFileId}
          onClick={() => {
            fireDownloadUnoFile(
              {
                unoFileId: unoFileId,
              },
              {}
            );
          }}
        >
          {tTitle("origin_download")}
        </AppButton>
      );
    }
  }, [
    findDownloadUnoFileSegment,
    fireDownloadUnoFile,
    formik.values.compressedImportedFile?.id,
    tTitle,
  ]);

  const deleteOrderSheetSetAction = useMemo(() => {
    const orderSheetSetId = formik.values.orderSheetSet?.id;
    const lineSheetSetId = formik.values.id;
    const orderSheetSetStatus = formik.values.orderSheetSet?.status;
    const selectedOrderSheetIds = formik.values.lineSheetOrAttachmentList
      .filter(isOrderSheetFormField)
      .filter((i) => i.isChecked && ["WORKING_DRAFT"].includes(i.status))
      .map((i) => i.id);

    if (
      identity?.company?.type === "BUYER" &&
      orderSheetSetId &&
      orderSheetSetStatus &&
      ["WORKING_DRAFT"].includes(orderSheetSetStatus)
    ) {
      const selectedOrderSheetStatus = formik.values.lineSheetOrAttachmentList
        .filter(isOrderSheetFormField)
        .filter((i) => i.isChecked)
        .map((i) => i.status);
      const isAllSameStatus = selectedOrderSheetStatus.every(
        (status) => status === selectedOrderSheetStatus[0]
      );
      return (
        <AppButton
          key={"LineSheetSetDetailAction_Delete"}
          variant={"white"}
          isDisabled={selectedOrderSheetIds.length === 0 || !isAllSameStatus}
          onClick={async () => {
            const result = await openDialog({
              title: tTitle("delete_order_sheet"),
              message: tTitle("common:delete_order_sheet.message"),
              confirmName: tTitle("delete.do"),
            });

            if (!result) {
              return;
            }

            fireDeleteOrderSheetSet(
              {
                buyerId: identity!.company!.id,
                orderSheetSetId,
              },
              {
                orderSheetIds: selectedOrderSheetIds,
              }
            );
            mutate();

            clearDeleteOrderSheetSet();
          }}
        >
          {tTitle("common:delete_order_sheet")}
        </AppButton>
      );
    }
  }, [
    clearDeleteOrderSheetSet,
    fireDeleteOrderSheetSet,
    formik.values.id,
    formik.values.lineSheetOrAttachmentList,
    formik.values.orderSheetSet?.id,
    formik.values.orderSheetSet?.status,
    identity,
    mutate,
    openDialog,
    tTitle,
  ]);

  const {
    isLoading: isBulkUpdateOrderSheetStatusLoading,
    data: bulkUpdateOrderSheetStatusData,
    error: bulkUpdateOrderSheetStatusError,
    fire: fireBulkUpdateOrderSheetStatus2,
    clear: clearBulkUpdateOrderSheetSetStatus,
  } = useBulkUpdateOrderSheetStatus();

  useEffect(() => {
    if (bulkUpdateOrderSheetStatusError) {
      showError(bulkUpdateOrderSheetStatusError);
    } else if (bulkUpdateOrderSheetStatusData) {
      mutate();
    }
  }, [
    bulkUpdateOrderSheetStatusData,
    bulkUpdateOrderSheetStatusError,
    mutate,
    showError,
  ]);

  const cancelOrderAction = useMemo(() => {
    const orderSheetSetId = formik.values.orderSheetSet?.id;
    const orderSheetSetStatus = formik.values.orderSheetSet?.status;
    const selectedOrderSheetIds = formik.values.lineSheetOrAttachmentList
      .filter(isOrderSheetFormField)
      .filter((i) => i.isChecked && ["ORDERED_TO_AGENCY"].includes(i.status))
      .map((i) => i.id);

    if (
      identity?.company?.type === "BUYER" &&
      orderSheetSetId &&
      orderSheetSetStatus &&
      selectedOrderSheetIds.length > 0
    ) {
      const selectedOrderSheetStatus = formik.values.lineSheetOrAttachmentList
        .filter(isOrderSheetFormField)
        .filter((i) => i.isChecked)
        .map((i) => i.status);
      const isAllSameStatus = selectedOrderSheetStatus.every(
        (status) => status === selectedOrderSheetStatus[0]
      );
      return (
        <AppButton
          key={"LineSheetSetDetailAction_Cancel"}
          variant={"white"}
          isDisabled={selectedOrderSheetIds.length === 0 || !isAllSameStatus}
          onClick={() => {
            fireBulkUpdateOrderSheetStatus2(
              {
                by: identity!.company!.type,
                companyId: identity!.company!.id,
                orderSheetSetId,
              },
              {
                status: "WORKING_DRAFT",
                orderSheetIds: selectedOrderSheetIds,
              }
            );
          }}
        >
          {tTitle("common:cancel_submit")}
        </AppButton>
      );
    }
  }, [fireBulkUpdateOrderSheetStatus2, formik, identity, tTitle]);

  const saveLineSheetSetAction = useMemo(() => {
    if (isEditing) {
      return (
        <AppButton
          key={"LineSheetSetDetailAction_Save"}
          variant={"primary"}
          isLoading={formik.isSubmitting}
          isDisabled={!formik.dirty}
          onClick={() => {
            formik.submitForm();
          }}
        >
          {tTitle("common:save")}
        </AppButton>
      );
    }
  }, [formik, isEditing, tTitle]);

  const { isUpdateLineSheetSetQueued } = useLineSheetSetDumbTransaction();

  const editLineSheetSetAction = useMemo(() => {
    const isPublished = !!formik.values.publishedAt;
    const isDeleted = formik.values.isDeleted;
    const lineSheetSetId = formik.values.id;

    if (
      !isEditing &&
      !isPublished &&
      !isDeleted &&
      identity?.company?.type === "AGENCY" &&
      lineSheetSetId
    ) {
      return (
        <AppButton
          key={"LineSheetSetDetailAction_Edit"}
          isLoading={isUpdateLineSheetSetQueued({
            agencyId: identity!.company!.id,
            lineSheetSetId,
          })}
          // isDisabled={props.values.lineSheetOrAttachmentList
          //   .filter(isLineSheetFormField)
          //   .some((field) => field.status === "PARSING_PENDING")}
          onClick={() => {
            navigate(`/line-sheet-sets/${lineSheetSetId}/edit`, {
              appendReferer: true,
            });
          }}
          variant={"white"}
        >
          {tTitle("edit")}
        </AppButton>
      );
    }
  }, [
    formik.values.id,
    formik.values.isDeleted,
    formik.values.publishedAt,
    identity,
    isEditing,
    isUpdateLineSheetSetQueued,
    navigate,
    tTitle,
  ]);

  const forceParseFailedAction = useMemo(() => {
    const isPublished = !!formik.values.publishedAt;
    const isDeleted = formik.values.isDeleted;
    const lineSheetSetId = formik.values.id;

    if (
      identity?.company?.type === "AGENCY" &&
      !isPublished &&
      !isDeleted &&
      lineSheetSetId
    ) {
      const isAnyParsed = formik.values.lineSheetOrAttachmentList
        .filter(isLineSheetFormField)
        .filter((field) => field.type === "LINE_SHEET")
        .some((field) => field.status === "PARSED");

      if (isAnyParsed) {
        return (
          <AppButton
            key={"LineSheetSetDetailAction_ForceParseFailed"}
            variant={"white"}
            isLoading={isReportLineSheetSetLoading}
            onClick={() => {
              fireReportLineSheetSet(
                {
                  agencyId: identity!.company?.id!,
                  lineSheetSetId,
                },
                {}
              );
            }}
          >
            {tTitle("common:force_parsing_failed")}
          </AppButton>
        );
      }
    }
  }, [
    fireReportLineSheetSet,
    formik.values.id,
    formik.values.isDeleted,
    formik.values.lineSheetOrAttachmentList,
    formik.values.publishedAt,
    identity,
    isReportLineSheetSetLoading,
    tTitle,
  ]);

  const reparseLineSheetSetAction = useMemo(() => {
    const isPublished = !!formik.values.publishedAt;
    const isDeleted = formik.values.isDeleted;
    const lineSheetSetId = formik.values.id;
    const status = formik.values.status;
    if (
      identity?.company?.type === "AGENCY" &&
      (status === "PARSING_PENDING" || status === "PARSING_FAILED") &&
      !isPublished &&
      !isDeleted &&
      lineSheetSetId
    ) {
      return (
        <AppButton
          key={"LineSheetSetDetailAction_Reparse"}
          isLoading={isReparseLineSheetSetLoading}
          onClick={() => {
            fireReparseLineSheetSet(
              {
                agencyId: identity!.company!.id,
                lineSheetSetId,
              },
              {}
            );
          }}
        >
          {tTitle("reparse")}
        </AppButton>
      );
    }
  }, [
    fireReparseLineSheetSet,
    formik.values.id,
    formik.values.isDeleted,
    formik.values.publishedAt,
    formik.values.status,
    identity,
    isReparseLineSheetSetLoading,
    tTitle,
  ]);

  const lineSheetSetReviewStatusUpdater = useMemo(() => {
    const isPublished = !!formik.values.publishedAt;
    const isDeleted = formik.values.isDeleted;
    const lineSheetSetId = formik.values.id;

    if (
      identity?.company?.type === "AGENCY" &&
      !isPublished &&
      !isDeleted &&
      lineSheetSetId
    ) {
      return (
        <LineSheetSetReviewStatusUpdater
          key={"LineSheetSetDetailAction_ReviewStatusUpdater"}
          lineSheetSetId={lineSheetSetId}
          onUpdate={(response) => {
            mutate();
          }}
        />
      );
    }
  }, [
    formik.values.id,
    formik.values.isDeleted,
    formik.values.publishedAt,
    identity?.company?.type,
    mutate,
  ]);

  const publishLineSheetSetAction = useMemo(() => {
    const isPublished = !!formik.values.publishedAt;
    const isDeleted = formik.values.isDeleted;
    const lineSheetSetId = formik.values.id;
    const status = formik.values.status;
    const compressionStatus = formik.values.compressionStatus;

    if (
      !isEditing &&
      !isDeleted &&
      !isPublished &&
      lineSheetSetId &&
      identity?.company?.type === "AGENCY"
    ) {
      return (
        <AppButton
          key={"LineSheetSetDetailAction_Publish"}
          variant={"primary"}
          isDisabled={
            status === undefined ||
            status === "PARSING_PENDING" ||
            compressionStatus !== "COMPLETED"
          }
          onClick={() => {
            onPublishClick();
          }}
        >
          {tTitle("publish")}
        </AppButton>
      );
    }
  }, [
    formik.values.id,
    formik.values.isDeleted,
    formik.values.publishedAt,
    formik.values.status,
    formik.values.compressionStatus,
    identity?.company?.type,
    isEditing,
    onPublishClick,
    tTitle,
  ]);

  const updateOrderSheetStatusItem = useMemo(():
    | UpdateOrderSheetStatusItem[]
    | UpdateOrderSheetSetStatusItem[] => {
    const orderSheetSet = formik.values.orderSheetSet;
    const issuedByBoutique = formik.values.issuedByBoutique;
    const lineSheetOrAttachmentList = formik.values.lineSheetOrAttachmentList;

    if (orderSheetSet && issuedByBoutique) {
      const isAllChecked = lineSheetOrAttachmentList
        .filter(isOrderSheetFormField)
        .filter((i) => i.isCheckable)
        .every((i) => i.isChecked);

      if (isAllChecked) {
        return [
          {
            id: orderSheetSet.id,
            status: orderSheetSet.status,
            createdByCompany: orderSheetSet.createdByCompany,
            orderSheets: orderSheetSet.orderSheets
              .filter((orderSheet) => {
                return lineSheetOrAttachmentList
                  .filter(isOrderSheetFormField)
                  .filter((i) => i.isCheckable)
                  .find((i) => i.id === orderSheet.id)?.lineSheet;
              })
              .map((orderSheet) => {
                const lineSheet = lineSheetOrAttachmentList
                  .filter(isOrderSheetFormField)
                  .find((i) => i.id === orderSheet.id)!.lineSheet;

                return {
                  id: orderSheet.id,
                  name: lineSheet.name,
                  confirmedAmount: orderSheet.confirmedAmount,
                  confirmedQuantity: orderSheet.confirmedQuantity,
                  orderedAmount: orderSheet.orderedAmount,
                  orderedQuantity: orderSheet.orderedQuantity,
                  issuedBy: issuedByBoutique,
                  lineSheet: {
                    id: lineSheet.id,
                    status: lineSheet.status!,
                  },
                  toBeSubmitted: orderSheet.toBeSubmitted,
                };
              }),
          },
        ];
      } else {
        return lineSheetOrAttachmentList
          ?.filter(isOrderSheetFormField)
          .filter((i) => i.isChecked)
          .map((i): UpdateOrderSheetStatusItem => {
            return {
              name: i.lineSheet.name,
              status: i.status,
              lineSheet: i.lineSheet,
              id: i.id,
              orderSheetSetId: orderSheetSet.id,
              createdByCompany: orderSheetSet.createdByCompany,
              toBeSubmitted: i.toBeSubmitted,
              issuedBy: issuedByBoutique,
            };
          });
      }
    }
    return [];
  }, [
    formik.values.issuedByBoutique,
    formik.values.lineSheetOrAttachmentList,
    formik.values.orderSheetSet,
  ]);

  const orderSheetStatusUpdater = useMemo(() => {
    const orderSheetSet = formik.values.orderSheetSet;

    if (identity?.company?.type === "AGENCY" && orderSheetSet) {
      return (
        <OrderSheetStatusUpdater
          key={"LineSheetSetDetailAction_OrderSheetStatusUpdater"}
          items={updateOrderSheetStatusItem}
          onUpdate={() => {
            mutate();
          }}
        />
      );
    }
  }, [
    formik.values.orderSheetSet,
    identity?.company?.type,
    mutate,
    updateOrderSheetStatusItem,
  ]);

  const selectedOrderSheetIds = useMemo(() => {
    return formik.values.lineSheetOrAttachmentList
      .filter(isOrderSheetFormField)
      .filter(
        (i) =>
          i.isChecked &&
          ["WORKING_DRAFT", "RESUBMIT_REQUIRED"].includes(i.status)
      )
      .map((i) => i.id);
  }, [formik.values.lineSheetOrAttachmentList]);

  const {
    isOpen: isOrderOrderSheetSetModalOpen,
    onOpen: onOpenOrderOrderSheetSetModal,
    onClose: onCloseOrderOrderSheetSetModal,
    data: orderOrderSheetSetModalData,
    openWithData: openOrderOrderSheetSetModalWithData,
  } = useDisclosureWithData<OrderOrderSheetSetModalProps>();

  const orderAction = useMemo(() => {
    if (
      identity?.company?.type === "BUYER" &&
      formik.values.orderSheetSet?.orderSheets.some(
        (i) => i.status === "WORKING_DRAFT" || i.status === "RESUBMIT_REQUIRED"
      )
    ) {
      const selectedOrderSheetStatus = formik.values.lineSheetOrAttachmentList
        .filter(isOrderSheetFormField)
        .filter((i) => i.isChecked)
        .map((i) => i.status);
      const isAllSameStatus = selectedOrderSheetStatus.every(
        (status) => status === selectedOrderSheetStatus[0]
      );
      if (
        isAllSameStatus &&
        selectedOrderSheetStatus[0] === "ORDERED_TO_AGENCY"
      ) {
        return;
      }
      return (
        <AppButton
          key={"LineSheetSetDetailAction_Order"}
          variant={"primary"}
          isDisabled={selectedOrderSheetIds.length === 0 || !isAllSameStatus}
          onClick={() => {
            openOrderOrderSheetSetModalWithData((prev) => {
              return {
                orderSheetSetId: formik.values.orderSheetSet!.id,
                orderSheetIds: selectedOrderSheetIds,
                onSubmit: () => {
                  mutate();
                  mutateIfMatched(
                    new RegExp(
                      `^(?:/)(buyers|agencies|boutiques)/(\\d+)/dense-?order-sheet-sets/${
                        formik.values.orderSheetSet!.id
                      }$`
                    )
                  );
                },
              };
            });
          }}
          disabledMessage={tSentence(
            "order_sheet_sets:disabled_button_to_submit_order_sheet.message"
          )}
        >
          {tTitle("submit")}
        </AppButton>
      );
    }
  }, [
    formik.values.lineSheetOrAttachmentList,
    formik.values.orderSheetSet,
    identity?.company?.type,
    mutate,
    openOrderOrderSheetSetModalWithData,
    selectedOrderSheetIds,
    tTitle,
  ]);

  const actions = useMemo(() => {
    return [
      saveLineSheetSetAction,
      editLineSheetSetAction,
      downloadLineSheetSetAction,
      forceParseFailedAction,
      reparseLineSheetSetAction,
      lineSheetSetReviewStatusUpdater,
      publishLineSheetSetAction,
      downloadOrderSheetSetAction,
      deleteOrderSheetSetAction,
      orderSheetStatusUpdater,
      orderAction,
      cancelOrderAction,
    ];
    //
  }, [
    deleteOrderSheetSetAction,
    cancelOrderAction,
    downloadLineSheetSetAction,
    downloadOrderSheetSetAction,
    editLineSheetSetAction,
    forceParseFailedAction,
    lineSheetSetReviewStatusUpdater,
    orderAction,
    orderSheetStatusUpdater,
    publishLineSheetSetAction,
    reparseLineSheetSetAction,
    saveLineSheetSetAction,
  ]);

  return (
    <>
      <AppListHeader
        filters={
          [
            // <AppCompanySelect
            //   key={"LineSheetSetDetailPageFilter_Brand"}
            //   name={toTitle(t("common:brand"))}
            //   types={["BRAND"]}
            //   id={query["madeByCompany.id__in"]}
            //   isMultiple={true}
            //   onSelect={(companies) => {
            //     setQuery((prev) => {
            //       return {
            //         ...prev,
            //         "madeByCompany.id__in": companies?.map(
            //           (company) => company.id
            //         ),
            //         pageNumber: 1,
            //       };
            //     });
            //   }}
            // />,
          ]
        }
        actions={actions}
        showNavigateToBack={true}
        blockNavigateToBack={formik.dirty && isEditing}
        emptyStackPage={emptyStackPage}
      />
      {orderOrderSheetSetModalData && (
        <OrderOrderSheetSetModal
          {...orderOrderSheetSetModalData}
          isOpen={isOrderOrderSheetSetModalOpen}
          onClose={onCloseOrderOrderSheetSetModal}
        />
      )}
    </>
  );
}
