import useIdentity from "@/features/ui/hooks/use-identity";
import React, { useCallback, useEffect, useMemo } from "react";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import useGetDenseOrderConfirmationInvoice, {
  GetDenseOrderConfirmationInvoiceParameter,
  GetDenseOrderConfirmationInvoiceRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-get-dense-order-confirmation-invoice";
import { Formik } from "formik";
import { Grid } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import OrderConfirmationInvoiceForm from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-form";
import {
  Brand,
  Company,
  isStoredObject,
  Price,
  StoredObject,
} from "@/features/types";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useOrderConfirmationInvoiceDumbTransaction } from "@/features/invoices/order-confirmation-invoices/hooks/use-order-confirmation-invoice-dumb-transaction";
import useUpdateOrderConfirmationInvoice, {
  UpdateOrderConfirmationInvoiceRequest,
} from "@/features/invoices/order-confirmation-invoices/hooks/use-update-order-confirmation-invoice";
import useOrderConfirmationInvoiceFormValidationSchema from "@/features/invoices/order-confirmation-invoices/hooks/use-order-confirmation-invoice-form-validation-schema";
import useRequiredParams from "@/features/invoices/hooks/use-required-params";
import {
  AbstractInvoice,
  OrderConfirmationInvoice,
  PaymentMethod,
} from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import useReadOrderConfirmationInvoice from "@/features/invoices/order-confirmation-invoices/hooks/use-read-order-confirmation-invoice";
import InvoiceDetailPageHeader from "@/features/invoices/bank-remittance-receipts/invoice-detail-page-header";
import { PriceInputValue } from "@/features/invoices/app-price-input";
import useDumbMixedUpdate from "@/features/transportations/use-dumb-mixed-update";
import PriceUtils from "@/features/ui/utils/price-utils";

interface OrderConfirmationInvoiceDetailPageProps {
  isEditing?: boolean;
}

export interface OrderConfirmationInvoiceFormFields {
  id?: number;
  number?: string;
  status?: OrderConfirmationInvoice["status"];
  isRemittedToBoutique?: boolean;
  isRemittedToAgency?: boolean;
  name?: string;
  season?: string;
  orderedBy?: Pick<Company, "id" | "name">;
  issuedBy?: Company;
  issuedThrough?: Pick<Company, "id" | "name">;
  issuedOn?: Date;
  paymentMethod?: PaymentMethod;
  exposedToBuyer: boolean;
  paidByAgency: boolean;
  publicComment?: string | null;
  privateComment?: string | null;
  orderSheetSets: DenseOrderSheetSet[];
  file?: File | Pick<StoredObject, "id" | "name">;
  totalRequiredAmount?: PriceInputValue;
  orderConfirmationInvoiceDetailList: (Omit<
    OrderConfirmationInvoice["orderConfirmationInvoiceDetailList"][number],
    | "amount"
    | "requiredAmount"
    | "brand"
    | "orderSheetSet"
    | "remittedAmount"
    | "expectedRemainingDepositAmount"
    | "actualRemainingDepositAmount"
  > & {
    amount?: PriceInputValue;
    requiredAmount?: PriceInputValue;
    brand?: Pick<Brand, "id" | "name">;
    orderSheetSet?: {
      id: number;
      lineSheetSet: {
        id: number;
        name: string;
      };
    };
  })[];
  proformaInvoices: { id: number }[];
  expectedRemainingDepositAmount?: PriceInputValue;
  creditInvoices: { id: number }[];
  depositInvoice?: { id: number };
  payments: {
    id: number;
    status: AbstractInvoice["status"];
    sender: Pick<Company, "id" | "name">;
  }[];
}

export default function OrderConfirmationInvoiceDetailPage({
  isEditing = false,
}: OrderConfirmationInvoiceDetailPageProps) {
  const { error: showError } = useAppToasts();
  const { orderConfirmationInvoiceId } = useRequiredParams<{
    orderConfirmationInvoiceId: number;
  }>();
  const { navigate } = useRouterHelper();
  const identity = useIdentity();
  const { t, tTitle } = useI18nHelper();

  const {
    fire: fireUpdateOrderConfirmationInvoice,
    data: updateOrderConfirmationInvoiceData,
    isLoading: updateOrderConfirmationInvoiceIsLoading,
    error: updateOrderConfirmationInvoiceError,
    clear: clearUpdateOrderConfirmationInvoice,
  } = useUpdateOrderConfirmationInvoice();

  useEffect(() => {
    if (updateOrderConfirmationInvoiceError) {
      showError(updateOrderConfirmationInvoiceError);
    }
  }, [updateOrderConfirmationInvoiceError, showError]);

  const getDenseOrderConfirmationInvoiceKey = useMemo(():
    | {
        parameter: GetDenseOrderConfirmationInvoiceParameter;
        request: GetDenseOrderConfirmationInvoiceRequest;
      }
    | undefined => {
    if (identity?.company && orderConfirmationInvoiceId !== undefined) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
          orderConfirmationInvoiceId,
        },
        request: {},
      };
    }

    return undefined;
  }, [orderConfirmationInvoiceId, identity]);

  const {
    data: orderConfirmationInvoice,
    error: getOrderConfirmationInvoiceError,
    mutate: mutateGetOrderConfirmationInvoice,
  } = useGetDenseOrderConfirmationInvoice(
    getDenseOrderConfirmationInvoiceKey?.parameter,
    getDenseOrderConfirmationInvoiceKey?.request
  );

  useEffect(() => {
    if (getOrderConfirmationInvoiceError) {
      showError(getOrderConfirmationInvoiceError);
    }
  }, [getOrderConfirmationInvoiceError, showError]);

  const {
    isLoading: isReadOrderConfirmationInvoiceLoading,
    data: readOrderConfirmationInvoiceData,
    clear: clearReadOrderConfirmationInvoice,
    fire: fireReadOrderConfirmationInvoice,
  } = useReadOrderConfirmationInvoice();

  useEffect(() => {
    if (
      identity?.company?.type === "BUYER" &&
      orderConfirmationInvoice &&
      !orderConfirmationInvoice.isRead
    ) {
      fireReadOrderConfirmationInvoice(
        {
          by: identity.company.type,
          companyId: identity.company.id,
          orderConfirmationInvoiceId: orderConfirmationInvoice.id,
        },
        {}
      );
    }
  }, [fireReadOrderConfirmationInvoice, identity, orderConfirmationInvoice]);

  useEffect(() => {
    if (readOrderConfirmationInvoiceData) {
      mutateGetOrderConfirmationInvoice().then(() => {
        clearReadOrderConfirmationInvoice();
      });
    }
  }, [
    clearReadOrderConfirmationInvoice,
    mutateGetOrderConfirmationInvoice,
    readOrderConfirmationInvoiceData,
  ]);

  const validateSchema = useOrderConfirmationInvoiceFormValidationSchema();

  const validate = useCallback(
    async (values: OrderConfirmationInvoiceFormFields) => {
      try {
        await validateSchema.validate(values, { abortEarly: false });
      } catch (e) {
        showError(e);
        return e;
      }
    },
    [showError, validateSchema]
  );

  const initialValues = useMemo((): OrderConfirmationInvoiceFormFields => {
    if (orderConfirmationInvoice) {
      return {
        id: orderConfirmationInvoice.id,
        number: orderConfirmationInvoice.number || undefined,
        status: orderConfirmationInvoice.status,
        season: orderConfirmationInvoice.season,
        isRemittedToBoutique: orderConfirmationInvoice.isRemittedToBoutique,
        isRemittedToAgency: orderConfirmationInvoice.isRemittedToAgency,
        orderedBy: orderConfirmationInvoice.issuedToCompany,
        issuedBy: orderConfirmationInvoice.issuedByCompany,
        issuedThrough: orderConfirmationInvoice.issuedThroughCompany,
        issuedOn: orderConfirmationInvoice.issuedOn,
        paymentMethod: orderConfirmationInvoice.paymentMethod,
        paidByAgency: orderConfirmationInvoice.paidBy === "AGENCY",
        exposedToBuyer: orderConfirmationInvoice.isPublic,
        privateComment: orderConfirmationInvoice.privateComment,
        publicComment: orderConfirmationInvoice.publicComment,
        orderSheetSets: orderConfirmationInvoice.orderSheetSets,
        file: orderConfirmationInvoice.file || undefined,
        name: orderConfirmationInvoice.name,
        orderConfirmationInvoiceDetailList:
          orderConfirmationInvoice.orderConfirmationInvoiceDetailList.map(
            (ocDetail) => {
              const orderSheetSet = ocDetail.orderSheetSet
                ? orderConfirmationInvoice.orderSheetSets.find(
                    (oss) => oss.id === ocDetail.orderSheetSet?.id
                  )
                : undefined;

              return {
                ...ocDetail,
                orderSheetSet,
              };
            }
          ),
        proformaInvoices: orderConfirmationInvoice.proformaInvoices.map(
          (pInvoice) => {
            return {
              id: pInvoice.id,
            };
          }
        ),
        expectedRemainingDepositAmount:
          orderConfirmationInvoice.expectedRemainingDepositAmount,

        creditInvoices: orderConfirmationInvoice.creditInvoices.map(
          (cInvoice) => {
            return {
              id: cInvoice.id,
            };
          }
        ),
        depositInvoice: orderConfirmationInvoice.depositInvoice || undefined,
        totalRequiredAmount: orderConfirmationInvoice.totalRequiredAmount,
        payments: orderConfirmationInvoice.payments,
      };
    }

    return {
      exposedToBuyer: identity?.company?.type !== "BOUTIQUE",
      paidByAgency: false,
      orderSheetSets: [],
      orderConfirmationInvoiceDetailList: [],
      proformaInvoices: [],
      creditInvoices: [],
      payments: [],
    };
  }, [orderConfirmationInvoice, identity]);

  const { isUpdateQueued, handleSubmit } = useDumbMixedUpdate({
    dumbFunction: useOrderConfirmationInvoiceDumbTransaction,
    smartFunction: useUpdateOrderConfirmationInvoice,
    isSmart: (request): request is UpdateOrderConfirmationInvoiceRequest => {
      return isStoredObject(request.file);
    },
    toRequest: (values: OrderConfirmationInvoiceFormFields) => {
      const total = values.orderConfirmationInvoiceDetailList
        .map((ocDetail) => {
          return {
            amount: ocDetail.amount,
            requiredAmount: ocDetail.requiredAmount,
          };
        })
        .reduce(
          (acc, cur) => {
            return {
              amount: PriceUtils.sum([acc.amount, cur.amount], "EUR"),
              requiredAmount: PriceUtils.sum(
                [acc.requiredAmount, cur.requiredAmount],
                "EUR"
              ),
            };
          },
          {
            amount: { currency: "EUR" },
            requiredAmount: { currency: "EUR" },
          }
        );

      return {
        id: values.id!,
        number: values.number!,
        season: values.season!,
        status: values.status!,
        name: values.name!,
        issuedToCompany: values.orderedBy!,
        issuedByCompany: values.issuedBy!,
        issuedThroughCompany: values.issuedThrough!,
        issuedOn: values.issuedOn!,
        paidBy: values.paidByAgency ? ("AGENCY" as const) : ("BUYER" as const),
        paymentMethod: values.paymentMethod!,
        orderConfirmationInvoiceDetailList:
          values.orderConfirmationInvoiceDetailList.map((ocDetail) => {
            return {
              id: ocDetail.id,
              amount: ocDetail.amount as Price,
              requiredAmount: ocDetail.requiredAmount as Price,
              brand: ocDetail.brand!,
              orderSheetSet: ocDetail.orderSheetSet || null,
            };
          }),
        orderSheetSets: values.orderSheetSets.map((oss) => {
          return {
            id: oss.id,
          };
        }),
        isPublic: values.exposedToBuyer,
        publicComment: values.publicComment || null,
        privateComment: values.privateComment || null,
        file: values.file!,
        totalAmount: total.amount as Price,
        totalRequiredAmount: total.requiredAmount as Price,
      };
    },
    mutate: mutateGetOrderConfirmationInvoice,
    defaultRedirectUrl: `/order-confirmation-invoices/${orderConfirmationInvoiceId}`,
  });

  return (
    <Formik<OrderConfirmationInvoiceFormFields>
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        if (identity?.company) {
          return await handleSubmit(
            {
              by: identity.company.type,
              companyId: identity.company.id,
              orderConfirmationInvoiceId: values.id!,
            },
            values,
            formikHelpers
          );
        } else {
          return false;
        }
      }}
    >
      {(props) => {
        return (
          <Grid
            as={"form"}
            height={"100%"}
            width={"100%"}
            gridTemplateColumns={"1fr"}
            gridTemplateRows={"auto auto 1fr"}
          >
            <InvoiceDetailPageHeader
              formik={props}
              isUpdateQueued={
                identity?.company && props.values.id
                  ? isUpdateQueued({
                      by: identity.company.type,
                      companyId: identity.company.id,
                      orderConfirmationInvoiceId: props.values.id,
                    })
                  : false
              }
              title={tTitle("order_confirmation_invoice")}
              baseUrl={"/order-confirmation-invoices"}
              isEditing={isEditing}
            />
            <OrderConfirmationInvoiceForm
              formik={props}
              isEditing={isEditing}
            />
          </Grid>
        );
      }}
    </Formik>
  );
}
