import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";
import SignInPage from "@/features/auth/sign-in/sign-in-page";
import PublicLayout from "@/features/ui/layouts/public-layout";
import SignUpPage from "@/features/auth/sign-up/sign-up-page";
import SignUpPendingPage from "@/features/auth/sign-up-pending/sign-up-pending-page";
import PrivateLayout from "@/features/ui/layouts/private-layout";
import LineSheetSetListPage from "@/features/line-sheet-sets/line-sheet-set-list-page";
import OrderConfirmationInvoiceListPage from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-list-page";
import ProformaInvoiceListPage from "@/features/invoices/proforma-invoices/proforma-invoice-list-page";
import OrderSheetSetListPage from "@/features/order-status/order-sheet-set-list-page";
import OrderSheetSetPage from "@/features/order-sheet-sets/order-sheet-set-page";
import LineSheetSetPage from "@/features/line-sheet-sets/line-sheet-set-page";
import DashboardPage from "@/features/dashboard/dashboard-page";
import BalanceTransactionListPage from "@/features/balance-transaction-status/balance-transaction-list-page";
import BankRemittanceReceiptListPage from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-list-page";
import CreditInvoiceListPage from "@/features/invoices/credit-invoices/credit-invoice-list-page";
import DepositInvoiceListPage from "@/features/invoices/deposit-invoices/deposit-invoice-list-page";
import TagManager from "react-gtm-module";
import LandingPage from "@/features/landing/landing";
import _404Page from "@/features/ui/_404-page";
import { Identity } from "@/features/ui/hooks/use-identity";
import { getIdentity } from "@/features/ui/helpers/identity-helpers";
import AppLayout from "@/features/ui/app-layout";
import i18next from "i18next";
import { ErrorBoundary } from "react-error-boundary";
import { isSupportedLanguage } from "@/features/ui/app-language-selector";
import _500Page from "@/features/ui/_500-page";
import InventoryListPage from "@/features/inventories/inventory-list-page";
import MyAccountPage from "@/features/my-account/my-account-page";
import LineSheetSetDetailPage from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import GmailListPage from "@/features/gmails/agency-gmail-list-page";
import UploadOrderConfirmationInvoicePage from "@/features/invoices/order-confirmation-invoices/upload-order-confirmation-invoice-page";
import OrderConfirmationInvoiceDetailPage from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-detail-page";
import UploadProformaInvoicePage from "@/features/invoices/proforma-invoices/upload-proforma-invoice-page";
import ProformaInvoiceDetailPage from "@/features/invoices/proforma-invoices/proforma-invoice-detail-page";
import UploadBankRemittanceReceiptPage from "@/features/invoices/bank-remittance-receipts/upload-bank-remittance-receipt-page";
import BankRemittanceReceiptDetailPage from "@/features/invoices/bank-remittance-receipts/bank-remittance-receipt-detail-page";
import UploadDepositInvoiceUploadPage from "@/features/invoices/deposit-invoices/upload-deposit-invoice-upload-page";
import DepositInvoiceDetailPage from "@/features/invoices/deposit-invoices/deposit-invoice-detail-page";
import UploadCreditInvoicePage from "@/features/invoices/credit-invoices/upload-credit-invoice-page";
import CreditInvoiceDetailPage from "@/features/invoices/credit-invoices/credit-invoice-detail-page";
import UploadLineSheetSetPage from "@/features/line-sheet-sets/upload-line-sheet-set-page";
import UploadTransportationPage from "@/features/transportations/upload-transportation-page";
import TransportationListPage from "@/features/transportations/transportation-list-page";
import TransportationDetailPage from "@/features/transportations/transportation-detail-page";
import UploadPaymentPage from "@/features/invoices/payment/upload-payment-page";
import PaymentListPage from "@/features/invoices/payment/payment-list-page";
import PaymentDetailPage from "@/features/invoices/payment/payment-detail-page";
import TriangularTradeListPage from "@/features/invoices/triangular-trades/triangular-trade-list-page";
import UploadTriangularTradePage from "@/features/invoices/triangular-trades/upload-triangular-trade-page";
import TriangularTradeDetailPage from "@/features/invoices/triangular-trades/triangular-trade-detail-page";
import RedirectTo500WithRefreshing from "@/features/ui/redirectTo500WithRefreshing";
import * as Sentry from "@sentry/react";
import { useState } from "react";

export function getDefaultPageByIdentity(
  identity: Identity | null,
  joinCTA: boolean = false
) {
  if (identity === null) {
    if (joinCTA) {
      return "/auth/sign-up?utm_source=pathrade-web&utm_medium=product&utm_campaign=product-auth-signup-link&utm_content=cta-button-link-top&utm_term=v240724";
    }
    return "/auth/sign-in?utm_source=pathrade-web&utm_medium=product&utm_campaign=product-auth-signin-link&utm_content=cta-button-link-top&utm_term=v240724";
  } else if (identity.company === null) {
    return "/auth/sign-up-pending";
  } else if (identity.company.type === "BUYER") {
    // return `/u/${identity.company.id}/dashboard`;
    return `/dashboard`;
  } else {
    return `/line-sheets`;
  }
}

export default function App() {
  const [isTagManagerInitialized, setIsTagManagerInitialized] = useState(false);
  if (process.env.NEXT_PUBLIC_API_URL === "https://api.pathrade.com") {
    if (!isTagManagerInitialized) {
      setIsTagManagerInitialized(true);
      const tagManagerArgs = {
        gtmId: "GTM-W65BT33P",
      };

      TagManager.initialize(tagManagerArgs);
      console.log("TagManager initialized");
    }
  }

  const router = createBrowserRouter([
    {
      path: "/:locale?",
      element: (
        <>
          {/*<ErrorBoundary fallback={<Navigate to={"/500"} replace={true} />}>*/}
          <ErrorBoundary
            fallback={<RedirectTo500WithRefreshing />}
            onError={(err) => {
              Sentry.captureException(err);
            }}
          >
            <AppLayout />
          </ErrorBoundary>
        </>
      ),
      loader: ({ params, request, ...rest }) => {
        const { locale } = params;
        const url = new URL(request.url);

        if (locale) {
          if (!isSupportedLanguage(locale)) {
            throw redirect("/");
          }
          return null;
        } else if (
          i18next.resolvedLanguage &&
          i18next.resolvedLanguage !== "en" &&
          isSupportedLanguage(i18next.resolvedLanguage)
        ) {
          throw redirect(
            `/${i18next.resolvedLanguage}${url.pathname}${url.search}`
          );
        }
        return null;
      },
      children: [
        {
          path: "auth",
          element: <PublicLayout showBackgroundImage={false} />,
          loader: ({ params, request }) => {
            const identity = getIdentity();
            const url = new URL(request.url);
            const isPending = url.pathname === "/auth/sign-up-pending";
            const isAuthenticated = identity !== null;
            const isConfirmed = isAuthenticated && identity.company !== null;

            if (isAuthenticated && isConfirmed) {
              throw redirect(getDefaultPageByIdentity(identity));
            }

            if (isPending && !isAuthenticated) {
              throw redirect("/auth/sign-in");
            }

            return null;
          },
          children: [
            {
              path: "sign-in",
              element: <SignInPage />,
            },
            {
              path: "sign-up",
              element: <SignUpPage step={1} />,
            },
            {
              path: "sign-up/2",
              element: <SignUpPage step={2} />,
            },
            {
              path: "sign-up-pending",
              element: <SignUpPendingPage />,
            },
          ],
        },
        {
          path: "",
          element: <LandingPage />,
        },
        {
          path: "",
          element: <PrivateLayout />,
          loader: ({ params, request }) => {
            //여기에서 redirect하면 replace가 안되어서, PrivateLayout에서 redirect하도록 한다.
            return null;
          },
          children: [
            {
              path: "dashboard",
              element: <DashboardPage />,
            },
            {
              path: "line-sheets",
              children: [
                {
                  path: "",
                  element: <LineSheetSetListPage />,
                },
                {
                  path: ":lineSheetSetId",
                  element: <LineSheetSetPage />,
                },
              ],
            },
            {
              path: "line-sheet-sets/upload",
              element: <UploadLineSheetSetPage />,
            },
            {
              path: "line-sheet-sets/:lineSheetSetId",
              element: <LineSheetSetDetailPage />,
            },
            {
              path: "line-sheet-sets/:lineSheetSetId/edit",
              element: <LineSheetSetDetailPage isEditing={true} />,
            },
            {
              path: "order-sheets",
              children: [
                {
                  path: "",
                  element: <OrderSheetSetListPage />,
                },
                {
                  path: ":orderSheetSetId",
                  element: <OrderSheetSetPage />,
                },
              ],
            },
            {
              path: "deposit-status",
              children: [
                {
                  path: "",
                  element: <BalanceTransactionListPage />,
                },
              ],
            },
            {
              path: "order-confirmation-invoices",
              children: [
                {
                  path: "",
                  element: <OrderConfirmationInvoiceListPage />,
                },
                {
                  path: "upload",
                  element: <UploadOrderConfirmationInvoicePage />,
                },
                {
                  path: ":orderConfirmationInvoiceId",
                  element: (
                    <OrderConfirmationInvoiceDetailPage isEditing={false} />
                  ),
                },
                {
                  path: ":orderConfirmationInvoiceId/edit",
                  element: (
                    <OrderConfirmationInvoiceDetailPage isEditing={true} />
                  ),
                },
              ],
            },
            {
              path: "proforma-invoices",
              children: [
                {
                  path: "",
                  element: <ProformaInvoiceListPage />,
                },
                {
                  path: "upload",
                  element: <UploadProformaInvoicePage />,
                },
                {
                  path: ":proformaInvoiceId",
                  element: <ProformaInvoiceDetailPage />,
                },
                {
                  path: ":proformaInvoiceId/edit",
                  element: <ProformaInvoiceDetailPage isEditing={true} />,
                },
              ],
            },
            {
              path: "payments",
              children: [
                {
                  path: "",
                  element: <PaymentListPage />,
                },
                {
                  path: "upload",
                  element: <UploadPaymentPage />,
                },
                {
                  path: ":paymentId",
                  element: <PaymentDetailPage />,
                },
                {
                  path: ":paymentId/edit",
                  element: <PaymentDetailPage isEditing={true} />,
                },
              ],
            },
            {
              path: "bank-remittance-receipts",
              children: [
                {
                  path: "",
                  element: <BankRemittanceReceiptListPage />,
                },
                {
                  path: "upload",
                  element: <UploadBankRemittanceReceiptPage />,
                },
                {
                  path: ":bankRemittanceReceiptId",
                  element: <BankRemittanceReceiptDetailPage />,
                },
                {
                  path: ":bankRemittanceReceiptId/edit",
                  element: <BankRemittanceReceiptDetailPage isEditing={true} />,
                },
              ],
            },
            {
              path: "deposit-invoices",
              children: [
                {
                  path: "",
                  element: <DepositInvoiceListPage />,
                },
                {
                  path: "upload",
                  element: <UploadDepositInvoiceUploadPage />,
                },
                {
                  path: ":depositInvoiceId",
                  element: <DepositInvoiceDetailPage />,
                },
                {
                  path: ":depositInvoiceId/edit",
                  element: <DepositInvoiceDetailPage isEditing={true} />,
                },
              ],
            },
            {
              path: "credit-invoices",
              children: [
                {
                  path: "",
                  element: <CreditInvoiceListPage />,
                },
                {
                  path: "upload",
                  element: <UploadCreditInvoicePage />,
                },
                {
                  path: ":creditInvoiceId",
                  element: <CreditInvoiceDetailPage />,
                },
                {
                  path: ":creditInvoiceId/edit",
                  element: <CreditInvoiceDetailPage isEditing={true} />,
                },
              ],
            },
            {
              path: "triangular-trades",
              children: [
                {
                  path: "",
                  element: <TriangularTradeListPage />,
                },
                {
                  path: "upload",
                  element: <UploadTriangularTradePage />,
                },
                {
                  path: ":triangularTradeId",
                  element: <TriangularTradeDetailPage isEditing={false} />,
                },
              ],
            },
            {
              path: "inventories",
              element: <InventoryListPage />,
            },
            {
              path: "my-account",
              element: <MyAccountPage />,
            },
            {
              path: "gmails",
              element: <GmailListPage />,
            },
            {
              path: "transportations",
              children: [
                {
                  path: "upload",
                  element: <UploadTransportationPage />,
                },
                {
                  path: "",
                  element: <TransportationListPage />,
                },
                {
                  path: ":transportationId",
                  element: <TransportationDetailPage />,
                },
                {
                  path: ":transportationId/edit",
                  element: <TransportationDetailPage isEditing={true} />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <PublicLayout showBackgroundImage={false} />,
      children: [
        {
          path: "404",
          element: <_404Page />,
        },
        {
          path: "500",
          element: <_500Page />,
        },
        {
          path: "*",
          element: <Navigate to={"/404"} replace={true} />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
