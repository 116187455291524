import type { Location } from "@remix-run/router";
import { Draft, produce } from "immer";
import { WritableDraft } from "immer/src/types/types-external";
import { StringTransformer } from "@/features/ui/helpers/string-transformer";
import { toSnake } from "@/utils/case";

export class AppUtils {
  static extractIds<T extends { id: number }>(items?: (T | T[] | undefined)[]) {
    if (!items) {
      return undefined;
    }

    return items
      .flatMap((item) => {
        if (!item) {
          return [];
        }
        return Array.isArray(item) ? item : [item];
      })
      .map((item) => item.id);
  }

  static filterDefined<T>(items: (T | undefined)[]): T[] {
    return items.filter((item) => item !== undefined) as T[];
  }

  static async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static getThumbnailUrl(sourceUrl: string) {
    const i = sourceUrl.lastIndexOf("/");
    return `${sourceUrl.substring(0, i + 1)}thumbnail_${sourceUrl.substring(
      i + 1
    )}`;
  }

  static calcPureTo(
    pathname: string,
    // query?: {
    //   [key: string]:
    //     | string
    //     | string[]
    //     | null
    //     | undefined
    //     | boolean
    //     | number
    //     | Date
    //     | OrderBy[]
    //     | number[];
    query?: { [key: string]: any }
  ) {
    if (query) {
      const searchParams = new URLSearchParams();
      Object.entries(query).forEach(([rawKey, value]) => {
        const key = toSnake(rawKey);
        const a = StringTransformer.stringifyAny(value);
        if (a) {
          if (Array.isArray(a)) {
            a.forEach((nestedValue) => {
              searchParams.append(key, nestedValue);
            });
          } else {
            searchParams.append(key, a);
          }
        }
      });
      return `${pathname}?${searchParams.toString()}`;
    }

    return pathname;
  }

  static getReferer(location: Location): Location | undefined {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("referer")) {
      const rawReferer = searchParams.get("referer");
      if (rawReferer) {
        const referer = decodeURIComponent(rawReferer);
        const i = referer.indexOf("?");
        let pathname = referer;
        let search = "";

        if (i !== -1) {
          pathname = referer.substring(0, i);
          search = referer.substring(i);
        }

        return {
          pathname,
          search,
          key: "shouldn't_be_used",
          hash: "shouldn't_be_used",
          state: {},
        };
      }
    }
  }

  static isLiteralEditOrUpload(
    literal: {
      pathname: string;
      query?: { [key: string]: string | string[] | null | undefined };
    },
    location: Location
  ) {
    const { pathname: literalPathname, query: literalQuery } = literal;
    const pathname = location.pathname.replace(/^\/(ko|it|en)/, "");

    // if (pathname === "/proforma-invoices/19/edit") {
    //   debugger;
    // }

    return new RegExp(`${literalPathname}/(upload|\\d+(/edit)?)`).test(
      pathname
    );
  }

  static isContainsLiteral(
    literal: {
      pathname: string;
      query?: { [key: string]: string | string[] | null | undefined | boolean };
    },
    location: Location
  ) {
    const { pathname: literalPathname, query: literalQuery } = literal;
    const pathname = location.pathname.replace(/^\/(ko|it|en)/, "");

    // console.dir({ literalPathname, literalQuery, pathname });

    if (literalPathname === pathname) {
      if (literalQuery) {
        const searchParams = new URLSearchParams(location.search);
        return Object.keys(literal.query || {}).every((key) => {
          const snake_key = toSnake(key);
          const value = literal.query?.[key];
          if (Array.isArray(value)) {
            return (
              [...searchParams.getAll(snake_key)].sort().join(",") ===
              [...value].sort().join(",")
            );
          } else if (typeof value === "boolean") {
            return searchParams.get(snake_key) === String(value);
          } else if (value) {
            return searchParams.get(snake_key) === value;
          } else {
            return searchParams.get(snake_key) === null;
          }
        });
      }
      return true;
    }

    return false;
  }

  static findIndexPathsOfNestedObjects<T extends { children: T[] }>(
    rows: T[],
    predicate: (item: T) => boolean,
    path: number[] = []
  ): number[] | null {
    for (let i = 0; i < rows.length; i++) {
      const item = rows[i];
      if (predicate(item)) {
        return [...path, i];
      }

      if (item.children) {
        const found = AppUtils.findIndexPathsOfNestedObjects(
          item.children,
          predicate,
          [...path, i]
        );
        if (found) {
          return found;
        }
      }
    }

    return null;
  }

  static updateNestedObjects<T extends { children: T[] }>(
    rows: T[],
    pairs: {
      predicate: (item: T) => boolean;
      updater: (item: T) => T;
    }[]
  ) {
    return produce(rows, (draft) => {
      pairs.forEach(({ predicate, updater }) => {
        const paths = AppUtils.findIndexPathsOfNestedObjects(rows, predicate);
        if (paths) {
          let cursor: WritableDraft<T[]> = draft;

          for (let i = 0; i < paths.length - 1; i++) {
            cursor = cursor[paths[i]].children;
          }

          cursor[paths[paths.length - 1]] = updater(
            cursor[paths[paths.length - 1]] as T
          ) as Draft<T>;
        }
      });
    });
  }

  static findDirectChild<T>(rows: T[], predicate: (item: T) => boolean) {
    return rows.find(predicate);
  }

  static getFirst<T>(item?: T | T[]): T | undefined {
    if (item) {
      if (Array.isArray(item)) {
        if (item.length > 0) {
          return item[0];
        }
      } else {
        return item;
      }
    }
  }

  static trimFloat(value: number) {
    return Math.round(value * 100) / 100;
  }

  static isTrue<T>(value: T | undefined | T[]) {
    if (value || value === 0) {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return true;
    }
    return false;
  }

  static subList<T>(items: T[], start: T, end?: T) {
    const startIndex = items.indexOf(start);
    if (startIndex === -1) {
      return [];
    }

    if (end) {
      const endIndex = items.indexOf(end);
      if (endIndex === -1) {
        return [];
      }
      return items.slice(startIndex, endIndex + 1);
    }

    return items.slice(startIndex);
  }

  static zip<T, U, V>(a: T[], b: U[], func: (a?: T, b?: U) => V): V[] {
    const length = Math.max(a.length, b.length);
    return Array.from({ length }, (value, index) => {
      return func(a[index], b[index]);
    });
  }
}
