import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { useSetRecoilState } from "recoil";
import { lineSheetSetFamily } from "@/features/line-sheet-sets/helpers/sheet-state";
import useGetLineSheetSet, {
  GetLineSheetSetPathParameter,
  GetLineSheetSetRequest,
} from "./use-get-line-sheet-set";

export interface UseFetchAndSetLineSheetSetParameter {
  data:
    | {
        lineSheetSetId: number;
      }
    | undefined;
}

export default function useFetchAndSetLineSheetSet({
  data,
}: UseFetchAndSetLineSheetSetParameter) {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);
  const { error: showError } = useAppToasts();

  const getLineSheetSetKey = useMemo(():
    | {
        parameter: GetLineSheetSetPathParameter;
        request: GetLineSheetSetRequest;
      }
    | undefined => {
    if (data && company) {
      return {
        parameter: {
          by: company.type,
          companyId: company.id,
          lineSheetSetId: data.lineSheetSetId,
        },
        request: {},
      };
    }
    return undefined;
  }, [data, company]);

  const {
    isLoading: isGetSheetSetLoading,
    isValidating: isGetLineSheetSetValidating,
    data: getLineSheetSetData,
    error: getLineSheetSetError,
    mutate: mutateGetLineSheetSet,
  } = useGetLineSheetSet(
    getLineSheetSetKey?.parameter,
    getLineSheetSetKey?.request,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    showError(getLineSheetSetError);
  }, [showError, getLineSheetSetError]);

  const setLineSheetSetFamily = useSetRecoilState(
    lineSheetSetFamily({
      lineSheetSetId: data?.lineSheetSetId || -1,
    })
  );

  useEffect(() => {
    if (
      !isGetSheetSetLoading &&
      !isGetLineSheetSetValidating &&
      getLineSheetSetData
    ) {
      setLineSheetSetFamily({
        key: {
          lineSheetSetId: getLineSheetSetData.id,
        },
        lineSheetSet: {
          ...getLineSheetSetData,
        },
      });
    }
  }, [
    isGetSheetSetLoading,
    getLineSheetSetData,
    setLineSheetSetFamily,
    isGetLineSheetSetValidating,
  ]);

  return {
    getLineSheetSetError,
    getLineSheetSetData,
    mutateGetLineSheetSet,
  };
}
