import {
  Box,
  Flex,
  FormLabel,
  Grid,
  Heading,
  Spacer,
  Spinner,
  Text,
} from "@chakra-ui/react";
import AppLineSheetTypeSelector from "@/features/line-sheet-sets/app-line-sheet-type-selector";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toLower } from "@/utils/case";
import AppSeasonSelect from "@/features/line-sheet-sets/app-season-select";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { Field, FieldProps, FormikProps } from "formik";
import TextareaAutosize from "react-textarea-autosize";
import AppDatePicker from "@/features/line-sheet-sets/app-date-picker";
import { LineSheetSetFormFields } from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import AppOrderSheetStatusLabel from "@/features/ui/app-order-sheet-status-label";
import useIdentity from "@/features/ui/hooks/use-identity";
import AppLineSheetSetStatusLabel from "@/features/ui/app-line-sheet-set-status-label";
import React, { useMemo } from "react";
import AppInput from "@/features/ui/app-input";
import AppListFormControl from "@/features/transportations/app-list-form-control";
import AppCurrencySelect from "../app-currency-select";

interface LineSheetSetInformationFormControlProps {
  isEditing: boolean;
  onPublishClick: () => void;
  formikProps: FormikProps<LineSheetSetFormFields>;
  isRefreshing?: boolean;
}

export default function LineSheetSetInformationFormControl({
  isEditing,
  formikProps,
  isRefreshing,
  onPublishClick,
}: LineSheetSetInformationFormControlProps) {
  const identity = useIdentity();

  const {
    t,
    tUpper,
    tTitle,
    tLocalDateString,
    tLocalDateTimeString2,
    tCurrencyString,
    tNumberString,
    tCountString,
    tConditionAppliedCurrencyString,
  } = useI18nHelper();

  const issuedToBuyersMeta = useMemo(() => {
    const issuedToBuyers = formikProps.values.issuedToBuyers || [];
    let name = "";
    if (issuedToBuyers.length > 0) {
      name = t(
        issuedToBuyers.length > 1
          ? "issued_to_buyers_others"
          : "issued_to_buyers_one",
        {
          first: issuedToBuyers[0].name,
          count: issuedToBuyers.length,
        }
      );
    }
    return name;
  }, [formikProps.values.issuedToBuyers, t]);

  return (
    <Box
      borderRadius={"8px"}
      border={"0.5px solid var(--Pathrade-Gray-Groomy, #8F8F8C)"}
      background={"var(--Pathrade-White, #FFF)"}
      boxShadow={"0px 2px 4px 0px rgba(0, 0, 0, 0.10)"}
      padding={"8px 16px"}
    >
      <Flex flexDirection={"column"} gap={"8px"}>
        {/*<Heading as={"h3"} fontSize={"16px"}>*/}
        {/*  {lineSheetSet.name}*/}
        {/*</Heading>*/}
        <Field name={"name"}>
          {(
            props: FieldProps<
              LineSheetSetFormFields["name"],
              LineSheetSetFormFields
            >
          ) => {
            return isEditing ? (
              // <Input
              //   fontSize={"16px"}
              //   {...props.field}
              //   padding={"0 4px"}
              //   minHeight={"0"}
              //   height={"auto"}
              // />
              <AppInput
                value={props.field.value}
                fontSize={"16px"}
                placeholder={tTitle("common:title_placeholder")}
                onChange={(value) => {
                  props.form.setFieldValue(props.field.name, value);
                }}
              />
            ) : (
              <Heading as={"h3"} fontSize={"16px"}>
                {props.field.value}
              </Heading>
            );
          }}
        </Field>

        <Flex flexDirection={"row"} gap={"4px"}>
          <Flex flexWrap={"wrap"} gap={"4px"} alignItems={"center"}>
            <Field name={"type"}>
              {(
                props: FieldProps<
                  LineSheetSetFormFields["type"],
                  LineSheetSetFormFields
                >
              ) => {
                if (isEditing) {
                  return (
                    <AppLineSheetTypeSelector
                      width={"120px"}
                      value={props.field.value}
                      onSelect={(type) => {
                        props.form.setFieldValue(props.field.name, type);
                      }}
                    />
                  );
                } else {
                  return (
                    <Text fontSize={"12px"}>
                      {props.field.value
                        ? tUpper(
                            `common\:line_sheet_set.type.${toLower(
                              props.field.value
                            )}`
                          )
                        : ""}
                    </Text>
                  );
                }
              }}
            </Field>
            <Text fontSize={"12px"}>/</Text>

            <Field name={"season"}>
              {(props: FieldProps<string, LineSheetSetFormFields>) => {
                if (isEditing) {
                  return (
                    <AppSeasonSelect
                      width={"88px"}
                      value={props.field.value}
                      onSelect={(season) => {
                        props.form.setFieldValue(props.field.name, season);
                      }}
                    />
                  );
                } else {
                  return <Text fontSize={"12px"}>{props.field.value}</Text>;
                }
              }}
            </Field>

            <Text fontSize={"12px"}>/</Text>

            {identity?.company?.type !== "BOUTIQUE" && (
              <>
                <Field name={"issuedByBoutique"}>
                  {(
                    props: FieldProps<
                      LineSheetSetFormFields["issuedByBoutique"],
                      LineSheetSetFormFields
                    >
                  ) => {
                    if (isEditing) {
                      return (
                        <AppCompanySelect
                          types={["BOUTIQUE"]}
                          name={tTitle(
                            "line_sheet_sets:upload_line_sheet_sets_form.field.issued_by_boutique"
                          )}
                          id={props.field.value?.id}
                          onSelect={(company) => {
                            const value = company
                              ? { id: company.id }
                              : undefined;
                            props.form.setFieldValue(props.field.name, value);
                          }}
                        />
                      );
                    } else {
                      return (
                        <Text fontSize={"12px"}>
                          {props.field.value?.name ?? ""}
                        </Text>
                      );
                    }
                  }}
                </Field>
                <Text fontSize={"12px"}>/</Text>
              </>
            )}

            <Field name={"issuedByBrand"}>
              {(
                props: FieldProps<
                  LineSheetSetFormFields["issuedByBrand"],
                  LineSheetSetFormFields
                >
              ) => {
                if (isEditing) {
                  return (
                    <AppCompanySelect
                      types={["BRAND"]}
                      name={tTitle(
                        "line_sheet_sets:upload_line_sheet_sets_form.field.issued_by_brand"
                      )}
                      id={props.field.value?.id}
                      onSelect={(company) => {
                        const value = company ? { id: company.id } : undefined;
                        props.form.setFieldValue(props.field.name, value);
                      }}
                    />
                  );
                } else {
                  return (
                    <Text fontSize={"12px"}>
                      {props.field.value?.name ?? ""}
                    </Text>
                  );
                }
              }}
            </Field>
          </Flex>
          <Spacer />
          <Flex
            flexDirection={"row"}
            flexShrink={"0"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            {formikProps.values.orderSheetSet ? (
              <AppOrderSheetStatusLabel
                value={formikProps.values.orderSheetSet.status}
              />
            ) : (
              identity?.company?.type === "AGENCY" &&
              formikProps.values.status &&
              (isRefreshing ? (
                <Spinner size={"xs"} boxSize={"20px"} />
              ) : (
                <AppLineSheetSetStatusLabel value={formikProps.values.status} />
              ))
            )}
          </Flex>
        </Flex>

        <Box height={"0.5px"} width={"100%"} backgroundColor={"#8F8F8C"} />

        <Flex flexDirection={"column"} gap={"4px"}>
          <Grid
            gridTemplateColumns={"1fr minmax(140px, auto)"}
            alignItems={"center"}
          >
            <Field name={"currency"}>
              {(
                props: FieldProps<
                  LineSheetSetFormFields["currency"],
                  LineSheetSetFormFields
                >
              ) => {
                return (
                  <>
                    <FormLabel
                      fontSize={"12px"}
                      padding={"0"}
                      margin={"0"}
                      fontWeight={"700"}
                    >
                      {tTitle("currency")}
                    </FormLabel>
                    {isEditing ? (
                      <AppCurrencySelect
                        width={"100%"}
                        value={props.field.value}
                        onSelect={(currency) => {
                          props.form.setFieldValue(props.field.name, currency);
                        }}
                      />
                    ) : (
                      <Text fontSize={"12px"}>{props.field.value}</Text>
                    )}
                  </>
                );
              }}
            </Field>

            <Field name={"validUntil"}>
              {(
                props: FieldProps<Date | undefined, LineSheetSetFormFields>
              ) => {
                return (
                  <>
                    <FormLabel
                      fontSize={"12px"}
                      padding={"0"}
                      margin={"0"}
                      fontWeight={"700"}
                    >
                      {tTitle("order_valid_until")}
                    </FormLabel>
                    {isEditing ? (
                      <AppDatePicker
                        name={tTitle("order_valid_until")}
                        value={props.field.value}
                        showTimeInput={true}
                        onChange={(value) => {
                          props.form.setFieldValue(props.field.name, value);
                        }}
                      />
                    ) : (
                      <Text fontSize={"12px"}>
                        {tLocalDateTimeString2(props.field.value)}
                      </Text>
                    )}
                  </>
                );
              }}
            </Field>

            {formikProps.values.id !== -1 && (
              <>
                <Text fontSize={"12px"} fontWeight={"700"}>
                  {tTitle("common:published_on")}
                </Text>

                <Text fontSize={"12px"}>
                  {tLocalDateString(formikProps.values.publishedAt)}
                </Text>

                {identity?.company?.type === "AGENCY" && (
                  <>
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      {tTitle("common:published_to")}
                    </Text>

                    <Text
                      fontSize={"12px"}
                      fontWeight={"700"}
                      decoration={"underline"}
                      as={"a"}
                      href={"#"}
                      onClick={(event) => {
                        event.preventDefault();
                        onPublishClick();
                      }}
                    >
                      {issuedToBuyersMeta}
                    </Text>
                  </>
                )}

                {formikProps.values.orderSheetSet && (
                  <>
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      {tTitle("common:ordered_on")}
                    </Text>

                    <Text fontSize={"12px"}>
                      {tLocalDateString(
                        formikProps.values.orderSheetSet?.submittedAt
                      )}
                    </Text>
                  </>
                )}
              </>
            )}
          </Grid>
        </Flex>

        {formikProps.values.orderSheetSet &&
          formikProps.values.orderSheetSet.status !== "INITIAL_DRAFT" && (
            <>
              <Box
                height={"0.5px"}
                width={"100%"}
                backgroundColor={"#8F8F8C"}
              />
              <Grid
                gridTemplateColumns={"280px 1fr"}
                alignItems={"center"}
                fontSize={"12px"}
              >
                <Text fontWeight={"700"}>
                  {tTitle("common:total_ordered_amount_without_condition")}
                </Text>
                <Text justifySelf={"end"}>
                  {tCurrencyString(
                    formikProps.values.orderSheetSet
                      .orderedAmountWithoutCondition,
                    {
                      type: "SYMBOL",
                      code: formikProps.values.currency,
                    }
                  )}
                </Text>
                <Text fontWeight={"700"}>
                  {tTitle("common:total_ordered_amount")}
                </Text>
                <Text justifySelf={"end"}>
                  {tConditionAppliedCurrencyString(
                    formikProps.values.orderSheetSet.orderedConditionType,
                    formikProps.values.orderSheetSet.orderedAmount,
                    {
                      type: "SYMBOL",
                      code: formikProps.values.currency,
                    }
                  )}
                </Text>
                <Text fontWeight={"700"}>
                  {tTitle("common:total_ordered_quantity")}
                </Text>
                <Text justifySelf={"end"}>
                  {tNumberString(
                    formikProps.values.orderSheetSet.orderedQuantity
                  )}
                </Text>
              </Grid>
            </>
          )}

        <Field name={"externalFileLinks"}>
          {(
            props: FieldProps<
              LineSheetSetFormFields["externalFileLinks"],
              LineSheetSetFormFields
            >
          ) => {
            return (
              <>
                <Box
                  height={"0.5px"}
                  width={"100%"}
                  backgroundColor={"#8F8F8C"}
                />
                <Flex flexDirection={"column"} gap={"4px"} fontSize={"12px"}>
                  <Text fontSize={"12px"}>{tTitle("external_file_links")}</Text>
                  <AppListFormControl
                    value={props.field.value || []}
                    isDisabled={!isEditing || props.form.values.id !== -1}
                    onChange={(value) => {
                      formikProps.setFieldValue(props.field.name, value);
                    }}
                    isLiteral={!isEditing}
                  />
                </Flex>
              </>
            );
          }}
        </Field>

        <Box height={"0.5px"} width={"100%"} backgroundColor={"#8F8F8C"} />
        <Field name={"joinedOrderRules"}>
          {(
            props: FieldProps<
              LineSheetSetFormFields["joinedOrderRules"],
              LineSheetSetFormFields
            >
          ) => {
            return (
              <Flex flexDirection={"column"} gap={"4px"}>
                <Text fontSize={"12px"} fontWeight={"700"}>
                  {tTitle("common:order_rules")}
                </Text>
                <TextareaAutosize
                  value={props.field.value || ""}
                  style={{
                    fontSize: "12px",
                    borderRadius: "4px",
                    border: isEditing ? "0.5px solid #8F8F8C" : "none",
                    background: "#FFF",
                    padding: isEditing ? "0 4px" : "0",
                  }}
                  readOnly={!isEditing}
                  onChange={(event) => {
                    props.form.setFieldValue(
                      props.field.name,
                      event.target.value
                    );
                  }}
                  minRows={10}
                />
              </Flex>
            );
          }}
        </Field>
      </Flex>
    </Box>
  );
}
