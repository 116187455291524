import useIdentity from "@/features/ui/hooks/use-identity";
import { Box, Flex } from "@chakra-ui/react";
import AppLineSheetTypeSelector from "@/features/line-sheet-sets/app-line-sheet-type-selector";
import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppPagination from "@/features/line-sheet-sets/app-pagination";
import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { buyerOrderSheetSetRowsMutateId } from "@/features/order-status/recoils/buyer-order-sheet-set-rows-state";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useBuyerOrderSheetSetRows from "@/features/order-status/hooks/use-buyer-order-sheet-set-rows";
import { DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE } from "@/features/line-sheet-sets/hooks/use-list-line-sheet-set-pairs";
import BuyerOrderSheetSetGrid from "@/features/order-status/buyer-order-sheet-set-grid";
import AppListHeader from "@/features/ui/app-list-header";
import useListDenseOrderSheetSet from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-set";
import { OrderByItem } from "@/features/types";
import { toTitle } from "@/utils/case";
import { useTranslation } from "react-i18next";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import useTypedSearchParams from "@/features/invoices/hooks/use-typed-search-params";
import { PersistedOrderSheetSetListQuery } from "@/features/order-status/agency-order-sheet-set-list-page";

export default function BuyerOrderSheetSetListPage() {
  const identity = useIdentity();
  const [query, setQuery] =
    useTypedSearchParams<PersistedOrderSheetSetListQuery>();
  const { setRows } = useBuyerOrderSheetSetRows();
  const rowsMutateId = useRecoilValue(buyerOrderSheetSetRowsMutateId);
  const { add: addToast, error: showError } = useAppToasts();
  const { t } = useTranslation();

  const listOrderSheetSetsKey = useMemo(() => {
    if (identity?.company) {
      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: {
          ...query,
          pageNumber: query.pageNumber || 1,
          pageSize: DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE,
          orderBy: [new OrderByItem("createdAt", "descend")],
        },
      };
    }
  }, [identity, query]);

  const {
    isLoading: isListOrderSheetSetsLoading,
    data: listOrderSheetSetsData,
    error: listOrderSheetSetsError,
    mutate: mutateListOrderSheetSets,
  } = useListDenseOrderSheetSet(
    listOrderSheetSetsKey?.parameter,
    listOrderSheetSetsKey?.request,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useEffect(() => {
    if (listOrderSheetSetsError) {
      showError(listOrderSheetSetsError);
    }
  }, [listOrderSheetSetsError, showError]);

  useEffect(() => {
    setRows(listOrderSheetSetsData?.orderSheetSets);
  }, [listOrderSheetSetsData, setRows]);

  useEffect(() => {
    if (rowsMutateId > 0) {
      mutateListOrderSheetSets();
    }
  }, [mutateListOrderSheetSets, rowsMutateId]);

  return (
    <Flex
      flexDirection={"column"}
      gap={"12px"}
      height={"100%"}
      paddingBottom={"16px"}
    >
      <AppListHeader
        filters={[
          <AppSeasonMultiSelect
            key={"season"}
            value={query["lineSheetSet.season__in"]}
            onSelect={(value) =>
              setQuery((prev) => ({
                ...prev,
                "lineSheetSet.season__in": value,
                pageNumber: 1,
              }))
            }
          />,
          <AppCompanySelect
            key={"AgencyOrderSheetSetListFilter_IssuedBy"}
            name={toTitle(t("issued_by"))}
            width={"144px"}
            types={["BOUTIQUE", "BRAND"]}
            id={query["lineSheetSet.issuedByCompany.id__eq"]}
            onSelect={(company) =>
              setQuery((prev) => ({
                ...prev,
                "lineSheetSet.issuedByCompany.id__eq": company?.id,
                pageNumber: 1,
              }))
            }
          />,
          <AppButton
            key={"AgencyOrderSheetSetListFilter_ResetFilter"}
            variant={"normal"}
            size={"medium"}
            width={"144px"}
            onClick={() => {
              setQuery((prev) => {
                return {
                  pageNumber: 1,
                };
              });
            }}
          >
            {toTitle(t("reset_filter"))}
          </AppButton>,
        ]}
        actions={[]}
      />
      <Box
        flexGrow={1}
        overflow={"auto"}
        style={{
          scrollbarGutter: "stable",
        }}
      >
        <BuyerOrderSheetSetGrid />
      </Box>
      <Flex flexDirection={"row"} justifyContent={"center"}>
        <AppPagination
          capacity={10}
          pageSize={DEFAULT_LIST_LINE_SHEET_PAIRS_PAGE_SIZE}
          totalCount={listOrderSheetSetsData?.totalCount}
          pageNumber={query.pageNumber}
          setPageNumber={(value) => setQuery((prev) => ({ pageNumber: value }))}
        />
      </Flex>
    </Flex>
  );
}
