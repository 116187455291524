import {
  AppSeasonSelectProps,
  getSeasonOptions,
} from "@/features/line-sheet-sets/app-season-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";

interface AppSeasonMultiSelectProps
  extends Omit<AppSeasonSelectProps, "value" | "onSelect"> {
  value?: string[];
  onSelect?: (value?: string[]) => void;
}

export default function AppSeasonMultiSelect({
  value,
  width = "144px",
  onSelect,
  isDisabled = false,
  after,
  before,
  isClearable = true,
  readonly = false,
}: AppSeasonMultiSelectProps) {
  const { tTitle } = useI18nHelper();
  const options = useMemo(() => {
    return getSeasonOptions(after, before);
  }, [after, before]);
  return (
    <AppSelect<string>
      name={tTitle("season")}
      options={options}
      width={width}
      value={value}
      isReadOnly={readonly}
      isDisabled={isDisabled}
      onSelect={onSelect}
      isSearchable={true}
      isMultiple={true}
      isClearable={isClearable}
    />
  );
}
