import AppGrid from "@/features/order-sheet-sets/app-grid";
import { produce } from "immer";
import { useRecoilState } from "recoil";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useCallback, useEffect } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import {
  findGridRowByPath,
  findParentGridRow,
  GridRow,
} from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import useAgencyOrderSheetSetRows from "@/features/order-status/hooks/use-agency-order-sheet-set-rows";
import { agencyOrderSheetSetRowsState } from "@/features/order-status/recoils/agency-order-sheet-set-rows-state";
import { sum } from "lodash";
import {
  AgencyAttachmentRow,
  AgencyLineSheetRow,
} from "@/features/order-sheet-sets/line-sheet-set-row.type";
import {
  AgencyOrderSheetRow,
  AgencyOrderSheetSetRow,
} from "@/features/order-status/order-sheet-set-row.type";
import useReadOrderSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-read-order-sheet-set-infinite";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import { readOrderSheetSetKey } from "@/features/line-sheet-sets/hooks/use-read-order-sheet-set";
import { Flex, Text } from "@chakra-ui/react";
import { readDenseOrderSheetSetProactively } from "@/features/order-sheet-sets/hooks/use-list-dense-order-sheet-set";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import { downloadUnoFileKey } from "@/hooks/use-download-uno-file";
import { toTitle } from "@/utils/case";
import useLineSheetSetGrid from "@/features/order-sheet-sets/hooks/use-line-sheet-set-grid";
import useAppGridMutate from "@/features/ui/hooks/use-app-grid-mutate";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";

interface AgencyOrderSheetSetGridProps {}

export default function AgencyOrderSheetSetGrid({}: AgencyOrderSheetSetGridProps) {
  const identity = useIdentity();
  const { add: addToast, error: showError } = useAppToasts();
  const [rows, setRows] = useRecoilState(agencyOrderSheetSetRowsState);
  const { mutateRows } = useAgencyOrderSheetSetRows();
  const { discloseTooltip, hover } = useAppGridMutate(
    agencyOrderSheetSetRowsState
  );
  const { t, tCurrencyString, tLocalDateString, tLocalDateTimeString2 } =
    useI18nHelper();
  const { navigateToOrderSheetSetPage } = useRouterHelper();

  const {
    disclosureUploadOrderSheetModal,
    disclosureOrderOrderSheetSetModal,
    fields,
    actions,
    extraContents,
  } = useLineSheetSetGrid({
    rows,
    discloseTooltip,
    mutateRows,
  });

  const findParentRow = useCallback(
    (row: GridRow) => {
      return findParentGridRow(row, rows);
    },
    [rows]
  );

  const findGrandParentRow = useCallback(
    (row: GridRow) => {
      const parent = findParentRow(row);
      if (parent) {
        return findParentRow(parent);
      }
    },
    [findParentRow]
  );

  // API : Download Uploaded File

  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    clear: clearDownloadUnoFile,
  } = useDownloadUnoFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      downloadUnoFileData,
      clearDownloadUnoFile,
      showError,
      () => {}
    );
  }, [downloadUnoFileData, clearDownloadUnoFile, showError]);

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId,
      });
    },
    [downloadUnoFileData]
  );

  // END: API : Download Uploaded File

  // --- API : Read Order Sheet Set
  const {
    fire: fireReadOrderSheetSet,
    data: readOrderSheetSetData,
    clear: clearReadOrderSheetSet,
  } = useReadOrderSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      readOrderSheetSetData,
      clearReadOrderSheetSet,
      showError
    );
  }, [readOrderSheetSetData, clearReadOrderSheetSet, showError]);

  const findReadOrderSheetSetSegment = useCallback(
    (row: AgencyOrderSheetSetRow) => {
      if (identity?.company) {
        return findYWRInfiniteSegment(
          readOrderSheetSetData,
          readOrderSheetSetKey,
          {
            by: identity.company.type,
            companyId: identity.company.id,
            orderSheetSetId: row.id,
          }
        );
      }
    },
    [identity, readOrderSheetSetData]
  );

  // --- END: API : Read Order Sheet Set

  return (
    <>
      <AppGrid
        metas={[
          {
            isCheckable: true,
            rowType: "OrderSheetSet",
            showCheckAll: false,
            gap: "4px",
            columns: [
              { name: toTitle(t("type")), width: "56px" },
              { name: toTitle(t("season")), width: "48px" },
              { name: toTitle(t("issued_by")), width: "104px" },
              { name: toTitle(t("ordered_by")), width: "104px" },
              { name: toTitle(t("title")), width: "minmax(224px, 1fr)" },
              {
                name: toTitle(t("ordered_amount.abbr")),
                width: "132px",
                textAlign: "end",
                marginRight: "24px",
              },
              { name: toTitle(t("ordered_on")), width: "104px" },
              { name: toTitle(t("order_valid_until")), width: "152px" },
              { name: toTitle(t("status")), width: "162px" },
            ],
            fields: [
              fields.lineSheetSetType,
              {
                name: "season",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  return (r as AgencyOrderSheetSetRow).season;
                },
              },
              {
                name: "IssuedBy",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  return (r as AgencyOrderSheetSetRow).issuedByBoutiqueName;
                },
              },
              {
                name: "OrderedBy",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  return (r as AgencyOrderSheetSetRow).orderedByBuyerName;
                },
              },
              {
                name: "name",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const { name, id } = r as AgencyOrderSheetSetRow;
                  return `#${id} ${name}`;
                },
              },
              {
                name: "OrderedAmount",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const orderedAmount = (r as AgencyOrderSheetSetRow)
                    .orderedAmount;
                  const orderedAmountWithoutCondition = (
                    r as AgencyOrderSheetSetRow
                  ).orderedAmountWithoutCondition;
                  const orderedConditionType = (r as AgencyOrderSheetSetRow)
                    .orderedConditionType;
                  let conditionStr = "";
                  if (orderedConditionType === "DISCOUNT") {
                    conditionStr = "R- ";
                  } else if (orderedConditionType === "MARKUP") {
                    conditionStr = "C+ ";
                  } else if (orderedConditionType === "MIX") {
                    conditionStr = "R/C ";
                  }
                  return (
                    <Flex
                      flexDirection={"column"}
                      fontSize={"12px"}
                      alignItems={"flex-end"}
                      marginRight={"24px"}
                    >
                      <Text fontWeight={"700"}>
                        {tCurrencyString(orderedAmountWithoutCondition, {
                          type: "SYMBOL",
                          code: (r as AgencyOrderSheetSetRow).currency,
                        })}
                      </Text>
                      <Text>
                        ({conditionStr}
                        {tCurrencyString(orderedAmount, {
                          type: "SYMBOL",
                          code: (r as AgencyOrderSheetSetRow).currency,
                        })}
                        )
                      </Text>
                    </Flex>
                  );
                },
              },
              {
                name: "OrderedAt",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const value = (r as AgencyOrderSheetSetRow).submittedAt;
                  return tLocalDateString(value);
                },
              },
              {
                name: "validUntil",
                value: (r) => {
                  const value = (r as AgencyOrderSheetSetRow).validUntil;
                  return tLocalDateTimeString2(value);
                },
              },
              fields.withOrderSheetStatus(),
            ],
            actions: [
              actions.withDownloadUnoFile<AgencyOrderSheetSetRow>(
                (row) => row.compressedExportedFile
              ),
            ],
            indicator: (row) => {
              return (
                <GridRowIndicator
                  type={"VIEW"}
                  isHovered={row.isHovered}
                  isCollapsed={row.isCollapsed}
                />
              );
            },
            onClick: (row) => {
              const orderSheetSetRow = row as AgencyOrderSheetSetRow;
              navigateToOrderSheetSetPage(
                orderSheetSetRow.lineSheetSetId,
                orderSheetSetRow.id
              );
            },
          },
          {
            rowType: "LineSheet",
            columns: [
              { name: "type", width: "240px" },
              { name: "name", width: "1fr" },
              { name: "status", width: "144px" },
            ],
            fields: [
              fields.withRowType("line_sheet"),
              {
                name: "name",
                chakraProps: {
                  fontWeight: 700,
                },
                value: (r) => {
                  return (r as AgencyLineSheetRow).name;
                },
              },
              fields.lineSheetStatus,
            ],
            actions: [
              actions.viewLineSheet,
              actions.withDownloadUnoFile<AgencyLineSheetRow>(
                (row) => row.file
              ),
            ],
          },
          {
            rowType: "Attachment",
            columns: [
              { name: "type", width: "232px" },
              { name: "name", width: "1fr" },
              { name: "status", width: "144px" },
            ],
            fields: [
              fields.storedObjectField,
              {
                name: "name",
                value: (row) => {
                  const attachmentRow = row as AgencyAttachmentRow;
                  return attachmentRow.name;
                },
              },
            ],
            actions: [
              actions.withDownloadUnoFile<AgencyAttachmentRow>((row) => row),
            ],
          },
        ]}
        rows={rows}
        onHover={(path, row, isHovered) => {
          hover(row, isHovered);
        }}
        onClick={(path, _row) => {
          if (_row._rowType === "OrderSheetSet") {
            const row = _row as AgencyOrderSheetSetRow;

            if (!row.isRead && identity?.company) {
              readDenseOrderSheetSetProactively(row.id);

              const isLoading =
                findReadOrderSheetSetSegment(row)?.isLoading || false;
              if (!isLoading) {
                fireReadOrderSheetSet(
                  {
                    by: identity.company.type,
                    companyId: identity.company.id,
                    orderSheetSetId: row.id,
                  },
                  {}
                );
              }
            }

            // setRows((prev) => {
            //   return produce(prev, (draft) => {
            //     const target = findGridRowByPath(path, draft);
            //     if (target) {
            //       target.isCollapsed = !target.isCollapsed;
            //       target.isActive = !target.isCollapsed;
            //     }
            //   });
            // });
          }
        }}
        onCheck={(path, row, isChecked) => {
          if (!rows) {
            return;
          }

          if (row._rowType === "OrderSheetSet") {
            const selected_order_sheet_set = row as AgencyOrderSheetSetRow;
            //오더시트세트가 선택되면 나머지 오더시트세트는 전부 선택불가 처리

            rows.map((order_sheet_set, index) => {
              if (selected_order_sheet_set.id !== order_sheet_set.id) {
                setRows((prev) => {
                  return produce(prev, (draft) => {
                    const target = findGridRowByPath([index], draft);
                    if (target) {
                      if (isChecked) {
                        target.isCheckable = false;
                      } else {
                        target.isCheckable = target.isCheckableOriginal!!;
                      }
                    }
                  });
                });
              }
              //모든 오더시트 선택불가 처리
              order_sheet_set.children.map((row2, row2_index) => {
                if (row2._rowType == "LineSheet") {
                  const line_sheet = row2 as AgencyLineSheetRow;
                  (line_sheet.children as AgencyOrderSheetRow[]).map(
                    (order_sheet, order_sheet_index) => {
                      setRows((prev) => {
                        return produce(prev, (draft) => {
                          const target = findGridRowByPath(
                            [index, row2_index, order_sheet_index],
                            draft
                          );

                          if (target) {
                            if (isChecked) {
                              if (
                                order_sheet_set.id ===
                                selected_order_sheet_set.id
                              ) {
                                if (target.isCheckable) target.isChecked = true;
                              } else {
                                target.isCheckable = false;
                              }
                            } else {
                              if (
                                order_sheet_set.id ===
                                selected_order_sheet_set.id
                              ) {
                                if (target.isCheckable)
                                  target.isChecked = false;
                              } else {
                                target.isCheckable =
                                  target.isCheckableOriginal!!;
                              }
                            }
                          }
                        });
                      });
                    }
                  );
                }
              });
            });
          }
          if (row._rowType === "OrderSheet") {
            const selectedOrderSheet = row as AgencyOrderSheetRow;
            const selectedOrderSheetSet = findGrandParentRow(
              selectedOrderSheet
            ) as AgencyOrderSheetSetRow;
            let checkCount = 0;
            //체크된 오더시트 수 계산
            rows.map((orderSheetSet, index) => {
              orderSheetSet.children.map((row2, rowIndex) => {
                if (row2._rowType === "LineSheet") {
                  const lineSheet = row2 as AgencyLineSheetRow;
                  (lineSheet.children as AgencyOrderSheetRow[]).map(
                    (orderSheet, orderSheetIndex) => {
                      if (orderSheet.isChecked) checkCount += 1;
                    }
                  );
                }
              });
            });
            if (isChecked) checkCount += 1;
            else checkCount -= 1;
            rows.map((orderSheetSet, index) => {
              // 오더시트 선택에 따라 오더시트세트 선택 반영
              const clickableCount = sum(
                orderSheetSet.children
                  .filter((row2) => row2._rowType === "LineSheet")
                  .map(
                    (lineSheet, lineSheetIndex) =>
                      lineSheet.children.filter(
                        (orderSheet) => orderSheet.isCheckable
                      ).length
                  )
              );
              if (orderSheetSet.id !== selectedOrderSheetSet.id) {
                setRows((prev) => {
                  return produce(prev, (draft) => {
                    const target = findGridRowByPath([index], draft);
                    if (target) {
                      if (checkCount > 0) {
                        target.isCheckable = false;
                      } else {
                        target.isCheckable = target.isCheckableOriginal!!;
                      }
                    }
                  });
                });
              } else {
                if (
                  clickableCount > 0 &&
                  clickableCount === checkCount &&
                  orderSheetSet.isCheckable
                ) {
                  setRows((prev) => {
                    return produce(prev, (draft) => {
                      const target = findGridRowByPath([index], draft);
                      if (target && !target.isChecked) target.isChecked = true;
                    });
                  });
                } else {
                  setRows((prev) => {
                    return produce(prev, (draft) => {
                      const target = findGridRowByPath([index], draft);
                      if (target && target.isChecked) target.isChecked = false;
                    });
                  });
                }
              }
              //라인시트 찾아서 다른 오더시트세트의 오더시트 선택불가 처리
              orderSheetSet.children.map((row2, row2Index) => {
                if (
                  row2._rowType === "LineSheet" &&
                  orderSheetSet.id !== selectedOrderSheetSet.id
                ) {
                  const lineSheet = row2 as AgencyLineSheetRow;
                  (lineSheet.children as AgencyOrderSheetRow[]).map(
                    (orderSheet, orderSheetIndex) => {
                      setRows((prev) => {
                        return produce(prev, (draft) => {
                          const target = findGridRowByPath(
                            [index, row2Index, orderSheetIndex],
                            draft
                          );
                          if (target) {
                            if (checkCount > 0) {
                              target.isCheckable = false;
                            } else {
                              target.isCheckable = target.isCheckableOriginal!!;
                            }
                          }
                        });
                      });
                    }
                  );
                }
                //같은 오더시트세트 내에서도 상태가 다른 오더시트는 선택 불가 처리
                if (
                  row2._rowType === "LineSheet" &&
                  orderSheetSet.id === selectedOrderSheetSet.id
                ) {
                  const lineSheet = row2 as AgencyLineSheetRow;
                  (lineSheet.children as AgencyOrderSheetRow[]).map(
                    (orderSheet, orderSheetIndex) => {
                      if (
                        orderSheet.id !== selectedOrderSheet.id &&
                        orderSheet.status !== selectedOrderSheet.status
                      ) {
                        setRows((prev) => {
                          return produce(prev, (draft) => {
                            const target = findGridRowByPath(
                              [index, row2Index, orderSheetIndex],
                              draft
                            );

                            if (target) {
                              if (checkCount > 0) {
                                target.isCheckable = false;
                              } else {
                                target.isCheckable =
                                  target.isCheckableOriginal!!;
                              }
                            }
                          });
                        });
                      }
                    }
                  );
                }
              });
            });
          }
          setRows((prev) => {
            return produce(prev, (draft) => {
              const target = findGridRowByPath(path, draft);
              if (target) {
                target.isChecked = isChecked;
              }
            });
          });
        }}
      ></AppGrid>
    </>
  );
}
