import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  GetDenseOrderSheetSetRequest,
  GetDenseOrderSheetSetResponse,
  parseGetDenseOrderSheetSetKey,
} from "@/features/order-sheet-sets/hooks/use-get-dense-order-sheet-set";
import useCondenseOrderSheetSets from "@/features/order-sheet-sets/hooks/use-condense-order-sheet-sets";
import { DenseOrderSheetSet } from "@/features/order-sheets/order-sheet.type";
import { useSetRecoilState } from "recoil";
import { lineSheetSetFamily } from "@/features/line-sheet-sets/helpers/sheet-state";

export default function useGetDenseOrderSheetSetFetcher() {
  const condenseOrderSheetSets = useCondenseOrderSheetSets();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetDenseOrderSheetSetRequest>
    ): Promise<GetDenseOrderSheetSetResponse> => {
      const { parameter, request } = parseGetDenseOrderSheetSetKey(config);
      let orderSheetSet = {
        id: parameter.orderSheetSetId,
      };

      await condenseOrderSheetSets(
        [orderSheetSet],
        (i) => i,
        (item, value) => {
          if (!Array.isArray(value)) {
            orderSheetSet = value;
          }
        },
        {
          by: parameter.by,
          companyId: parameter.companyId,
        }
      );

      //없으면? 404를 내가 임의로 만들어줘야 하나?
      if ((orderSheetSet as DenseOrderSheetSet).lineSheetSet === undefined) {
        throw {
          error: {
            code: 404,
            message: "NOT_FOUND",
            status: "NOT_FOUND",
          },
        };
      } else {
        return Promise.resolve(orderSheetSet as DenseOrderSheetSet);
      }
    };
  }, [condenseOrderSheetSets]);
}
