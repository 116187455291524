import {
  LightLineSheetSet,
  LineSheetSet,
  LineSheetSetType,
  LineSheetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import { StoredObject } from "@/features/types";
import useYWR, { YWRConfig, YWRKey } from "@/features/ui/hooks/use-ywr";
import { calcUrl } from "@/features/ui/helpers/fetcher-helpers";
import useFetcher from "@/utils/use-fetcher";
import { AxiosRequestConfig } from "axios";

export interface UpdateLineSheetSetV2Request {
  name: string;
  season: string;
  type: LineSheetSetType;
  issuedByBrand?: {
    id: number;
  };
  issuedByBoutique: {
    id: number;
  };
  validUntil: Date;
  currency: string;
  lineSheets: {
    id: number;
    type: LineSheetType;
    reviewStatus: LineSheetSet["reviewStatus"];
    file: Pick<StoredObject, "id">;
  }[];
  attachments: Pick<StoredObject, "id">[];
  orderRules: string[];
  externalFileLinks: string[];
}

export interface UpdateLineSheetSetV2Response extends LightLineSheetSet {}

export interface UpdateLineSheetSetV2PathParameter {
  agencyId: number;
  lineSheetSetId: number;
}

export const updateLineSheetSetV2TemplateUrl = `/agencies/:agencyId/line-sheet-sets/:lineSheetSetId/v2`;

export const updateLineSheetSetV2Key: YWRKey<UpdateLineSheetSetV2PathParameter> =
  {
    url: (parameter) => {
      return calcUrl(updateLineSheetSetV2TemplateUrl, parameter);
    },
    method: "put",
  };

export default function useUpdateLineSheetSetV2(
  config?: AxiosRequestConfig<UpdateLineSheetSetV2Request>
) {
  const fetcher = useFetcher<
    UpdateLineSheetSetV2Request,
    UpdateLineSheetSetV2Response
  >();
  return useYWR(updateLineSheetSetV2Key, fetcher, {
    ...config,
    timeout: 3 * 60 * 1000,
  });
}
