import useCalcOrderSheetSetStatistic from "@/features/line-sheet-sets/hooks/use-calc-order-sheet-set-statistic";
import { useMemo } from "react";
import AppSheetSetBillboard, {
  BillBoardItem,
} from "@/features/line-sheet-sets/app-sheet-set-billboard";
import {
  DenseOrderSheetSet,
  getConditionTypeSymbol,
} from "@/features/order-sheets/order-sheet.type";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useRecoilValue } from "recoil";
import { clientSheetStatisticFamily } from "@/features/line-sheet-sets/helpers/sheet-state";
import { toUpper } from "lodash";
import useIdentity from "../ui/hooks/use-identity";
import AppOrderSheetStatusLabel from "../ui/app-order-sheet-status-label";
import OverlappedBillBoardCell from "@/features/order-sheet-sets/overlapped-bill-board-cell";

interface OrderSheetSetBillboardProps {
  orderSheetSet: DenseOrderSheetSet | undefined;
  selectedOrderSheetId: number;
  revisionNumber?: number;
}

export default function OrderSheetSetBillboard({
  orderSheetSet,
  selectedOrderSheetId,
  revisionNumber,
}: OrderSheetSetBillboardProps) {
  const { statistic: setStatistic } = useCalcOrderSheetSetStatistic({
    orderSheetSetId: orderSheetSet?.id || -1,
    orderSheetId: selectedOrderSheetId,
    revisionNumber: revisionNumber || -1,
    orderSheets: orderSheetSet?.orderSheets || [],
  });

  const {
    tTitle,
    tWithPrefix,
    tLocalDateString,
    tLocalDateTimeString2,
    tCurrencyString,
    tNumberString,
    tCountString,
  } = useI18nHelper();

  const identity = useIdentity();

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId: orderSheetSet?.id || -1,
      orderSheetId: selectedOrderSheetId,
      revisionNumber: revisionNumber || -1,
    };
  }, [orderSheetSet?.id, selectedOrderSheetId, revisionNumber]);

  const sheetStatisticState = useRecoilValue(
    clientSheetStatisticFamily(sheetKey)
  );

  const rows = useMemo((): (BillBoardItem | null)[][] => {
    function toOverlappedOrVanilla(
      top: number | string | null,
      under: number | string | null,
      isPrice: boolean = false
    ) {
      const func = isPrice
        ? (value: number | string) => {
            if (typeof value === "number") {
              return tCurrencyString(value);
            } else {
              return value;
            }
          }
        : (value: number | string) => {
            if (typeof value === "number") {
              return tCountString(value, "");
            } else {
              return value;
            }
          };
      if (top == null && under != null) {
        return func(under);
      } else if (under == null && top != null) {
        return func(top);
      } else if (top != null && under != null) {
        return {
          top: func(top),
          under: func(under),
        };
      } else {
        return func(0);
      }
    }

    if (orderSheetSet && sheetStatisticState) {
      const { lineSheetSet } = orderSheetSet;
      const { statistic: sheetStatistic } = sheetStatisticState;
      return [
        [
          { value: tTitle("status"), width: "64px" },
          {
            value: <AppOrderSheetStatusLabel value={orderSheetSet.status} />,
            width: "160px",
          },
          { value: tTitle("issued_on"), width: "64px" },
          {
            value: tLocalDateString(lineSheetSet.publishedAt),
            variant: "bold",
            width: "128px",
          },
          { value: tTitle("set_total_quantity"), width: "144px" },
          {
            // value: `${setStatistic.totalOrderedQuantity}`,
            value: (
              <OverlappedBillBoardCell
                value={toOverlappedOrVanilla(
                  setStatistic.totalOrderedQuantity,
                  setStatistic.firstOrderedQuantity,
                  false
                )}
              />
            ),
            width: "112px",
            variant: "bold",
            textAlign: "right",
          },
          { value: tTitle("common:sheet_total_quantity"), width: "144px" },
          {
            // value: `${sheetStatistic.totalOrderedQuantity}`,
            value: (
              <OverlappedBillBoardCell
                value={toOverlappedOrVanilla(
                  sheetStatistic.totalOrderedQuantity,
                  sheetStatistic.firstOrderedQuantity
                )}
              />
            ),
            width: "112px",
            variant: "bold",
            textAlign: "right",
          },
        ],
        [
          { value: tTitle("common:type") },
          {
            value: tWithPrefix(
              "line_sheet_set.type",
              lineSheetSet.type,
              toUpper
            ),
            variant: "bold",
          },
          { value: tTitle("common:valid_until") },
          {
            value: tLocalDateTimeString2(lineSheetSet.validUntil),
            variant: "bold",
          },
          { value: tTitle("common:set_total_amount") },
          {
            // value: `${tCurrencyString(setStatistic.totalOrderedAmountWithoutCondition)}`,
            value: (
              <OverlappedBillBoardCell
                value={toOverlappedOrVanilla(
                  tCurrencyString(
                    setStatistic.totalOrderedAmountWithoutCondition,
                    {
                      type: "SYMBOL",
                      code: lineSheetSet.currency,
                    }
                  ),
                  setStatistic.firstOrderedAmountWithoutCondition
                    ? tCurrencyString(
                        setStatistic.firstOrderedAmountWithoutCondition,
                        {
                          type: "SYMBOL",
                          code: lineSheetSet.currency,
                        }
                      )
                    : setStatistic.firstOrderedAmountWithoutCondition,
                  true
                )}
              />
            ),
            variant: "bold",
            textAlign: "right",
          },
          { value: tTitle("sheet_total_amount") },
          {
            // value: `${tCurrencyString(sheetStatistic.totalOrderedAmountWithoutCondition)}`,
            value: (
              <OverlappedBillBoardCell
                value={toOverlappedOrVanilla(
                  tCurrencyString(
                    sheetStatistic.totalOrderedAmountWithoutCondition,
                    {
                      type: "SYMBOL",
                      code: lineSheetSet.currency,
                    }
                  ),
                  sheetStatistic.firstOrderedAmountWithoutCondition
                    ? tCurrencyString(
                        sheetStatistic.firstOrderedAmountWithoutCondition,
                        {
                          type: "SYMBOL",
                          code: lineSheetSet.currency,
                        }
                      )
                    : sheetStatistic.firstOrderedAmountWithoutCondition,
                  true
                )}
              />
            ),
            variant: "bold",
            textAlign: "right",
          },
        ],
        [
          { value: tTitle("issued_by") },
          { value: lineSheetSet.issuedByBoutique.name, variant: "bold" },
          null,
          null,
          { value: tTitle("common:set_total_amount_with_condition") },
          {
            // value: `${tCurrencyString(setStatistic.totalOrderedAmount)}`,
            value: (
              <OverlappedBillBoardCell
                value={toOverlappedOrVanilla(
                  tCurrencyString(setStatistic.totalOrderedAmount, {
                    type: "SYMBOL",
                    code: lineSheetSet.currency,
                  }),
                  setStatistic.firstOrderedAmount
                    ? tCurrencyString(setStatistic.firstOrderedAmount, {
                        type: "SYMBOL",
                        code: lineSheetSet.currency,
                      })
                    : setStatistic.firstOrderedAmount,
                  true
                )}
              />
            ),
            variant: "bold",
            textAlign: "right",
          },
          { value: tTitle("sheet_total_amount_with_condition") },
          {
            // value: `(${getConditionTypeSymbol(
            //   sheetStatistic.orderedConditionType
            // )}) ${tCurrencyString(sheetStatistic.totalOrderedAmount)}`,
            value: (
              <OverlappedBillBoardCell
                value={toOverlappedOrVanilla(
                  `${getConditionTypeSymbol(
                    sheetStatistic.orderedConditionType
                  )} ${tCurrencyString(sheetStatistic.totalOrderedAmount, {
                    type: "SYMBOL",
                    code: lineSheetSet.currency,
                  })}`,
                  sheetStatistic.firstOrderedAmount != null
                    ? `${getConditionTypeSymbol(
                        sheetStatistic.firstOrderedConditionType
                      )} ${tCurrencyString(sheetStatistic.firstOrderedAmount, {
                        type: "SYMBOL",
                        code: lineSheetSet.currency,
                      })}`
                    : `${getConditionTypeSymbol(
                        sheetStatistic.orderedConditionType
                      )} ${tCurrencyString(sheetStatistic.totalOrderedAmount, {
                        type: "SYMBOL",
                        code: lineSheetSet.currency,
                      })}`,
                  true
                )}
              />
            ),
            variant: "bold",
            textAlign: "right",
          },
        ],
      ];
    }
    return [];
  }, [
    orderSheetSet,
    setStatistic.firstOrderedAmount,
    setStatistic.firstOrderedAmountWithoutCondition,
    setStatistic.firstOrderedQuantity,
    setStatistic.totalOrderedAmount,
    setStatistic.totalOrderedAmountWithoutCondition,
    setStatistic.totalOrderedQuantity,
    sheetStatisticState,
    tCountString,
    tCurrencyString,
    tLocalDateString,
    tLocalDateTimeString2,
    tTitle,
    tWithPrefix,
  ]);

  return <AppSheetSetBillboard rows={rows} />;
}
