import { ChakraProps, Flex, Input, Text } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { useMemo } from "react";
import { getCurrencySymbol, isPrice, Price } from "@/features/types";

export interface PriceInputValue {
  value?: number;
  currency: Price["currency"] | "EA" | "%";
}

function isPriceInputValue(candidate: any): candidate is PriceInputValue {
  return (
    candidate &&
    (candidate.value === undefined || typeof candidate.value === "number") &&
    candidate.currency
  );
}

interface AppPriceInputProps {
  value?: PriceInputValue;
  currency?: PriceInputValue["currency"];
  currencyWidth?: string;
  onChange?: (value?: PriceInputValue) => void;
  isDisabled?: boolean;
  width?: string;
  isReadOnly?: boolean;
  isLiteral?: boolean;
}

function calcCurrencyStyle({
  isDisabled,
  isReadOnly,
}: Pick<AppPriceInputProps, "isDisabled" | "isReadOnly">): ChakraProps {
  const defaultStyle: ChakraProps = {
    borderRadius: "0 4px 4px 0",
    borderWidth: "0.5px",
    borderStyle: "solid",
    borderColor: "#8F8F8C",
    backgroundColor: "#FCFCFB",
  };

  if (isDisabled) {
    return {
      ...defaultStyle,
      borderColor: "#8F8F8C",
      backgroundColor: "#EFEFED",
      color: "#8F8F8C",
    };
  } else if (isReadOnly) {
    return {
      ...defaultStyle,
      borderColor: "#6F6F6C",
      backgroundColor: "#FCFCFB",
      color: "#6F6F6C",
    };
  }

  return defaultStyle;
}

function calcInputStyle({
  isDisabled,
  isReadOnly,
  isLiteral,
}: AppPriceInputProps): ChakraProps {
  const defaultStyle: ChakraProps = {
    borderRadius: "4px 0px 0px 4px",
    borderTop: "0.5px solid var(--Gray-Groomy, #8F8F8C)",
    borderBottom: "0.5px solid var(--Gray-Groomy, #8F8F8C)",
    borderLeft: "0.5px solid var(--Gray-Groomy, #8F8F8C)",
    background: "var(--White, #FFF)",
    _hover: {},
  };

  if (isDisabled) {
    return {
      ...defaultStyle,
      background: "var(--pathrade-gray-light, #EFEFED)",
      color: "#8F8F8C",
    };
  } else if (isReadOnly) {
    return {
      ...defaultStyle,
      borderColor: "#6F6F6C",
      backgroundColor: "#FFF",
      borderRight: "none",
      color: "#6F6F6C",
      opacity: "1.0 !important",
    };
  }

  return defaultStyle;
}

export default function AppPriceInput({
  value: _value,
  currency: _currency = "EUR",
  onChange = () => {},
  width = "100%",
  isDisabled = false,
  isReadOnly = false,
  currencyWidth,
  isLiteral = false,
}: AppPriceInputProps) {
  const { tNumberString, tCurrencyString } = useI18nHelper();
  const [value, symbol] = useMemo(() => {
    if (_value !== undefined) {
      return [_value.value, getCurrencySymbol(_value.currency)];
    }

    return [undefined, getCurrencySymbol(_currency)];
  }, [_currency, _value]);

  const readableValue = useMemo(() => {
    if (value !== undefined) {
      if (isReadOnly && _currency !== "EA") {
        return tCurrencyString(value, { type: "NONE" });
      } else if (isLiteral) {
        return tCurrencyString(value, { type: "SYMBOL", code: _currency });
      }
      return value;
    }
    return "";
  }, [_currency, isReadOnly, isLiteral, tCurrencyString, value]);

  if (isLiteral) {
    return (
      <Text fontSize={"12px"} textAlign={"start"}>
        {readableValue}
      </Text>
    );
  }

  return (
    <Flex height={"28px"} fontSize={"12px"} flexDirection={"row"} width={width}>
      <Input
        padding={"0 8px"}
        margin={"0"}
        minHeight={"0"}
        height={"auto"}
        fontSize={"inherit"}
        type={isReadOnly ? "text" : "number"}
        value={readableValue}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        placeholder={"0"}
        textAlign={"end"}
        {...calcInputStyle({ isDisabled, isReadOnly })}
        onChange={(event) => {
          let value: number | undefined = parseFloat(event.target.value);
          if (isNaN(value)) {
            value = undefined;
          }
          onChange({
            value,
            currency: _currency,
          });
        }}
      />
      <Text
        padding={"0 8px"}
        alignSelf={"stretch"}
        display={"inline-flex"}
        alignItems={"center"}
        width={currencyWidth}
        {...calcCurrencyStyle({ isDisabled, isReadOnly })}
      >
        {symbol}
      </Text>
    </Flex>
  );
}
