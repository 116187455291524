import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import { UploadOrderSheetsModalProps } from "@/features/order-sheet-sets/upload-order-sheets-modal";
import { useCallback, useEffect, useMemo } from "react";
import {
  findParentGridRow,
  getDescendantsByType,
  GridRow,
} from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { useTranslation } from "react-i18next";
import { useDumbTransactions } from "@/features/ui/dumb-transaction/dumb-transaction-state";
import useIdentity from "@/features/ui/hooks/use-identity";
import { toLower, toSnake, toTitle } from "@/utils/case";
import { Company, StoredObject } from "@/features/types";
import {
  isLineSheetSetStatus,
  LineSheetSet,
  LineSheetSetStatus,
  LineSheetSetType,
  LineSheetType,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import {
  BuyerOrderSheetRow,
  BuyerOrderSheetSetRow,
  OrderSheetRow,
  OrderSheetSetRow,
} from "@/features/order-status/order-sheet-set-row.type";
import {
  AgencyLineSheetRow,
  AgencyLineSheetSetRow,
  BuyerLineSheetSetRow,
  LineSheetRow,
} from "@/features/order-sheet-sets/line-sheet-set-row.type";
import useDeleteOrderSheetUploadedFileInfinite from "@/features/order-sheet-sets/hooks/use-delete-order-sheet-uploaded-file-infinite";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import { deleteOrderSheetUploadedFileKey } from "@/features/order-sheet-sets/hooks/use-delete-order-sheet-uploaded-file";
import useNavigateToOrderSheetSetPage from "@/features/order-sheet-sets/hooks/use-navigate-to-order-sheet-set-page";
import { downloadUnoFileKey } from "@/hooks/use-download-uno-file";
import useDownloadUnoFileInfinite from "@/hooks/use-download-uno-file-infinite";
import AppGridRowAttachmentExtraContent from "@/features/ui/app-grid-row-attachment-extra-content";
import useCreateOrderSheetSeamlesslyInfinite from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly-infinite";
import { isErrorResponse } from "@/utils/use-fetcher";
import {
  createOrderSheetSeamlesslyKey,
  CreateOrderSheetSeamlesslyParameter,
  CreateOrderSheetSeamlesslyResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-seamlessly";
import { CreateFallbackOrderSheetSeamlesslyParameter } from "@/features/line-sheet-sets/hooks/use-create-fallback-order-sheet-seamlessly";
import useNavigateToLineSheetSetPage from "@/features/line-sheet-sets/hooks/use-navigate-to-line-sheet-set-page";
import { OrderOrderSheetSetModalProps } from "@/features/order-sheet-sets/order-order-sheet-set-modal";
import {
  updateOrderSheetSetStatusKey,
  UpdateOrderSheetSetStatusRequest,
} from "@/features/order-status/hooks/use-update-order-sheet-set-status";
import useUpdateOrderSheetSetStatusInfinite from "@/features/order-status/hooks/use-update-order-sheet-set-status-infinite";
import {
  isOrderSheetOrderedToAgency,
  isOrderSheetStatus,
} from "@/features/order-sheets/helpers/order-sheet-helpers";
import AppStatusLabel from "@/features/ui/app-status-label";
import {
  AppGridColumn,
  AppGridContext,
  AppGridField,
  AppGridMeta,
} from "@/features/order-sheet-sets/app-grid";
import useCopyOrderSheetInfinite from "@/features/order-status/hooks/use-copy-order-sheet-infinite";
import { copyOrderSheetKey } from "@/features/order-status/hooks/use-copy-order-sheet";
import { toUpper } from "lodash";
import AppOrderSheetStatusLabel from "@/features/ui/app-order-sheet-status-label";
import useUpdateLineSheetSetReviewStatusInfinite from "@/features/line-sheet-sets/hooks/use-line-sheet-set-review-status-infinite";
import { updateLineSheetSetReviewStatusKey } from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-review-status";
import {
  ConditionType,
  getConditionTypeSymbol,
  OrderSheetStatus,
} from "@/features/order-sheets/order-sheet.type";
import { Flex, Text } from "@chakra-ui/react";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppGridCell from "@/features/order-sheet-sets/app-grid-cell";
import { OrderConfirmationInvoice } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice.type";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import usePublishLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-publish-line-sheet-set-infinite";
import { publishLineSheetSetKey } from "@/features/line-sheet-sets/hooks/use-publish-line-sheet-set";
import usePublishLineSheetSetIfConfirmed from "@/features/line-sheet-sets/hooks/use-publish-line-sheet-set-if-confirmed";
import useAppGrid from "@/features/invoices/hooks/use-app-grid";
import AppLineSheetSetStatusLabel from "@/features/ui/app-line-sheet-set-status-label";
import AppLineSheetSetCompressionStatusLabel from "@/features/ui/app-line-sheet-set-compression-status-label";
import AppReviewStatusLabel from "@/features/ui/app-review-status-label";
import useReparseLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet-set-infinite";
import { reparseLineSheetSetKey } from "@/features/line-sheet-sets/hooks/use-reparse-line-sheet-set";
import useDeleteLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-delete-line-sheet-set-infinite";
import { deleteLineSheetSetKey } from "@/features/line-sheet-sets/hooks/use-delete-line-sheet-set";
import useRestoreLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-restore-line-sheet-set-infinite";
import { restoreLineSheetSetKey } from "@/features/line-sheet-sets/hooks/use-restore-line-sheet-set";
import useReportLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-report-line-sheet-set-infinite";
import { reportLineSheetSetKey } from "@/features/line-sheet-sets/hooks/use-report-line-sheet-set";
import { IsolatedLineSheetRow } from "@/features/line-sheet-sets/detail/isolated-sheet.type";

interface UseLineSheetSetGridProps {
  rows?: GridRow[];
  discloseTooltip?: (row: GridRow, isOpen: boolean) => void;
  mutateRows: () => void;
}

interface LineSheetSetOrOrderSheetSetRow extends GridRow {
  orderSheetSetId?: number;
  lineSheetSetId?: number;
  type: LineSheetSetType;
}

export default function useLineSheetSetGrid({
  rows,
  discloseTooltip,
  mutateRows,
}: UseLineSheetSetGridProps) {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const appGrid = useAppGrid();
  const { t, tCurrencyString } = useI18nHelper();
  const disclosureUploadOrderSheetModal =
    useDisclosureWithData<UploadOrderSheetsModalProps>();
  const disclosureOrderOrderSheetSetModal =
    useDisclosureWithData<OrderOrderSheetSetModalProps>();

  const navigateToLineSheetSetPage = useNavigateToLineSheetSetPage();

  const {
    findCreateFallbackOrderSheetSeamlesslyTransaction,
    findUpdateFallbackOrderSheetTransaction,
  } = useDumbTransactions();

  const { navigateToOrderSheetSetPage, navigateToOrderSheetSetDraftPage } =
    useRouterHelper();

  const unsafe_destructureLineSheetSetOrOrderSheetSetRow = useCallback(
    (_row: GridRow) => {
      const lineSheetSetOrOrderSheetSetRow =
        _row as LineSheetSetOrOrderSheetSetRow;

      const lineSheetSetId =
        lineSheetSetOrOrderSheetSetRow.lineSheetSetId ||
        lineSheetSetOrOrderSheetSetRow.id;

      const orderSheetSetId =
        lineSheetSetOrOrderSheetSetRow.orderSheetSetId ||
        lineSheetSetOrOrderSheetSetRow.id;

      return {
        lineSheetSetOrOrderSheetSetRow,
        lineSheetSetId,
        orderSheetSetId,
      };
    },
    []
  );

  const unsafe_destructureLineSheetRow = useCallback(
    (_row: GridRow) => {
      const lineSheetRow = _row as LineSheetRow;
      const lineSheetSetOrOrderSheetSetRow = findParentGridRow(
        lineSheetRow,
        rows
      ) as LineSheetSetOrOrderSheetSetRow;

      const lineSheetSetId =
        lineSheetSetOrOrderSheetSetRow.lineSheetSetId ||
        lineSheetSetOrOrderSheetSetRow.id;

      const orderSheetSetId =
        lineSheetSetOrOrderSheetSetRow.orderSheetSetId ||
        lineSheetSetOrOrderSheetSetRow.id;

      return {
        lineSheetRow,
        lineSheetSetOrOrderSheetSetRow,
        lineSheetSetId,
        orderSheetSetId,
      };
    },
    [rows]
  );

  const unsafe_destructureOrderSheetRow = useCallback(
    (_row: GridRow) => {
      const orderSheetRow = _row as OrderSheetRow;
      const lineSheetRow = findParentGridRow(_row, rows) as LineSheetRow;

      const lineSheetSetOrOrderSheetSetRow = findParentGridRow(
        lineSheetRow,
        rows
      ) as LineSheetSetOrOrderSheetSetRow;

      const lineSheetSetId =
        lineSheetSetOrOrderSheetSetRow.lineSheetSetId ||
        lineSheetSetOrOrderSheetSetRow.id;
      const orderSheetSetId =
        lineSheetSetOrOrderSheetSetRow.orderSheetSetId ||
        lineSheetSetOrOrderSheetSetRow.id;

      return {
        orderSheetRow,
        lineSheetRow,
        lineSheetSetOrOrderSheetSetRow,
        lineSheetSetId,
        orderSheetSetId,
      };
    },
    [rows]
  );

  // --- API: Delete Order Sheet Uploaded File
  const {
    data: deleteOrderSheetUploadedFileData,
    fire: fireDeleteOrderSheetUploadedFile,
    clear: clearDeleteOrderSheetUploadedFile,
  } = useDeleteOrderSheetUploadedFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      deleteOrderSheetUploadedFileData,
      clearDeleteOrderSheetUploadedFile,
      showError,
      (response) => {
        mutateRows();
      }
    );
  }, [
    deleteOrderSheetUploadedFileData,
    clearDeleteOrderSheetUploadedFile,
    showError,
    mutateRows,
  ]);

  const findDeleteOrderSheetUploadedFileSegment = useCallback(
    (orderSheetSetId: number, orderSheetId: number) => {
      if (identity?.company) {
        return findYWRInfiniteSegment(
          deleteOrderSheetUploadedFileData,
          deleteOrderSheetUploadedFileKey,
          {
            by: identity.company.type,
            companyId: identity.company.id,
            orderSheetSetId: orderSheetSetId,
            orderSheetId: orderSheetId,
          }
        );
      }
    },
    [identity, deleteOrderSheetUploadedFileData]
  );

  // --- END: API: Delete Order Sheet Uploaded File

  // API : Download Uno File
  const {
    data: downloadUnoFileData,
    fire: fireDownloadUnoFile,
    clear: clearDownloadUnoFile,
  } = useDownloadUnoFileInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      downloadUnoFileData,
      clearDownloadUnoFile,
      showError,
      () => {}
    );
  }, [downloadUnoFileData, clearDownloadUnoFile, showError]);

  const findDownloadUnoFileSegment = useCallback(
    (unoFileId: number) => {
      return findYWRInfiniteSegment(downloadUnoFileData, downloadUnoFileKey, {
        unoFileId: unoFileId,
      });
    },
    [downloadUnoFileData]
  );

  // END: API : Download Uno File

  // API: Create Order Sheet Seamlessly
  const {
    data: createOrderSheetSeamlesslyData,
    fire: fireCreateOrderSheetSeamlessly,
    clear: clearCreateOrderSheetSeamlessly,
  } = useCreateOrderSheetSeamlesslyInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      createOrderSheetSeamlesslyData,
      clearCreateOrderSheetSeamlessly,
      (error) => {
        showError(error);
        if (isErrorResponse(error) && error.error.code === 409) {
          mutateRows();
        }
      },
      (response: CreateOrderSheetSeamlesslyResponse[]) => {
        //mutateRows();
        if (response.length > 0) {
          const last = response[response.length - 1];
          navigateToOrderSheetSetDraftPage(last._orderSheetSetId!, last.id);
        }
      }
    );
  }, [
    createOrderSheetSeamlesslyData,
    clearCreateOrderSheetSeamlessly,
    showError,
    navigateToOrderSheetSetDraftPage,
    mutateRows,
  ]);

  const findCreateOrderSheetSegment = useCallback(
    (parameter: CreateOrderSheetSeamlesslyParameter) => {
      return findYWRInfiniteSegment(
        createOrderSheetSeamlesslyData,
        createOrderSheetSeamlesslyKey,
        parameter
      );
    },
    [createOrderSheetSeamlesslyData]
  );

  // END: API: Create Order Sheet Seamlessly

  // --- API: Update Line Sheet Set Review Status ---
  const {
    fire: fireUpdateLineSheetSetReviewStatus,
    data: updateLineSheetSetReviewStatusData,
    clear: clearUpdateLineSheetSetReviewStatus,
  } = useUpdateLineSheetSetReviewStatusInfinite();

  const findUpdateLineSheetSetReviewStatusSegment = useCallback(
    (lineSheetSetId: number) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(
          updateLineSheetSetReviewStatusData,
          updateLineSheetSetReviewStatusKey,
          {
            agencyId: identity.company.id,
            lineSheetSetId,
          }
        );
      }
    },
    [identity, updateLineSheetSetReviewStatusData]
  );

  useEffect(() => {
    postProcessYWRInfiniteData(
      updateLineSheetSetReviewStatusData,
      clearUpdateLineSheetSetReviewStatus,
      showError,
      () => {
        mutateRows();
      }
    );
  }, [
    clearUpdateLineSheetSetReviewStatus,
    mutateRows,
    showError,
    updateLineSheetSetReviewStatusData,
  ]);

  // --- END: API: Update Line Sheet Set Review Status ---

  // --- API: Update Order Sheet Set Status ---
  const {
    fire: fireUpdateOrderSheetSetStatus,
    data: updateOrderSheetSetStatusData,
    clear: clearUpdateOrderSheetSetStatus,
  } = useUpdateOrderSheetSetStatusInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      updateOrderSheetSetStatusData,
      clearUpdateOrderSheetSetStatus,
      showError,
      (response) => {
        mutateRows();
      }
    );
  }, [
    updateOrderSheetSetStatusData,
    clearUpdateOrderSheetSetStatus,
    showError,
    mutateRows,
  ]);

  const findUpdateOrderSheetSetStatusSegment = useCallback(
    (orderSheetSetId: number) => {
      if (identity?.company) {
        return findYWRInfiniteSegment(
          updateOrderSheetSetStatusData,
          updateOrderSheetSetStatusKey,
          {
            by: identity.company.type,
            companyId: identity.company.id,
            orderSheetSetId: orderSheetSetId,
          }
        );
      }
    },
    [identity, updateOrderSheetSetStatusData]
  );

  // --- END: API: Update Order Sheet Set Status ---

  // API : Copy Order Sheet
  const {
    data: copyOrderSheetData,
    fire: fireCopyOrderSheet,
    clear: clearCopyOrderSheet,
  } = useCopyOrderSheetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      copyOrderSheetData,
      clearCopyOrderSheet,
      showError,
      () => {
        mutateRows();
      }
    );
  }, [copyOrderSheetData, clearCopyOrderSheet, showError, mutateRows]);

  const findCopyOrderSheetSegment = useCallback(
    (parameter: { orderSheetSetId: number; orderSheetId: number }) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(copyOrderSheetData, copyOrderSheetKey, {
          agencyId: identity.company.id,
          orderSheetSetId: parameter.orderSheetSetId,
          _sourceOrderSheetId: parameter.orderSheetId,
        });
      }
    },
    [identity, copyOrderSheetData]
  );

  // --- END: API : Copy Order Sheet

  // API: Reparse Line Sheet Set
  const {
    data: reparseLineSheetSetData,
    fire: fireReparseLineSheetSet,
    clear: clearReparseLineSheetSet,
  } = useReparseLineSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      reparseLineSheetSetData,
      clearReparseLineSheetSet,
      showError,
      () => {
        mutateRows();
      }
    );
  }, [
    reparseLineSheetSetData,
    clearReparseLineSheetSet,
    showError,
    mutateRows,
  ]);

  const findReparseLineSheetSetSegment = useCallback(
    (lineSheetSetId: number) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(
          reparseLineSheetSetData,
          reparseLineSheetSetKey,
          {
            agencyId: identity.company.id,
            lineSheetSetId,
          }
        );
      }
    },
    [identity, reparseLineSheetSetData]
  );

  // END API: Reparse Line Sheet Set

  // API: Delete Line Sheet Set
  const {
    data: deleteLineSheetSetData,
    fire: fireDeleteLineSheetSet,
    clear: clearDeleteLineSheetSet,
  } = useDeleteLineSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      deleteLineSheetSetData,
      clearDeleteLineSheetSet,
      showError,
      () => {
        mutateRows();
      }
    );
  }, [deleteLineSheetSetData, clearDeleteLineSheetSet, showError, mutateRows]);

  const findDeleteLineSheetSetSegment = useCallback(
    (lineSheetSetId: number) => {
      if (identity?.company) {
        return findYWRInfiniteSegment(
          deleteLineSheetSetData,
          deleteLineSheetSetKey,
          {
            by: identity.company.type,
            companyId: identity.company.id,
            lineSheetSetId,
          }
        );
      }
    },
    [identity, deleteLineSheetSetData]
  );

  // END API: Delete Line Sheet Set

  // API: Restore Line Sheet Set
  const {
    data: restoreLineSheetSetData,
    fire: fireRestoreLineSheetSet,
    clear: clearRestoreLineSheetSet,
  } = useRestoreLineSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      restoreLineSheetSetData,
      clearRestoreLineSheetSet,
      showError,
      () => {
        mutateRows();
      }
    );
  }, [
    restoreLineSheetSetData,
    clearRestoreLineSheetSet,
    showError,
    mutateRows,
  ]);

  const findRestoreLineSheetSetSegment = useCallback(
    (lineSheetSetId: number) => {
      if (identity?.company) {
        return findYWRInfiniteSegment(
          restoreLineSheetSetData,
          restoreLineSheetSetKey,
          {
            by: identity.company.type,
            companyId: identity.company.id,
            lineSheetSetId,
          }
        );
      }
    },
    [identity, restoreLineSheetSetData]
  );

  // END API: Restore Line Sheet Set

  // API: Report Line Sheet Set
  const {
    data: reportLineSheetSetData,
    fire: fireReportLineSheetSet,
    clear: clearReportLineSheetSet,
  } = useReportLineSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      reportLineSheetSetData,
      clearReportLineSheetSet,
      showError,
      () => {
        mutateRows();
      }
    );
  }, [reportLineSheetSetData, clearReportLineSheetSet, showError, mutateRows]);

  const findReportLineSheetSetSegment = useCallback(
    (lineSheetSetId: number) => {
      if (identity?.company?.type === "AGENCY") {
        return findYWRInfiniteSegment(
          reportLineSheetSetData,
          reportLineSheetSetKey,
          {
            agencyId: identity.company.id,
            lineSheetSetId,
          }
        );
      }
    },
    [identity, reportLineSheetSetData]
  );

  // END API: Report Line Sheet Set

  const { fire: firePublishLineSheetSetIfConfirmed } =
    usePublishLineSheetSetIfConfirmed();

  //actions

  const reparseLineSheetSet = useCallback(
    (_row: GridRow) => {
      const { id, status, publishedAt, isDeleted } = _row as unknown as Pick<
        AgencyLineSheetSetRow,
        "id" | "status" | "publishedAt" | "isDeleted"
      >;

      if (
        !publishedAt &&
        !isDeleted &&
        (status === "PARSING_PENDING" || status === "PARSING_FAILED")
      ) {
        return {
          name: toTitle(t("reparse")),
          isLoading: findReparseLineSheetSetSegment(id)?.isLoading || false,
          onClick: () => {
            if (identity?.company?.type === "AGENCY") {
              fireReparseLineSheetSet(
                {
                  agencyId: identity.company.id,
                  lineSheetSetId: id,
                },
                {}
              );
            }
          },
        };
      }
    },
    [findReparseLineSheetSetSegment, fireReparseLineSheetSet, identity, t]
  );

  const deleteLineSheetSet = useCallback(
    (_row: GridRow) => {
      const { publishedAt, isDeleted } = _row as GridRow & {
        publishedAt: Date | null;
        isDeleted: boolean;
      };

      if (publishedAt === null && !isDeleted) {
        return {
          name: toTitle(t("delete.do")),
          onClick: () => {
            if (identity?.company) {
              fireDeleteLineSheetSet(
                {
                  by: identity.company.type,
                  companyId: identity.company.id,
                  lineSheetSetId: _row.id,
                },
                {}
              );
            }
          },
          isLoading: findDeleteLineSheetSetSegment(_row.id)?.isLoading || false,
        };
      }
    },
    [findDeleteLineSheetSetSegment, fireDeleteLineSheetSet, identity, t]
  );

  const restoreLineSheetSet = useCallback(
    (_row: GridRow) => {
      const { isDeleted } = _row as GridRow & { isDeleted: boolean };

      if (isDeleted) {
        return {
          name: toTitle(t("restore.do")),
          onClick: () => {
            if (identity?.company) {
              fireRestoreLineSheetSet(
                {
                  by: identity.company.type,
                  companyId: identity.company.id,
                  lineSheetSetId: _row.id,
                },
                {}
              );
            }
          },
          isLoading:
            findRestoreLineSheetSetSegment(_row.id)?.isLoading || false,
        };
      }
    },
    [findRestoreLineSheetSetSegment, fireRestoreLineSheetSet, identity, t]
  );

  const publishLineSheetSet = useCallback(
    (_row: GridRow) => {
      const lineSheetSetRow = _row as AgencyLineSheetSetRow;

      if (
        lineSheetSetRow.status !== "PARSING_PENDING" &&
        !lineSheetSetRow.isDeleted
      ) {
        return {
          name: toTitle(t("publish.do")),
          isDisabled: lineSheetSetRow.compressionStatus !== "COMPLETED",
          onClick: async () => {
            const result = await firePublishLineSheetSetIfConfirmed([
              lineSheetSetRow.id,
            ]);
            if (result) {
              mutateRows();
            }
          },
        };
      }
    },
    [firePublishLineSheetSetIfConfirmed, mutateRows, t]
  );

  const reportLineSheetSet = useCallback(
    (_row: GridRow) => {
      const lineSheetSetRow = _row as AgencyLineSheetSetRow;

      if (
        lineSheetSetRow.status === "PARSING_COMPLETED" &&
        !lineSheetSetRow.isDeleted &&
        !lineSheetSetRow.publishedAt
      ) {
        return {
          name: toTitle(t("force_parsing_failed")),
          onClick: () => {
            if (identity?.company?.type === "AGENCY") {
              fireReportLineSheetSet(
                {
                  agencyId: identity.company.id,
                  lineSheetSetId: lineSheetSetRow.id,
                },
                {}
              );
            }
          },
          isLoading: findReportLineSheetSetSegment(lineSheetSetRow.id)
            ?.isLoading,
        };
      }
    },
    [findReportLineSheetSetSegment, fireReportLineSheetSet, identity, t]
  );

  const pendLineSheetSetReviewStatus = useCallback(
    (_row: GridRow) => {
      const lineSheetSetRow = _row as AgencyLineSheetSetRow;
      if (
        lineSheetSetRow.publishedAt === null &&
        lineSheetSetRow.reviewStatus &&
        !lineSheetSetRow.isDeleted &&
        ["APPROVED", "REJECTED"].includes(lineSheetSetRow.reviewStatus)
      ) {
        return {
          name: toTitle(t("review_pend.do")),
          isLoading:
            findUpdateLineSheetSetReviewStatusSegment(lineSheetSetRow.id)
              ?.isLoading || false,
          onClick: () => {
            if (identity?.company?.type === "AGENCY") {
              fireUpdateLineSheetSetReviewStatus(
                {
                  agencyId: identity.company.id,
                  lineSheetSetId: lineSheetSetRow.id,
                },
                {
                  reviewStatus: "PENDING",
                }
              );
            }
          },
        };
      }
    },
    [
      findUpdateLineSheetSetReviewStatusSegment,
      fireUpdateLineSheetSetReviewStatus,
      identity,
      t,
    ]
  );

  const approveLineSheetSetReviewStatus = useCallback(
    (_row: GridRow) => {
      const lineSheetSetRow = _row as AgencyLineSheetSetRow;
      if (
        lineSheetSetRow.publishedAt === null &&
        lineSheetSetRow.reviewStatus &&
        !lineSheetSetRow.isDeleted &&
        ["PENDING", "REJECTED"].includes(lineSheetSetRow.reviewStatus)
      ) {
        return {
          name: toTitle(t("review_approve.do")),
          isLoading:
            findUpdateLineSheetSetReviewStatusSegment(lineSheetSetRow.id)
              ?.isLoading || false,
          onClick: () => {
            if (identity?.company?.type === "AGENCY") {
              fireUpdateLineSheetSetReviewStatus(
                {
                  agencyId: identity.company.id,
                  lineSheetSetId: lineSheetSetRow.id,
                },
                {
                  reviewStatus: "APPROVED",
                }
              );
            }
          },
        };
      }
    },
    [
      findUpdateLineSheetSetReviewStatusSegment,
      fireUpdateLineSheetSetReviewStatus,
      identity,
      t,
    ]
  );

  const rejectLineSheetSetReviewStatus = useCallback(
    (_row: GridRow) => {
      const lineSheetSetRow = _row as AgencyLineSheetSetRow;
      if (
        lineSheetSetRow.publishedAt === null &&
        lineSheetSetRow.reviewStatus &&
        !lineSheetSetRow.isDeleted &&
        ["PENDING", "APPROVED"].includes(lineSheetSetRow.reviewStatus)
      ) {
        return {
          name: toTitle(t("review_reject.do")),
          isLoading:
            findUpdateLineSheetSetReviewStatusSegment(lineSheetSetRow.id)
              ?.isLoading || false,
          onClick: () => {
            if (identity?.company?.type === "AGENCY") {
              fireUpdateLineSheetSetReviewStatus(
                {
                  agencyId: identity.company.id,
                  lineSheetSetId: lineSheetSetRow.id,
                },
                {
                  reviewStatus: "REJECTED",
                }
              );
            }
          },
        };
      }
    },
    [
      findUpdateLineSheetSetReviewStatusSegment,
      fireUpdateLineSheetSetReviewStatus,
      identity,
      t,
    ]
  );

  const goToOrderSheetSetPage = useCallback(
    (_row: GridRow) => {
      return {
        name: toTitle(t("view")),
        onClick: () => {
          const orderSheetSetRow = _row as GridRow &
            Pick<OrderSheetSetRow, "lineSheetSet">;
          navigateToOrderSheetSetPage(
            orderSheetSetRow.lineSheetSet.id,
            orderSheetSetRow.id
          );
        },
      };
    },
    [navigateToOrderSheetSetPage, t]
  );

  const copyOrderSheet = useCallback(
    (_row: GridRow) => {
      const { orderSheetRow, orderSheetSetId } =
        unsafe_destructureOrderSheetRow(_row);
      const isCopiable = orderSheetRow.status === "MODIFICATION_REQUIRED";
      let segment;
      if (isCopiable) {
        segment = findCopyOrderSheetSegment({
          orderSheetSetId: orderSheetSetId,
          orderSheetId: orderSheetRow.id,
        });
      }
      return {
        name: toTitle(t("copy")),
        isDisabled: !isCopiable,
        isLoading: segment?.isLoading || false,
        onClick: () => {
          if (identity?.company?.type === "AGENCY") {
            fireCopyOrderSheet(
              {
                agencyId: identity.company.id,
                orderSheetSetId: orderSheetSetId,
                _sourceOrderSheetId: orderSheetRow.id,
              },
              {
                "sourceOrderSheet.id": orderSheetRow.id,
              }
            );
          }
        },
      };
    },
    [
      unsafe_destructureOrderSheetRow,
      t,
      findCopyOrderSheetSegment,
      identity,
      fireCopyOrderSheet,
    ]
  );

  // const orderOrderSheetSet = useCallback(
  //   (_row: GridRow) => {
  //     const lineSheetSetOrOrderSheetSetRow =
  //       _row as LineSheetSetOrOrderSheetSetRow;
  //     const orderSheetRows = getDescendantsByType<OrderSheetRow>(
  //       lineSheetSetOrOrderSheetSetRow,
  //       "OrderSheet"
  //     );
  //
  //     const isOrderable = orderSheetRows.some((orderSheetRow) => {
  //       return "WORKING_DRAFT" === orderSheetRow.status;
  //     });
  //
  //     return {
  //       name: toTitle(t("submit")),
  //       isDisabled: !isOrderable,
  //       isMajor: true,
  //       onClick: () => {
  //         disclosureOrderOrderSheetSetModal.openWithData((prev) => {
  //           return {
  //             orderSheetSetId:
  //               lineSheetSetOrOrderSheetSetRow.orderSheetSetId ||
  //               lineSheetSetOrOrderSheetSetRow.id,
  //             onSubmit: (response) => {
  //               mutateRows();
  //             },
  //           };
  //         });
  //       },
  //     };
  //   },
  //   [t, disclosureOrderOrderSheetSetModal, mutateRows]
  // );

  const cancelOrderSheetSet = useCallback(
    (_row: GridRow) => {
      const { lineSheetSetOrOrderSheetSetRow, orderSheetSetId } =
        unsafe_destructureLineSheetSetOrOrderSheetSetRow(_row);
      const orderSheetRow = getDescendantsByType<BuyerOrderSheetRow>(
        lineSheetSetOrOrderSheetSetRow,
        "OrderSheet"
      );

      const isAnyOrderedToAgency = orderSheetRow.some((orderSheetRow) => {
        return isOrderSheetOrderedToAgency(orderSheetRow);
      });

      let segment;

      if (isAnyOrderedToAgency) {
        segment = findUpdateOrderSheetSetStatusSegment(orderSheetSetId);
      }

      const parameter: UpdateOrderSheetSetStatusRequest = {
        status: "WORKING_DRAFT",
      };

      return {
        name: toTitle(t("cancel_submit")),
        isDisabled: !isAnyOrderedToAgency,
        isMajor: true,
        isLoading: segment?.isLoading || false,
        onClick: () => {
          if (identity?.company) {
            fireUpdateOrderSheetSetStatus(
              {
                by: identity.company.type,
                companyId: identity.company.id,
                orderSheetSetId: orderSheetSetId,
              },
              {
                status: "WORKING_DRAFT",
              }
            );
          }
        },
        disabledMessage:
          orderSheetRow.length > 0
            ? t("cancel_order_sheet_set_disabled_message", {
                ns: "order_sheet_sets",
              })
            : "",
      };
    },
    [
      findUpdateOrderSheetSetStatusSegment,
      fireUpdateOrderSheetSetStatus,
      identity,
      t,
      unsafe_destructureLineSheetSetOrOrderSheetSetRow,
    ]
  );

  const viewLineSheet = useCallback(
    (_row: GridRow) => {
      const {
        lineSheetRow,
        lineSheetSetOrOrderSheetSetRow,
        lineSheetSetId,
        orderSheetSetId,
      } = unsafe_destructureLineSheetRow(_row);

      return {
        name: toTitle(t("view")),
        isDisabled: lineSheetRow.isFallback,
        onClick: () => {
          navigateToLineSheetSetPage(lineSheetSetId, lineSheetRow.id);
        },
      };
    },
    [unsafe_destructureLineSheetRow, t, navigateToLineSheetSetPage]
  );

  const writeDraft = useCallback(
    (_row: GridRow) => {
      const {
        lineSheetRow,
        lineSheetSetOrOrderSheetSetRow,
        lineSheetSetId,
        orderSheetSetId,
      } = unsafe_destructureLineSheetRow(_row);

      if (!lineSheetRow.isFallback) {
        const isDisabled = !lineSheetRow.isWritable;

        let parameter: CreateOrderSheetSeamlesslyParameter | undefined;
        let segment;
        if (!isDisabled && identity?.company) {
          parameter = {
            by: identity.company.type,
            companyId: identity.company.id,
            lineSheetSetId,
            lineSheetId: lineSheetRow.id,
            orderSheetSetId,
            index: getDescendantsByType(lineSheetRow, "OrderSheet").length,
          };

          segment = findCreateOrderSheetSegment(parameter);
        }

        return {
          name: toTitle(t("write_draft")),
          isDisabled,
          isLoading: segment?.isLoading || false,
          onClick: () => {
            if (parameter) {
              fireCreateOrderSheetSeamlessly(parameter, {});
            }
          },
          isMajor: true,
        };
      }
    },
    [
      unsafe_destructureLineSheetRow,
      findCreateOrderSheetSegment,
      fireCreateOrderSheetSeamlessly,
      identity,
      t,
    ]
  );

  const writeFallbackDraft = useCallback(
    (_row: GridRow) => {
      const {
        lineSheetRow,
        lineSheetSetId,
        orderSheetSetId,
        lineSheetSetOrOrderSheetSetRow,
      } = unsafe_destructureLineSheetRow(_row);
      if (lineSheetRow.isFallback) {
        const isDisabled = !lineSheetRow.isWritable;

        let parameter: CreateFallbackOrderSheetSeamlesslyParameter | undefined;
        let segment;
        if (!isDisabled && identity?.company) {
          parameter = {
            by: identity.company.type,
            companyId: identity.company.id,
            lineSheetSetId,
            lineSheetId: lineSheetRow.id,
            orderSheetSetId,
            index: getDescendantsByType(lineSheetRow, "OrderSheet").length,
          };

          segment =
            findCreateFallbackOrderSheetSeamlesslyTransaction(parameter);
        }

        return {
          name: toTitle(t("write_fallback_draft")),
          isDisabled,
          isLoading: segment?.isLoading || false,
          onClick: () => {
            if (parameter) {
              disclosureUploadOrderSheetModal.openWithData((prev) => {
                return {
                  ...parameter!,
                  name: lineSheetRow.name,
                  lineSheetSetType: lineSheetSetOrOrderSheetSetRow.type,
                  orderSheetId: -1,
                };
              });
            }
          },
          isMajor: true,
        };
      }
    },
    [
      disclosureUploadOrderSheetModal,
      findCreateFallbackOrderSheetSeamlesslyTransaction,
      identity,
      t,
      unsafe_destructureLineSheetRow,
    ]
  );

  const editOrViewOrderSheet = useCallback(
    (_row: GridRow) => {
      const {
        orderSheetRow,
        lineSheetRow,
        lineSheetSetOrOrderSheetSetRow,
        lineSheetSetId,
        orderSheetSetId,
      } = unsafe_destructureOrderSheetRow(_row);

      const isDraft = ["INITIAL_DRAFT", "WORKING_DRAFT"].includes(
        orderSheetRow.status
      );

      const isEditable = isDraft && identity?.company?.type === "BUYER";

      return {
        name: toTitle(t(isEditable ? "edit" : "view")),
        isDisabled: orderSheetRow.isFallback,
        onClick: () => {
          navigateToOrderSheetSetDraftPage(orderSheetSetId, orderSheetRow.id);
        },
      };
    },
    [
      unsafe_destructureOrderSheetRow,
      navigateToOrderSheetSetDraftPage,
      t,
      identity,
    ]
  );

  const downloadCompressedImportedFile = useCallback(
    (_row: GridRow) => {
      const lineSheetSetRow = _row as AgencyLineSheetSetRow;
      const compressedImportedFile = lineSheetSetRow.compressedImportedFile;
      let segment;
      if (compressedImportedFile) {
        segment = findDownloadUnoFileSegment(compressedImportedFile.id);
      }
      return {
        name: toTitle(t("download")),
        isDisabled: !compressedImportedFile,
        isLoading: segment?.isLoading || false,
        onClick: () => {
          if (compressedImportedFile) {
            fireDownloadUnoFile({ unoFileId: compressedImportedFile.id }, {});
          }
        },
      };
    },
    [findDownloadUnoFileSegment, fireDownloadUnoFile, t]
  );

  const replaceOrUploadUploadedFile = useCallback(
    (_row: GridRow) => {
      const {
        orderSheetRow,
        lineSheetRow,
        lineSheetSetOrOrderSheetSetRow,
        lineSheetSetId,
        orderSheetSetId,
      } = unsafe_destructureOrderSheetRow(_row);

      const isDraft = ["INITIAL_DRAFT", "WORKING_DRAFT"].includes(
        orderSheetRow.status
      );

      if (orderSheetRow.isFallback && isDraft) {
        let segment;
        if (identity?.company?.type === "BUYER") {
          segment = findUpdateFallbackOrderSheetTransaction({
            buyerId: identity.company.id,
            orderSheetSetId:
              lineSheetSetOrOrderSheetSetRow.orderSheetSetId ||
              lineSheetSetOrOrderSheetSetRow.id,
            orderSheetId: orderSheetRow.id,
          });
        }

        return {
          name: toTitle(
            t(orderSheetRow.uploadedFile ? "replace_file" : "upload_file")
          ),
          isLoading: segment?.isLoading || false,
          onClick: () => {
            disclosureUploadOrderSheetModal.openWithData((prev) => {
              return {
                name: lineSheetRow.name,
                lineSheetSetType: lineSheetSetOrOrderSheetSetRow.type,
                lineSheetSetId,
                orderSheetSetId,
                lineSheetId: lineSheetRow.id,
                orderSheetId: orderSheetRow.id,
                index: 0,
              };
            });
          },
        };
      }
    },
    [
      t,
      findUpdateFallbackOrderSheetTransaction,
      identity,
      disclosureUploadOrderSheetModal,
      unsafe_destructureOrderSheetRow,
    ]
  );

  const deleteUploadedFile = useCallback(
    (_row: GridRow) => {
      const { orderSheetRow, orderSheetSetId } =
        unsafe_destructureOrderSheetRow(_row);

      const isDraft = ["INITIAL_DRAFT", "WORKING_DRAFT"].includes(
        orderSheetRow.status
      );

      if (orderSheetRow.isFallback) {
        const isDisabled = !isDraft || !orderSheetRow.uploadedFile;
        let segment;
        if (!isDisabled) {
          segment = findDeleteOrderSheetUploadedFileSegment(
            orderSheetSetId,
            orderSheetRow.id
          );
        }

        return {
          name: toTitle(t("delete_file")),
          isDisabled,
          isLoading: segment?.isLoading || false,
          onClick: () => {
            if (identity?.company) {
              fireDeleteOrderSheetUploadedFile(
                {
                  by: identity.company.type,
                  companyId: identity.company.id,
                  orderSheetSetId,
                  orderSheetId: orderSheetRow.id,
                },
                {}
              );
            }
          },
          isMajor: true,
        };
      }
    },
    [
      unsafe_destructureOrderSheetRow,
      findDeleteOrderSheetUploadedFileSegment,
      fireDeleteOrderSheetUploadedFile,
      identity,
      t,
    ]
  );

  const withDownloadUnoFile = useCallback(
    <T extends GridRow>(
      getter: (row: T) => Pick<StoredObject, "id" | "name"> | null
    ) => {
      return (row: GridRow) => {
        const file = getter(row as T);
        let segment;
        if (file) {
          segment = findDownloadUnoFileSegment(file.id);
        }
        return {
          name: toTitle(t("download")),
          isDisabled: !file,
          isLoading: segment?.isLoading || false,
          onClick: () => {
            if (file) {
              fireDownloadUnoFile({ unoFileId: file.id }, {});
            }
          },
        };
      };
    },
    [findDownloadUnoFileSegment, fireDownloadUnoFile, t]
  );

  const orderSheetExtraContents = useCallback(
    (_row: GridRow) => {
      const { orderSheetRow } = unsafe_destructureOrderSheetRow(_row);
      const uploadedFile = orderSheetRow.uploadedFile;
      if (uploadedFile) {
        const segment = findDownloadUnoFileSegment(uploadedFile.id);
        return [
          <AppGridRowAttachmentExtraContent
            key={"UploadedFile"}
            name={uploadedFile.name}
            isLoading={segment?.isLoading || false}
            onClick={() => {
              fireDownloadUnoFile(
                {
                  unoFileId: uploadedFile.id,
                },
                {}
              );
            }}
          />,
        ];
      }
      return [];
    },
    [
      unsafe_destructureOrderSheetRow,
      findDownloadUnoFileSegment,
      fireDownloadUnoFile,
    ]
  );

  //fields

  const lineSheetSetType = useMemo(() => {
    return {
      name: "type",
      value: (_row: GridRow) => {
        const row = _row as GridRow & { type: LineSheetSetType };
        return toUpper(t(toLower(row.type)));
      },
    };
  }, [t]);

  const lineSheetType = useMemo(() => {
    return {
      name: "type",
      value: (_row: GridRow) => {
        const row = _row as GridRow & { type: LineSheetType };
        return toUpper(t(toLower(row.type)));
      },
    };
  }, [t]);

  const lineSheetStatus = useMemo(() => {
    return {
      name: "status",
      value: (_row: GridRow, context: AppGridContext) => {
        const row = _row as GridRow & { status: string };
        return (
          <AppStatusLabel
            i18nPrefix={"line_sheet.status"}
            keyOrFunc={"status"}
            row={row}
            context={context}
          />
        );
      },
    };
  }, []);

  const lineSheetSetStatus = useMemo(() => {
    return {
      name: "status",
      value: (_row: GridRow, context: AppGridContext) => {
        const row = _row as GridRow & { status: string };
        return (
          <AppStatusLabel
            i18nPrefix={"line_sheet_set.status"}
            keyOrFunc={"status"}
            row={row}
            context={context}
          />
        );
      },
    };
  }, []);

  const withOrderSheetStatus = useCallback((isBuyer: boolean = false) => {
    return {
      name: "status",
      value: (_row: GridRow, context: AppGridContext) => {
        const row = _row as BuyerOrderSheetSetRow;
        return <AppOrderSheetStatusLabel value={row.status} />;
      },
    };
  }, []);

  const withRowType = useCallback(
    (rowType: string) => {
      return {
        name: "type",
        value: (_row: GridRow) => {
          return toTitle(t(toLower(rowType)));
        },
      };
    },
    [t]
  );

  const storedObjectField = useMemo(() => {
    return {
      name: "type",
      value: (_row: GridRow) => {
        const row = _row as GridRow & { name: string };
        const i = row.name.lastIndexOf(".");
        let suffix = "";
        if (i > -1) {
          suffix = "/" + row.name.substring(i + 1);
        }
        return `${toTitle(t("attachment"))}${suffix}`;
      },
    };
  }, [t]);

  //pairs

  type GridKey =
    | "season"
    | "title"
    | "orderedAmount"
    | "status"
    | "lineSheetSetType"
    | "type"
    | "tsi"
    | "oc"
    | "issuedByBrand"
    | "issuedByBoutique"
    | "uploadedOn"
    | "publishedOn"
    | "validUntil"
    | "compressionStatus"
    | "reviewStatus";

  const pairs = useMemo((): {
    [key in GridKey]: {
      column: AppGridColumn;
      field: AppGridField;
    };
  } => {
    return {
      season: {
        column: {
          name: toTitle(t("season")),
          width: "64px",
        },
        field: {
          name: "season",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { season: string };
            return row.season;
          },
        },
      },
      title: {
        column: {
          name: toTitle(t("title")),
          width: "1fr",
        },
        field: {
          name: "title",
          value: (_row: GridRow) => {
            const { id, name } = _row as GridRow & { name: string };
            if (identity?.company?.type === "AGENCY") {
              return `#${id} ${name}`;
            } else {
              return name;
            }
          },
        },
      },
      orderedAmount: {
        column: {
          name: toTitle(t("ordered_amount.abbr")),
          width: "104px",
          textAlign: "end",
        },
        field: {
          name: "ordered_amount",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              orderedAmountWithoutCondition: number;
              orderedConditionType: ConditionType | null;
              orderedAmount: number;
            };

            return (
              <Flex
                flexDirection={"column"}
                fontSize={"12px"}
                alignItems={"flex-end"}
              >
                <Text fontWeight={"700"}>
                  {tCurrencyString(row.orderedAmountWithoutCondition)}
                </Text>
                <Text>
                  {getConditionTypeSymbol(row.orderedConditionType)}
                  {tCurrencyString(row.orderedAmount)})
                </Text>
              </Flex>
            );
          },
        },
      },
      status: {
        column: {
          name: toTitle(t("status")),
          width: "104px",
        },
        field: {
          name: "status",
          value: (_row: GridRow, context: AppGridContext) => {
            const row = _row as GridRow & {
              status: OrderSheetStatus | LineSheetSetStatus;
            };

            if (isOrderSheetStatus(row.status)) {
              return <AppOrderSheetStatusLabel value={row.status} />;
            } else if (isLineSheetSetStatus(row.status)) {
              return <AppLineSheetSetStatusLabel value={row.status} />;
            }

            return "";
          },
        },
      },
      type: {
        column: {
          name: toTitle(t("type")),
          width: "104px",
        },
        field: {
          name: "type",
          value: (_row: GridRow) => {
            const row = _row as GridRow;
            return toTitle(t(toLower(toSnake(row._rowType)).substring(1)));
          },
        },
      },
      lineSheetSetType: {
        column: {
          name: toTitle(t("type")),
          width: "104px",
        },
        field: {
          name: "lineSheetSetType",
          value: (_row: GridRow) => {
            const row = _row as GridRow & { type: LineSheetSetType };
            return toUpper(t(`line_sheet_set.type.${toLower(row.type)}`));
          },
        },
      },
      tsi: {
        column: {
          name: "tsi",
          width: "200px",
          value: (
            <AppGridCell
              value={[
                `${toTitle(t("type"))}/${toTitle(t("season"))}`,
                `${toTitle(t("issued_by"))}`,
              ]}
            />
          ),
        },
        field: {
          name: "tsi",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              season: string;
              issuedByBoutique?: OrderSheetSetRow["issuedByBoutique"];
            };
            return (
              <AppGridCell
                value={[
                  `${toTitle(t(toLower(toSnake(row._rowType)).substring(1)))}/${
                    row.season
                  }`,
                  row.issuedByBoutique?.name || "",
                ]}
              />
            );
          },
        },
      },
      oc: {
        column: {
          name: "oc",
          width: "200px",
          value: (
            <AppGridCell
              alignItems={"flex-end"}
              value={[`${toTitle(t("ordered_amount.abbr"))}`]}
            />
          ),
        },
        field: {
          name: "oc",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              orderedAmountWithoutCondition: number;
              orderedConditionType: ConditionType | null;
              orderedAmount: number;
              confirmedAmount: number;
            };

            return (
              <AppGridCell
                alignItems={"flex-end"}
                value={[
                  <Text key={"orderedAmount"}>
                    {getConditionTypeSymbol(row.orderedConditionType)}
                    {tCurrencyString(row.orderedAmount)}
                  </Text>,
                ]}
              />
            );
          },
        },
      },
      issuedByBrand: {
        column: {
          name: toTitle(t("brand")),
          width: "104px",
        },
        field: {
          name: "brand",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              issuedByBrand?: { id: number; name: string };
            };
            return row.issuedByBrand?.name || "";
          },
        },
      },
      issuedByBoutique: {
        column: {
          name: toTitle(t("issued_by")),
          width: "104px",
        },
        field: {
          name: "issuedBy",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              issuedByBoutique?: { id: number; name: string };
            };
            return row.issuedByBoutique?.name || "";
          },
        },
      },
      uploadedOn: appGrid.calcLocalDatePair("uploadedOn", "createdAt"),
      publishedOn: appGrid.calcLocalDatePair("publishedOn", "publishedAt"),
      validUntil: appGrid.calcLocalDateTime2Pair("validUntil"),
      compressionStatus: {
        column: {
          name: toTitle(t("compression_status")),
          width: "104px",
        },
        field: {
          name: "compressionStatus",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              compressionStatus: LineSheetSet["compressionStatus"];
            };
            return (
              <AppLineSheetSetCompressionStatusLabel
                value={row.compressionStatus}
              />
            );
          },
        },
      },
      reviewStatus: {
        column: {
          name: toTitle(t("review_status")),
          width: "104px",
        },
        field: {
          name: "reviewStatus",
          value: (_row: GridRow) => {
            const row = _row as GridRow & {
              reviewStatus: LineSheetSet["reviewStatus"];
            };
            return <AppReviewStatusLabel value={row.reviewStatus} />;
          },
        },
      },
    };
  }, [appGrid, t, tCurrencyString, identity]);

  const fields = useMemo(() => {
    return {
      lineSheetSetType,
      lineSheetType,
      withOrderSheetStatus,
      withRowType,
      storedObjectField,
      lineSheetStatus,
      lineSheetSetStatus,
    };
  }, [
    lineSheetSetType,
    lineSheetType,
    withOrderSheetStatus,
    withRowType,
    storedObjectField,
    lineSheetStatus,
    lineSheetSetStatus,
  ]);

  const actions = useMemo(() => {
    return {
      reportLineSheetSet,
      reparseLineSheetSet,
      deleteLineSheetSet,
      restoreLineSheetSet,
      downloadCompressedImportedFile,
      replaceOrUploadUploadedFile,
      deleteUploadedFile,
      editOrViewOrderSheet,
      withDownloadUnoFile,
      writeDraft,
      writeFallbackDraft,
      viewLineSheet,
      // orderOrderSheetSet,
      cancelOrderSheetSet,
      copyOrderSheet,
      pendLineSheetSetReviewStatus,
      approveLineSheetSetReviewStatus,
      rejectLineSheetSetReviewStatus,
      goToOrderSheetSetPage,
      publishLineSheetSet,
    };
  }, [
    reportLineSheetSet,
    reparseLineSheetSet,
    deleteLineSheetSet,
    restoreLineSheetSet,
    downloadCompressedImportedFile,
    replaceOrUploadUploadedFile,
    deleteUploadedFile,
    editOrViewOrderSheet,
    withDownloadUnoFile,
    writeDraft,
    writeFallbackDraft,
    viewLineSheet,
    // orderOrderSheetSet,
    cancelOrderSheetSet,
    copyOrderSheet,
    pendLineSheetSetReviewStatus,
    approveLineSheetSetReviewStatus,
    rejectLineSheetSetReviewStatus,
    goToOrderSheetSetPage,
    publishLineSheetSet,
  ]);

  const extraContents = useMemo(() => {
    return {
      orderSheetExtraContents,
    };
  }, [orderSheetExtraContents]);

  return {
    disclosureUploadOrderSheetModal,
    disclosureOrderOrderSheetSetModal,
    fields,
    actions,
    extraContents,
    pairs,
  };
}
