import {
  CellMouseOutEvent,
  CellMouseOverEvent,
  CellStyle,
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import {
  FlatProduct,
  flatTabsFamily,
  lineSheetSetFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import ProductImageCell, {
  PRODUCT_IMAGE_SIZE,
} from "@/features/order-sheet-sets/product-image-cell";
import PriceAdjustedByCell from "@/features/order-sheet-sets/price-adjusted-by-cell";
import NumericEditor from "@/features/ui/numeric-editor";
import { produce } from "immer";
import { times, uniq } from "lodash";
import { Box } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import ProductCheckboxCell from "@/features/order-sheet-sets/product-checkbox-cell";
import SheetHeader from "@/features/order-sheet-sets/sheet-header";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import SheetCheckBoxHeader from "@/features/order-sheet-sets/sheet-checkbox-header";
import ProductImageHeader from "@/features/order-sheet-sets/product-image-header";
import { MAX_PRODUCT_IMAGE_COUNT } from "@/features/order-sheet-sets/representative-product-image";
import OverlappedConditionTypeCell from "../ui/overlapped-condition-type-cell";
import OverlappedValueCell from "../ui/overlapped-value-cell";
import TakeAllProductsCell from "../ui/take-all-products-cell";

interface LineSheetTabProps {
  lineSheetSetId: number;
  lineSheetId: number;
  tabIndex: number;
  isLoading: boolean;
  onSelectedProductIdsChange: (ids: number[]) => void;
  onGridReady: (event: GridReadyEvent<FlatProduct>) => void;
}

function getRowId(params: GetRowIdParams<FlatProduct>) {
  return params.data.rowId;
}

function LineSheetTab({
  lineSheetSetId,
  lineSheetId,
  tabIndex,
  isLoading,
  onSelectedProductIdsChange,
  onGridReady,
}: LineSheetTabProps) {
  const lineSheetSetKey = useMemo(() => {
    return {
      lineSheetSetId,
    };
  }, [lineSheetSetId]);
  const lineSheetSetState = useRecoilValue(lineSheetSetFamily(lineSheetSetKey));
  const sheetKey = useMemo(() => {
    return {
      lineSheetSetId,
      lineSheetId,
    };
  }, [lineSheetSetId, lineSheetId]);

  const flatSheetState = useRecoilValue(flatTabsFamily(sheetKey));

  const rowData = useMemo(() => {
    if (flatSheetState && tabIndex > -1) {
      return flatSheetState.tabs
        .find((tab) => tab.index === tabIndex)
        ?.products.filter((product) => product.isFiltered);
    }
  }, [flatSheetState, tabIndex]);

  const { t, tTitle, tCurrencyString } = useI18nHelper();
  const gridRef = useRef<AgGridReact<FlatProduct> | null>(null);
  const [isGridRefSet, setIsGridRefSet] = useState(false);

  const handleSetGridRef = useCallback(
    (ref: AgGridReact<FlatProduct> | null) => {
      gridRef.current = ref;
      if (ref && !isGridRefSet) {
        setIsGridRefSet(true);
      }
    },
    [isGridRefSet, setIsGridRefSet]
  );

  const [isImagesCollapsed, setIsImagesCollapsed] = useState<boolean>(true);

  const components = useMemo<{ [p: string]: any }>(() => {
    return {
      agColumnHeader: SheetHeader,
    };
  }, []);

  const [colDefs, setColDefs] = useState<ColDef<FlatProduct>[]>([
    {
      cellRenderer: ProductCheckboxCell,
      rowSpan: (params) => {
        if (params.node && params.node.rowIndex !== null) {
          return params.node.rowIndex % 3 === 0 ? 3 : 1;
        }
        return 1;
      },
      width: 40,
      pinned: "left",
      field: "isChecked",
      headerName: "",
      resizable: false,
      suppressMovable: true,
      headerCheckboxSelection: false,
      showDisabledCheckboxes: false,
      headerComponent: SheetCheckBoxHeader,
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 === 0,
        "product-checkbox-cell": (params) => params.rowIndex % 3 === 0,
      },
      headerClass: "product-checkbox-header",
    },
    {
      valueGetter: (params) => {
        if (
          params.data &&
          params.data.imageUrls != null &&
          params.data.imageUrls.length > 0
        ) {
          return params.data.imageUrls;
        }
        return null;
      },
      cellStyle: { fontSize: "12px" },
      cellRenderer: ProductImageCell,
      width: PRODUCT_IMAGE_SIZE,
      autoHeight: false,
      rowSpan: (params) => {
        if (params.node && params.node.rowIndex !== null) {
          return params.node.rowIndex % 3 === 0 ? 3 : 1;
        }
        return 1;
      },
      resizable: false,
      pinned: "left",
      field: "i",
      // headerName: "",
      headerClass: "product-image-header",
      headerComponent: ProductImageHeader,
      headerComponentParams: {
        isCollapsed: isImagesCollapsed,
        onClick: (isCollapse: boolean) => {
          setIsImagesCollapsed(isCollapse);
        },
      },
      cellRendererParams: {
        isCollapsed: isImagesCollapsed,
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 === 0,
      },
    },
    {
      field: "brandModelNumberModelName",
      autoHeaderHeight: true,
      width: 120,
      resizable: true,
      pinned: "left",
      cellStyle: { fontSize: "12px" },
      headerComponentParams: {
        items: ["brand", "sku", "model_name"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
      },
    },
    {
      field: "genderCategorySubcategory",
      width: 120,
      resizable: true,
      pinned: "left",
      cellStyle: { fontSize: "12px" },
      headerComponentParams: {
        items: ["gender", "category", "subcategory"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
      },
    },
    {
      field: "colorCodeColorNameMaterial",
      width: 120,
      resizable: true,
      pinned: "left",
      cellStyle: { fontSize: "12px" },
      headerComponentParams: {
        items: ["color_code", "color_name", "material"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
      },
    },
    {
      field: "priceAmount",
      cellStyle: (params): CellStyle => {
        const cellStyle: CellStyle = { textAlign: "end", fontSize: "12px" };
        const rowIndex = params.node?.rowIndex;
        if (typeof rowIndex === "number" && rowIndex % 3 === 0) {
          cellStyle.color = "#8F8F8C";
        }
        return cellStyle;
      },
      width: 96,
      resizable: true,
      valueFormatter: (params) => {
        if (params.data?.priceCurrency) {
          // 개별 가격 정보에 통화 정보가 있으면 해당 통화로 표시
          return tCurrencyString(params.value, {
            type: "SYMBOL",
            code: params.data.priceCurrency,
          });
        } else if (lineSheetSetState?.lineSheetSet?.currency) {
          // 개별 가격 정보가 없지만 라인시트 세트에 통화 정보가 있으면 해당 통화로 표시
          return tCurrencyString(params.value, {
            type: "SYMBOL",
            code: lineSheetSetState?.lineSheetSet?.currency,
          });
        } else {
          // 통화 정보가 없으면 기본값 사용
          return tCurrencyString(params.value);
        }
      },
      pinned: "left",
      headerComponentParams: {
        items: ["price", "cost", "retail"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
        "product-price-cell": (params) => params.rowIndex % 3 === 0,
      },
    },
    {
      field: "priceAdjustedByPartial",
      headerName: "",
      pinned: "left",
      width: 96,
      resizable: false,
      colId: "priceAdjustedByPartial",
      cellRenderer: PriceAdjustedByCell,
      cellStyle: {
        display: "flex",
        overflow: "visible",
        padding: "1px",
      },
      editable: false,
      valueGetter: (params) => {
        //cell re render가 되려면 이 값이 변경되어함,
        //custom cell 에서 value로 접근 가능
        if (params.node && params.node.rowIndex !== null) {
          const rowIndex = params.node.rowIndex;
          const partialIndex = rowIndex % 3;
          if (partialIndex === 0) {
            // const nextRowData = {
            //   by: params.data?.priceAdjustedByPartial,
            //   value: params.api.getDisplayedRowAtIndex(rowIndex + 1)?.data
            //     ?.priceAdjustedByPartial,
            // };
            return params.data?.priceAdjustedByPartial;
          } else if (partialIndex === 1) {
            return params.data?.priceAdjustedByPartial;
            // return {
            //   by: params.api.getDisplayedRowAtIndex(rowIndex - 1)?.data
            //     ?.priceAdjustedByPartial,
            //   value: params.data?.priceAdjustedByPartial,
            // };
          } else {
            return params.data?.sizes;
          }
        }
        return params.data?.priceAdjustedByPartial;
      },
      cellRendererSelector: (params) => {
        const rowIndex = params.node.rowIndex;
        if (rowIndex != null) {
          const index = rowIndex % 3;
          if (index === 0) {
            return {
              component: OverlappedConditionTypeCell,
            };
          } else if (index === 1) {
            return {
              component: OverlappedValueCell,
              params: {
                unit: params.data?.priceAdjustedByPartial ? "%" : "-",
              },
            };
          } else if (index === 2) {
            return {
              component: TakeAllProductsCell,
            };
          }
        }

        return undefined;
      },
      cellEditor: forwardRef(NumericEditor),
      cellEditorParams: {
        unit: "%",
        tabIndex,
        sheetKey,
        isEditable: false,
      },
      cellClass: "product-price-adjusted-cell",
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
        "product-price-adjusted-selectbox-cell": (params) =>
          params.rowIndex % 3 === 0,
        "product-price-adjusted-input-cell": (params) =>
          params.rowIndex % 3 === 1,
      },
    },
  ]);

  const maxProductImageCount = useMemo(() => {
    return (
      rowData
        ?.map((i) =>
          Math.min(MAX_PRODUCT_IMAGE_COUNT, (i.imageUrls || []).length)
        )
        .reduce((acc, cur) => Math.max(acc, cur), 0) || 0
    );
  }, [rowData]);

  useEffect(() => {
    // gridRef.current?.api.refreshHeader();
    setColDefs((prev) => {
      return produce(prev, (draft) => {
        const i = draft.findIndex((colDef) => colDef.field === "i");

        let width;
        if (isImagesCollapsed) {
          width = PRODUCT_IMAGE_SIZE;
        } else {
          width =
            PRODUCT_IMAGE_SIZE * maxProductImageCount +
            (maxProductImageCount - 1) * 4 +
            2;
        }

        if (i > -1) {
          draft[i] = {
            ...draft[i],
            width,
            headerComponentParams: {
              ...draft[i].headerComponentParams,
              isCollapsed: isImagesCollapsed,
              isDisabled:
                maxProductImageCount === 0 || maxProductImageCount === 1,
            },
            cellRendererParams: {
              ...draft[i].cellRendererParams,
              isCollapsed: isImagesCollapsed,
            },
          };
        }
      });
    });
  }, [isImagesCollapsed, maxProductImageCount]);

  useEffect(() => {
    setColDefs((prev) => {
      return produce(prev, (draft) => {
        const i = draft.findIndex((c) => c.field === "priceAdjustedByPartial");
        if (i > -1) {
          draft[i].cellRendererParams = {
            tabIndex,
            sheetKey,
            isEditable: false,
          };
        }
      });
    });
  }, [tabIndex, sheetKey]);

  const sizeLength = useMemo(() => {
    if (rowData) {
      return rowData
        .map((i) => {
          return i.sizes ? i.sizes.length : 0;
        })
        .reduce((acc, cur) => {
          return Math.max(acc, cur);
        }, 0);
    }
    return 0;
  }, [rowData]);

  useEffect(() => {
    if (sizeLength > 0) {
      setColDefs((prev) => {
        const i = prev.findLastIndex(
          (p) => p.field === "priceAdjustedByPartial"
        );
        if (i > -1) {
          return [
            ...prev.slice(0, i + 1),
            ...((): ColDef<FlatProduct>[] => {
              return times(sizeLength, (index): ColDef<FlatProduct> => {
                return {
                  headerName: `sq${index}`,
                  headerClass: "product-size-or-quantity-cell-header",
                  colId: `sq_${index}`,
                  width: 80,
                  cellStyle: (params) => {
                    return {
                      textAlign: "center",
                      fontSize: "12px",
                    };
                  },
                  valueGetter: (params) => params.data?.sizes?.[index],
                  valueFormatter: (params) => {
                    if (params.value === -1) {
                      return "∞";
                    }
                    return params.value === 0 ? "" : params.value;
                  },
                  valueSetter: (params) => {
                    return true;
                  },
                  cellClassRules: {
                    "product-odd-row": (params) => params.rowIndex % 6 < 3,
                    "product-each-last-row": (params) =>
                      params.rowIndex % 3 === 2,
                  },
                  editable: false,
                  resizable: true,
                  headerComponentParams: {
                    items: ["size", "stock", "quantity.abbr"],
                  },
                };
              });
            })(),
          ];
        }
        return prev;
      });
    }
  }, [tabIndex, sizeLength]);

  const defaultColDef = useMemo((): ColDef<FlatProduct> => {
    return {
      autoHeight: false,
      cellStyle: { fontSize: "12px", padding: "0" },
    };
  }, []);

  const handleGridReady = useCallback(
    (event: GridReadyEvent<FlatProduct>) => {
      onGridReady(event);
    },
    [onGridReady]
  );

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent<FlatProduct>) => {
      onSelectedProductIdsChange(
        uniq(event.api.getSelectedRows().map((row) => row.id))
      );
      event.api.refreshCells({
        force: true,
        columns: ["isChecked"],
      });
    },
    [onSelectedProductIdsChange]
  );

  const handleMouseOver = useCallback(
    (event: CellMouseOverEvent<FlatProduct>) => {
      const rowIndex = event.node.rowIndex;
      if (rowIndex !== null && rowIndex % 3 === 0) {
        if (
          event.colDef.headerClass &&
          (event.colDef.headerClass
            .toString()
            .includes("product-checkbox-header") ||
            event.colDef.headerClass
              .toString()
              .includes("product-image-header"))
        ) {
          const agPinnedLeftColsElement = document.querySelector(
            ".ag-pinned-left-cols-container"
          );
          if (agPinnedLeftColsElement !== null) {
            const secondRowElement = agPinnedLeftColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.add("ag-row-hover");
            thirdRowElement?.classList.add("ag-row-hover");
          }
          const agCenterColsElement = document.querySelector(
            ".ag-center-cols-container"
          );
          if (agCenterColsElement !== null) {
            const secondRowElement = agCenterColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.add("ag-row-hover");
            thirdRowElement?.classList.add("ag-row-hover");
          }
        }
      }
    },
    []
  );

  const handleMouseOut = useCallback(
    (event: CellMouseOutEvent<FlatProduct>) => {
      const rowIndex = event.node.rowIndex;
      if (rowIndex !== null && rowIndex % 3 === 0) {
        if (
          event.colDef.headerClass &&
          (event.colDef.headerClass
            .toString()
            .includes("product-checkbox-header") ||
            event.colDef.headerClass
              .toString()
              .includes("product-image-header"))
        ) {
          const agPinnedLeftColsElement = document.querySelector(
            ".ag-pinned-left-cols-container"
          );
          if (agPinnedLeftColsElement !== null) {
            const secondRowElement = agPinnedLeftColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.remove("ag-row-hover");
            thirdRowElement?.classList.remove("ag-row-hover");
          }
          const agCenterColsElement = document.querySelector(
            ".ag-center-cols-container"
          );
          if (agCenterColsElement !== null) {
            const secondRowElement = agCenterColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.remove("ag-row-hover");
            thirdRowElement?.classList.remove("ag-row-hover");
          }
        }
      }
    },
    []
  );

  return (
    <Box
      className={"ag-theme-alpine"}
      paddingBottom={"16px"}
      height={"100%"}
      width={"100%"}
    >
      <AgGridReact<FlatProduct>
        ref={handleSetGridRef}
        suppressRowTransform={true}
        readOnlyEdit={true}
        getRowId={getRowId}
        headerHeight={24}
        columnDefs={colDefs}
        rowData={rowData}
        //왜 25를 해야 24가 되는거야?;;
        rowHeight={25}
        rowModelType={"clientSide"}
        defaultColDef={defaultColDef}
        onGridReady={handleGridReady}
        rowSelection={"multiple"}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        onCellMouseOver={handleMouseOver}
        onCellMouseOut={handleMouseOut}
        enableCellTextSelection={true}
        components={components}
        suppressRowHoverHighlight={false}
        columnHoverHighlight={true}
      />
    </Box>
  );
}

export default LineSheetTab;
