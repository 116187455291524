import useIdentity from "@/features/ui/hooks/use-identity";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import AppGrid, {
  AppGridColumn,
  AppGridField,
  AppGridMeta,
} from "@/features/order-sheet-sets/app-grid";
import LineSheetFileTypeGridHeaderCell from "@/features/line-sheet-sets/detail/line-sheet-file-type-grid-header-cell";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import {
  AttachmentRow,
  IsolatedLineSheetRow,
  IsolatedOrderSheetRow,
} from "@/features/line-sheet-sets/detail/isolated-sheet.type";
import {
  isAttachmentRow,
  isLineSheetRow,
  isOrderSheetRow,
  LineSheetableRow,
} from "@/features/line-sheet-sets/detail/utils/line-sheetable-mapper";
import LineSheetFileTypeSelect from "@/features/line-sheet-sets/line-sheet-file-type-select";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { toLower } from "@/utils/case";
import { isFallbackLineSheet } from "@/features/line-sheet-sets/utils/line-sheet-set-utils";
import AppLabel from "@/features/ui/app-label";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import { GridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import { AppLineCardAction } from "@/features/line-sheet-sets/app-flat-card";
import {
  ConditionType,
  LightOrderSheetSet,
  OrderSheet,
} from "@/features/order-sheets/order-sheet.type";
import useLineSheetableGrid from "@/features/line-sheet-sets/detail/hooks/use-line-sheetable-grid";
import LineSheetReviewStatusGridHeaderCell from "@/features/line-sheet-sets/detail/line-sheet-review-status-grid-header-cell";
import AppReviewStatusSelect from "@/features/ui/app-review-status-select";
import AppReviewStatusLabel from "@/features/ui/app-review-status-label";
import { getFileExtension, isExcel } from "@/features/types";
import { NegativeSequencer } from "@/features/ui/utils/negative-sequencer";
import { LineSheetableRowConverter } from "@/features/line-sheet-sets/detail/utils/line-sheetable-row-converter";
import AppOrderSheetStatusLabel from "@/features/ui/app-order-sheet-status-label";
import { useFormikContext } from "formik";
import { LineSheetSetFormFields } from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";

interface LineSheetGridMetaParameter {
  isEditing?: boolean;
  rows?: LineSheetableRow[];
  setRows: (
    valueOrUpdater:
      | LineSheetableRow[]
      | ((
          prev: LineSheetableRow[] | undefined
        ) => LineSheetableRow[] | undefined)
  ) => void;
  isRefreshing: boolean;
  orderSheetSet?: LightOrderSheetSet;
  fileInputRef: React.RefObject<HTMLInputElement>;
  sequencer: NegativeSequencer;
  lineSheetableGrid: ReturnType<typeof useLineSheetableGrid>;
}

export default function LineSheetableGrid({
  isEditing = false,
  rows,
  setRows,
  isRefreshing,
  orderSheetSet,
  fileInputRef,
  sequencer,
  lineSheetableGrid,
}: LineSheetGridMetaParameter) {
  const { values } = useFormikContext<LineSheetSetFormFields>();
  const fileTargetRef = useRef<IsolatedLineSheetRow | AttachmentRow>();

  const { tTitle, tUpper, tCurrencyString, tConditionAppliedCurrencyString } =
    useI18nHelper();
  const identity = useIdentity();

  const rowConverter = useMemo(() => {
    return new LineSheetableRowConverter(sequencer);
  }, [sequencer]);

  const openFileInput = useCallback(
    (row: GridRow) => {
      fileTargetRef.current = row as IsolatedLineSheetRow | AttachmentRow;
      fileInputRef.current?.click();
    },
    [fileInputRef]
  );

  const handleFileChange = useCallback(
    (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0 && fileTargetRef.current) {
        const file = target.files[0];

        setRows((prev) => {
          if (prev) {
            return prev.map((row) => {
              if (row.id === fileTargetRef.current?.id) {
                return rowConverter.convert(fileTargetRef.current, {
                  file,
                });
              }
              return row;
            });
          }
        });
      }
    },
    [rowConverter, setRows]
  );

  useEffect(() => {
    const fileInputElement = fileInputRef.current;

    if (fileInputElement) {
      fileInputRef.current.addEventListener("change", handleFileChange);
    }

    return () => {
      if (fileInputElement) {
        fileInputElement.removeEventListener("change", handleFileChange);
      }
    };
  }, [fileInputRef, handleFileChange]);

  const withEditOnlyGridAction = useCallback(
    (actions: ((row: GridRow) => AppLineCardAction | undefined)[]) => {
      if (isEditing) {
        return actions;
      }
      return [];
    },
    [isEditing]
  );

  const withReadOnlyGridActions = useCallback(
    (actions: ((row: GridRow) => AppLineCardAction | undefined)[]) => {
      if (!isEditing) {
        return actions;
      }
      return [];
    },
    [isEditing]
  );

  const indicator = useMemo((): AppGridMeta["indicator"] => {
    // eslint-disable-next-line react/display-name
    return (row) => {
      const lineSheetRow = row as IsolatedLineSheetRow;
      if (!isEditing) {
        if (lineSheetRow.status) {
          if (identity?.company?.type === "BOUTIQUE") {
            return (
              <GridRowIndicator type={"DOWNLOAD"} isHovered={row.isHovered} />
            );
          } else {
            if (isFallbackLineSheet({ status: lineSheetRow.status })) {
              return (
                <GridRowIndicator type={"DOWNLOAD"} isHovered={row.isHovered} />
              );
            } else {
              return (
                <GridRowIndicator type={"VIEW"} isHovered={row.isHovered} />
              );
            }
          }
        }
      }
      return <GridRowIndicator type={"EMPTY"} isHovered={row.isHovered} />;
    };
  }, [identity?.company?.type, isEditing]);

  type LineSheetGridKey =
    | "line_sheet_set.type"
    | "title"
    | "status"
    | "review_status";

  const lineSheetGridPairs = useMemo((): {
    [key in LineSheetGridKey]: { column: AppGridColumn; field: AppGridField };
  } => {
    return {
      "line_sheet_set.type": {
        column: {
          name: tTitle("line_sheet_set.type"),
          width: "128px",
          value: (
            <LineSheetFileTypeGridHeaderCell
              rows={rows}
              isEditing={isEditing}
              onSelect={(type) => {
                setRows((prev) => {
                  return prev?.map((row) => {
                    if (row.isChecked) {
                      if (isLineSheetRow(row)) {
                        return rowConverter.convert(row, { type });
                      } else if (isAttachmentRow(row)) {
                        if (
                          (type === "LINE_SHEET" || type === "ORDER_FORM") &&
                          !isExcel(
                            row.file || {
                              id: row.id,
                              name: row.name,
                            }
                          )
                        ) {
                          return row;
                        } else {
                          return rowConverter.convert(row, {
                            type,
                          });
                        }
                      }
                    }
                    return row;
                  });
                });
              }}
            />
          ),
        },
        field: {
          name: "line_sheet_set.type",
          value: (row) => {
            const lineSheetRow = row as IsolatedLineSheetRow;
            if (isEditing) {
              return (
                <LineSheetFileTypeSelect
                  value={lineSheetRow.type}
                  isExcel={true}
                  onSelect={(lineSheetType) => {
                    setRows((prev) => {
                      return prev?.map((row) => {
                        if (row.id === lineSheetRow.id) {
                          return rowConverter.convert(row, {
                            type: lineSheetType,
                          });
                        }
                        return row;
                      });
                    });
                  }}
                />
              );
            } else {
              switch (identity?.company?.type) {
                case "AGENCY":
                  return <Text>{tUpper(toLower(lineSheetRow.type))}</Text>;
                default:
                  let key: string = "line_sheet";
                  if (isFallbackLineSheet({ status: lineSheetRow.status! })) {
                    key = "order_form";
                  }
                  return <Text>{tUpper(toLower(key))}</Text>;
              }
            }
          },
        },
      },
      title: {
        column: {
          name: tTitle("title"),
          width: "minmax(224px, 1fr)",
        },
        field: {
          name: "title",
          value: (row) => {
            const { id, name } = row as IsolatedLineSheetRow;
            if (identity?.company?.type === "AGENCY") {
              return `#${id} ${name}`;
            } else {
              return name;
            }
          },
        },
      },
      status: {
        column: {
          name: tTitle("status"),
          width: "160px",
        },
        field: {
          name: "status",
          value: (row) => {
            const lineSheetRow = row as IsolatedLineSheetRow;

            if (identity?.company?.type === "AGENCY") {
              if (lineSheetRow.status) {
                if (lineSheetRow.status === "PARSING_PENDING" && isRefreshing) {
                  return <Spinner />;
                }
                return (
                  <AppLabel
                    nameKey={`line_sheet.status.${toLower(
                      lineSheetRow.status
                    )}`}
                    level={(() => {
                      const status = lineSheetRow.status;
                      if (status === "PARSING_FAILED") {
                        return "ERROR";
                      } else if (status === "PARSED") {
                        return "SUCCESS";
                      }
                      return "LIGHT";
                    })()}
                  />
                );
              }
            }

            return "";
          },
        },
      },
      review_status: {
        column: {
          name: tTitle("review_status"),
          width: "128px",
          value: (
            <LineSheetReviewStatusGridHeaderCell
              rows={rows}
              isEditing={isEditing}
              onChange={(reviewStatus) => {
                setRows((prev) => {
                  return prev?.map((row) => {
                    if (row.isChecked) {
                      if (isLineSheetRow(row)) {
                        return {
                          ...row,
                          reviewStatus,
                        };
                      }
                    }

                    return row;
                  });
                });
              }}
            />
          ),
        },
        field: {
          name: "review_status",
          value: (row) => {
            const lineSheetRow = row as IsolatedLineSheetRow;
            if (isEditing && lineSheetRow.type === "LINE_SHEET") {
              return (
                <AppReviewStatusSelect
                  value={lineSheetRow.reviewStatus}
                  onSelect={(reviewStatus) => {
                    setRows((prev) => {
                      return prev?.map((row) => {
                        if (row.id === lineSheetRow.id) {
                          return {
                            ...row,
                            reviewStatus,
                          };
                        }
                        return row;
                      });
                    });
                  }}
                />
              );
            } else if (lineSheetRow.reviewStatus) {
              return <AppReviewStatusLabel value={lineSheetRow.reviewStatus} />;
            }
            return "";
          },
        },
      },
    };
  }, [
    identity?.company?.type,
    isEditing,
    isRefreshing,
    rowConverter,
    rows,
    setRows,
    tTitle,
    tUpper,
  ]);

  type AttachmentGridKey = "type" | "name";

  const attachmentGridPairs = useMemo((): {
    [key in AttachmentGridKey]: {
      column: AppGridColumn;
      field: AppGridField;
    };
  } => {
    return {
      type: {
        column: {
          name: tTitle("type"),
          width: "128px",
        },
        field: {
          name: "type",
          value: (row) => {
            const attachmentRow = row as AttachmentRow;
            if (isEditing) {
              return (
                <LineSheetFileTypeSelect
                  value={"ATTACHMENT"}
                  isExcel={isExcel(
                    attachmentRow.file || {
                      id: attachmentRow.id,
                      name: attachmentRow.name,
                    }
                  )}
                  onSelect={(lineSheetType) => {
                    setRows((prev) => {
                      return prev?.map((row) => {
                        if (row.id === attachmentRow.id) {
                          return rowConverter.convert(row, {
                            type: lineSheetType,
                          });
                        }
                        return row;
                      });
                    });
                  }}
                />
              );
            } else {
              return (
                <Text>{`${
                  tUpper("attachment") +
                  "/" +
                  getFileExtension(attachmentRow.name)
                }`}</Text>
              );
            }
          },
        },
      },
      name: {
        column: {
          name: tTitle("name"),
          width: "minmax(224px, 1fr)",
        },
        field: {
          name: "name",
          value: (row) => {
            const attachmentRow = row as AttachmentRow;
            const { id, name } = attachmentRow;
            if (identity?.company?.type === "AGENCY") {
              return `#${id} ${name}`;
            } else {
              return name;
            }
          },
        },
      },
    };
  }, [
    identity?.company?.type,
    isEditing,
    rowConverter,
    setRows,
    tTitle,
    tUpper,
  ]);

  const onCheck = useCallback(
    (row: GridRow, checked: boolean) => {
      setRows((prev) => {
        return prev?.map((r) => {
          if (r.id === row.id) {
            return { ...r, isChecked: checked };
          }
          return r;
        });
      });
    },
    [setRows]
  );

  const onCheckAll = useCallback(
    (checked: boolean) => {
      setRows((prev) => {
        return prev?.map((r) => {
          return { ...r, isChecked: checked };
        });
      });
    },
    [setRows]
  );

  const attachmentGridMeta = useMemo((): AppGridMeta => {
    const onClick: AppGridMeta["onClick"] = (row) => {
      if (isEditing) {
        openFileInput(row);
      } else {
        lineSheetableGrid.actions.downloadAttachment(row)?.onClick();
      }
    };
    switch (identity?.company?.type) {
      default:
        return {
          gap: "8px",
          rowType: "Attachment",
          isCheckable: isEditing,
          columns: [
            attachmentGridPairs.type.column,
            attachmentGridPairs.name.column,
          ],
          fields: [
            attachmentGridPairs.type.field,
            attachmentGridPairs.name.field,
          ],
          indicator,
          actions: [
            ...withEditOnlyGridAction([
              lineSheetableGrid.actions.deleteLineSheetable,
            ]),
          ],
          onClick,
          onCheck,
          onCheckAll,
        };
    }
  }, [
    attachmentGridPairs.name.column,
    attachmentGridPairs.name.field,
    attachmentGridPairs.type.column,
    attachmentGridPairs.type.field,
    identity?.company?.type,
    indicator,
    isEditing,
    lineSheetableGrid.actions,
    onCheck,
    onCheckAll,
    openFileInput,
    withEditOnlyGridAction,
  ]);

  const lineSheetGridMeta = useMemo((): AppGridMeta => {
    const onClick: AppGridMeta["onClick"] = (row) => {
      if (isEditing) {
        openFileInput(row);
      } else {
        const lineSheetRow = row as IsolatedLineSheetRow;

        if (identity?.company?.type === "BOUTIQUE") {
          lineSheetableGrid.actions.downloadLineSheet(row)?.onClick();
          return;
        }

        if (
          isFallbackLineSheet({
            status: lineSheetRow.status!,
          })
        ) {
          lineSheetableGrid.actions.downloadLineSheet(row)?.onClick();
        } else if (lineSheetRow.status === "PARSED") {
          const action =
            lineSheetableGrid.actions.writeDraftOrViewLineSheet(row);
          if (action && !action.isLoading) {
            action.onClick();
          }
        }
      }
    };

    switch (identity?.company?.type) {
      case "BUYER":
        return {
          gap: "8px",
          rowType: "LineSheet",
          isCheckable: true,
          columns: [
            lineSheetGridPairs["line_sheet_set.type"].column,
            lineSheetGridPairs.title.column,
            {
              name: tTitle("submit_to"),
              width: "128px",
            },
            {
              name: tTitle("ordered_amount"),
              width: "132px",
            },
            {
              name: tTitle("status"),
              width: "160px",
            },
          ],
          fields: [
            lineSheetGridPairs["line_sheet_set.type"].field,
            lineSheetGridPairs.title.field,
            {
              name: "submit_to",
              value: (_row) => {
                return values.issuedByBoutique?.name || "";
              },
            },
            {
              name: "ordered_amount",
              value: (_row) => {
                return "";
              },
            },
            {
              name: "status",
              value: (_row) => {
                return "";
              },
            },
          ],
          indicator,
          actions: [
            ...withEditOnlyGridAction([
              lineSheetableGrid.actions.deleteLineSheetable,
            ]),
            ...withReadOnlyGridActions([
              lineSheetableGrid.actions.writeDraftOrViewLineSheet,
              lineSheetableGrid.actions.downloadLineSheet,
              lineSheetableGrid.actions.reparseLineSheet,
              lineSheetableGrid.actions.forceLineSheetParsingFailed,
              lineSheetableGrid.actions.pendReview,
              lineSheetableGrid.actions.approveReview,
              lineSheetableGrid.actions.rejectReview,
            ]),
          ],
          onClick,
        };
      case "AGENCY":
        return {
          gap: "8px",
          rowType: "LineSheet",
          isCheckable: true,
          columns: [
            lineSheetGridPairs["line_sheet_set.type"].column,
            lineSheetGridPairs.title.column,
            {
              name: tTitle("submit_to"),
              width: "128px",
            },
            {
              name: tTitle("ordered_amount"),
              width: "132px",
            },
            lineSheetGridPairs.status.column,
            lineSheetGridPairs.review_status.column,
          ],
          fields: [
            lineSheetGridPairs["line_sheet_set.type"].field,
            lineSheetGridPairs.title.field,
            {
              name: "submit_to",
              value: (_row) => {
                return "";
              },
            },
            {
              name: "ordered_amount",
              value: (_row) => {
                return "";
              },
            },
            lineSheetGridPairs.status.field,
            lineSheetGridPairs.review_status.field,
          ],
          indicator,
          actions: [
            ...withEditOnlyGridAction([
              lineSheetableGrid.actions.deleteLineSheetable,
            ]),
            ...withReadOnlyGridActions([
              lineSheetableGrid.actions.writeDraftOrViewLineSheet,
              lineSheetableGrid.actions.downloadLineSheet,
              lineSheetableGrid.actions.reparseLineSheet,
              lineSheetableGrid.actions.forceLineSheetParsingFailed,
              lineSheetableGrid.actions.pendReview,
              lineSheetableGrid.actions.approveReview,
              lineSheetableGrid.actions.rejectReview,
            ]),
          ],
          onClick,
          onCheck,
          onCheckAll,
        };
      case "BOUTIQUE":
        return {
          gap: "8px",
          isCheckable: isEditing,
          rowType: "LineSheet",
          columns: [
            lineSheetGridPairs["line_sheet_set.type"].column,
            lineSheetGridPairs.title.column,
          ],
          fields: [
            lineSheetGridPairs["line_sheet_set.type"].field,
            lineSheetGridPairs.title.field,
          ],
          indicator,
          actions: [
            ...withEditOnlyGridAction([
              lineSheetableGrid.actions.deleteLineSheetable,
            ]),
          ],
          onClick,
          onCheck,
          onCheckAll,
        };
      default: {
        return {
          gap: "8px",
          rowType: "LineSheet",
          columns: [],
          fields: [],
          actions: [],
          indicator,
        };
      }
    }
  }, [
    identity?.company?.type,
    isEditing,
    openFileInput,
    lineSheetableGrid.actions,
    lineSheetGridPairs,
    tTitle,
    indicator,
    withEditOnlyGridAction,
    withReadOnlyGridActions,
    onCheck,
    onCheckAll,
    values.issuedByBoutique?.name,
  ]);

  type OrderSheetGridKey =
    | "type"
    | "name"
    | "submitTo"
    | "orderedAmount"
    | "status";

  const orderSheetGridPairs = useMemo((): {
    [key in OrderSheetGridKey]: {
      column: AppGridColumn;
      field: AppGridField;
    };
  } => {
    return {
      type: {
        column: {
          name: tTitle("type"),
          width: "128px",
        },
        field: {
          name: "type",
          value: (row) => {
            const orderSheetRow = row as IsolatedOrderSheetRow;
            let key: string = "line_sheet";
            if (isFallbackLineSheet(orderSheetRow.lineSheet)) {
              key = "order_form";
            }
            return <Text>{tUpper(toLower(key))}</Text>;
          },
        },
      },
      name: {
        column: {
          name: tTitle("name"),
          width: "minmax(224px, 1fr)",
        },
        field: {
          name: "name",
          value: (row) => {
            const { id, name } = row as IsolatedOrderSheetRow;
            if (identity?.company?.type === "AGENCY") {
              return `#${id} ${name}`;
            } else {
              return name;
            }
          },
        },
      },
      submitTo: {
        column: {
          name: tTitle("submit_to"),
          width: "128px",
        },
        field: {
          name: "submit_to",
          value: (row) => {
            const orderSheetRow = row as IsolatedOrderSheetRow;
            if (orderSheetRow.toBeSubmitted) {
              return orderSheetRow.toBeSubmitted.name;
            }
            return "";
          },
        },
      },
      orderedAmount: {
        column: {
          name: tTitle("ordered_amount.abbr"),
          width: "132px",
          textAlign: "end",
        },
        field: {
          name: "ordered_amount",
          value: (row) => {
            const orderSheetRow = row as IsolatedOrderSheetRow;
            if (
              orderSheetRow.status !== "INITIAL_DRAFT" &&
              orderSheetRow.orderedAmountWithoutCondition !== 0 &&
              orderSheetRow.orderedAmount !== 0
            ) {
              return (
                <Flex
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                  marginRight={"8px"}
                >
                  <Box fontWeight={"700"}>
                    {tCurrencyString(
                      orderSheetRow.orderedAmountWithoutCondition,
                      { type: "SYMBOL", code: values.currency }
                    )}
                  </Box>
                  <Box>
                    {tConditionAppliedCurrencyString(
                      orderSheetRow.orderedConditionType,
                      orderSheetRow.orderedAmount,
                      { type: "SYMBOL", code: values.currency }
                    )}
                  </Box>
                </Flex>
              );
            }
            return "";
          },
        },
      },
      status: {
        column: {
          name: tTitle("status"),
          width: "160px",
        },
        field: {
          name: "status",
          value: (row) => {
            const orderSheetRow = row as IsolatedOrderSheetRow;
            return <AppOrderSheetStatusLabel value={orderSheetRow.status} />;
          },
        },
      },
    };
  }, [
    identity?.company?.type,
    tConditionAppliedCurrencyString,
    tCurrencyString,
    tTitle,
    tUpper,
  ]);

  const orderSheetGridMeta = useMemo((): AppGridMeta => {
    const onClick: AppGridMeta["onClick"] = (row) => {
      const orderSheetRow = row as IsolatedOrderSheetRow;
      if (!isEditing) {
        if (isFallbackLineSheet(orderSheetRow.lineSheet)) {
          lineSheetableGrid.actions.downloadOrderSheet(row)?.onClick();
        } else {
          lineSheetableGrid.actions.editOrViewOrderSheet(row)?.onClick();
        }
      }
    };

    const onCheck: AppGridMeta["onCheck"] = (row, checked) => {
      const orderSheetRow = row as IsolatedOrderSheetRow;
      setRows((prev) => {
        if (prev) {
          let statues = new Set<OrderSheet["status"]>();
          const next = prev.map((row) => {
            if (isOrderSheetRow(row)) {
              if (row.id === orderSheetRow.id) {
                statues.add(row.status);
                return { ...row, isChecked: checked };
              }
              return row;
            }
            return row;
          });

          if (statues.size === 0) {
            return next.map((row) => {
              if (isOrderSheetRow(row)) {
                return { ...row, isCheckable: true };
              }
              return row;
            });
          } else if (statues.size === 1) {
            return next.map((row) => {
              if (isOrderSheetRow(row)) {
                if (row.status === statues.values().next().value) {
                  return { ...row, isCheckable: true };
                }
              }
              return row;
            });
          } else {
            throw {
              message:
                "Cannot check multiple order sheets with different status",
            };
          }
        }

        return prev;
      });
    };

    const onCheckAll: AppGridMeta["onCheckAll"] = (checked) => {
      setRows((prev) => {
        if (prev) {
          return prev.map((row) => {
            if (isOrderSheetRow(row) && row.isCheckable) {
              return { ...row, isChecked: checked };
            }
            return row;
          });
        }
        return prev;
      });
    };

    switch (identity?.company?.type) {
      case "AGENCY":
        return {
          gap: "8px",
          rowType: "OrderSheet",
          isCheckable: true,
          showCheckAll: true,
          columns: [
            orderSheetGridPairs.type.column,
            orderSheetGridPairs.name.column,
            orderSheetGridPairs.submitTo.column,
            orderSheetGridPairs.orderedAmount.column,
            orderSheetGridPairs.status.column,
            {
              name: tTitle("review_status"),
              width: "132px",
            },
          ],
          fields: [
            orderSheetGridPairs.type.field,
            orderSheetGridPairs.name.field,
            orderSheetGridPairs.submitTo.field,
            orderSheetGridPairs.orderedAmount.field,
            orderSheetGridPairs.status.field,
            {
              name: "ordered_amount",
              value: (_row) => {
                return "";
              },
            },
          ],
          indicator,
          extraContents:
            lineSheetableGrid.extraContents.orderSheetExtraContents,
          actions: [
            ...withEditOnlyGridAction([
              lineSheetableGrid.actions.deleteLineSheetable,
            ]),
            ...withReadOnlyGridActions([
              lineSheetableGrid.actions.writeDraftByOrderSheet,
              lineSheetableGrid.actions.editOrViewOrderSheet,
              lineSheetableGrid.actions.uploadFallbackOrderSheet,
              lineSheetableGrid.actions.downloadOrderSheet,
              lineSheetableGrid.actions.deleteOrderSheet,
              lineSheetableGrid.actions.downloadLineSheet,
              lineSheetableGrid.actions.copyOrderSheet,
              lineSheetableGrid.actions.updateToBeSubmitted,
            ]),
          ],
          onClick,
          onCheck,
          onCheckAll,
        };
      default:
        return {
          gap: "8px",
          rowType: "OrderSheet",
          isCheckable: true,
          showCheckAll: true,
          columns: [
            orderSheetGridPairs.type.column,
            orderSheetGridPairs.name.column,
            orderSheetGridPairs.submitTo.column,
            orderSheetGridPairs.orderedAmount.column,
            orderSheetGridPairs.status.column,
          ],
          fields: [
            orderSheetGridPairs.type.field,
            orderSheetGridPairs.name.field,
            orderSheetGridPairs.submitTo.field,
            orderSheetGridPairs.orderedAmount.field,
            orderSheetGridPairs.status.field,
          ],
          indicator,
          extraContents:
            lineSheetableGrid.extraContents.orderSheetExtraContents,
          actions: [
            ...withEditOnlyGridAction([
              lineSheetableGrid.actions.deleteLineSheetable,
            ]),
            ...withReadOnlyGridActions([
              lineSheetableGrid.actions.writeDraftByOrderSheet,
              lineSheetableGrid.actions.editOrViewOrderSheet,
              lineSheetableGrid.actions.uploadFallbackOrderSheet,
              lineSheetableGrid.actions.downloadOrderSheet,
              lineSheetableGrid.actions.deleteOrderSheet,
              lineSheetableGrid.actions.downloadLineSheet,
              lineSheetableGrid.actions.copyOrderSheet,
              lineSheetableGrid.actions.updateToBeSubmitted,
            ]),
          ],
          onClick,
          onCheck,
          onCheckAll,
        };
    }
  }, [
    identity?.company?.type,
    indicator,
    isEditing,
    lineSheetableGrid.actions,
    lineSheetableGrid.extraContents.orderSheetExtraContents,
    orderSheetGridPairs.name.column,
    orderSheetGridPairs.name.field,
    orderSheetGridPairs.orderedAmount.column,
    orderSheetGridPairs.orderedAmount.field,
    orderSheetGridPairs.status.column,
    orderSheetGridPairs.status.field,
    orderSheetGridPairs.submitTo.column,
    orderSheetGridPairs.submitTo.field,
    orderSheetGridPairs.type.column,
    orderSheetGridPairs.type.field,
    setRows,
    withEditOnlyGridAction,
    withReadOnlyGridActions,
  ]);

  return (
    <AppGrid
      isAnchored={false}
      showNoRows={!isEditing}
      rows={rows}
      metas={
        orderSheetSet
          ? [orderSheetGridMeta, lineSheetGridMeta, attachmentGridMeta]
          : [lineSheetGridMeta, orderSheetGridMeta, attachmentGridMeta]
      }
    />
  );
}
