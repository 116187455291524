import { Helmet } from "react-helmet-async";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import AppIconButton from "@/features/line-sheet-sets/app-icon-button";
import AppButton from "@/features/line-sheet-sets/app-button";
import AppTabSelect from "@/features/line-sheet-sets/app-tap-select";
import OrderRulesPanel from "@/features/order-sheet-sets/order-rules-panel";
import SheetTabFilter, {
  EMPTY_SHEET_TAB_FILTER_QUERY,
  isProductFiltered,
  SheetTabFilterQuery,
} from "@/features/order-sheet-sets/sheet-tab-filter";
import { useParams } from "react-router";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useLineSheetSetPagePersistedQuery from "@/features/line-sheet-sets/hooks/use-line-sheet-set-page-persisted-query";
import LineSheetSetBillboard from "@/features/line-sheet-sets/line-sheet-set-billboard";
import { produce } from "immer";
import LineSheetSelector from "@/features/line-sheet-sets/line-sheet-selector";
import {
  LightLineSheet,
  LightLineSheetTab,
} from "@/features/line-sheet-sets/line-sheet-set.types";
import useFetchAndSetLineSheetTabs, {
  UseFetchAndSetLineSheetTabsParameter,
} from "@/features/line-sheet-sets/use-fetch-and-set-line-sheet-tabs";
import LineSheetTab from "@/features/line-sheet-sets/line-sheet-tab";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import useInflatedTabs from "@/features/line-sheet-sets/helpers/use-inflated-tabs";
import { FlatProduct } from "@/features/line-sheet-sets/helpers/sheet-state";
import { UpdateLineSheetSetOrderRulesResponse } from "@/features/line-sheet-sets/hooks/use-update-line-sheet-set-order-rules";
import useWrappedNavigate from "@/features/ui/hooks/use-wrapped-navigate";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppDownloadButton from "@/features/invoices/app-download-button";
import usePublishLineSheetSetIfConfirmed from "@/features/line-sheet-sets/hooks/use-publish-line-sheet-set-if-confirmed";
import useFetchAndSetLineSheetSet, {
  UseFetchAndSetLineSheetSetParameter,
} from "./hooks/use-fetch-and-set-line-sheet-set";

export default function LineSheetSetPage() {
  const navigate = useWrappedNavigate();
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);
  const { error: showError } = useAppToasts();
  const { lineSheetSetId: rawLineSheetSetId } = useParams();
  const lineSheetSetId = useMemo(() => {
    return rawLineSheetSetId ? parseInt(rawLineSheetSetId) || -1 : -1;
  }, [rawLineSheetSetId]);

  const { fire: publishLineSheetSetIfConfirmed } =
    usePublishLineSheetSetIfConfirmed();

  useEffect(() => {
    if (lineSheetSetId === -1) {
      navigate("/");
    }
  }, [lineSheetSetId, navigate]);
  const { tTitle } = useI18nHelper();

  const getLineSheetSetParameter =
    useMemo((): UseFetchAndSetLineSheetSetParameter => {
      return {
        data: {
          lineSheetSetId: lineSheetSetId,
        },
      };
    }, [lineSheetSetId]);

  const { getLineSheetSetError, getLineSheetSetData, mutateGetLineSheetSet } =
    useFetchAndSetLineSheetSet(getLineSheetSetParameter);

  useEffect(() => {
    showError(getLineSheetSetError);
  }, [showError, getLineSheetSetError]);

  const { value: query, set: setQuery } = useLineSheetSetPagePersistedQuery();

  const lineSheetSet = useMemo(() => {
    return getLineSheetSetData;
  }, [getLineSheetSetData]);

  const selectedLineSheet = useMemo(() => {
    if (lineSheetSet && query.lineSheetId) {
      return lineSheetSet.lineSheets.find(
        (lineSheet) => lineSheet.id === query.lineSheetId
      );
    }
  }, [lineSheetSet, query.lineSheetId]);

  const lineSheetSelectValues = useMemo(() => {
    if (lineSheetSet) {
      return lineSheetSet.lineSheets.filter(
        (lineSheet) => lineSheet.status === "PARSED"
      );
    }
    return [];
  }, [lineSheetSet]);

  const lineSheetTabSelectValues = useMemo(() => {
    if (lineSheetSet && selectedLineSheet) {
      return selectedLineSheet.lineSheetTabs;
    }
    return [];
  }, [lineSheetSet, selectedLineSheet]);

  const selectedLineSheetTab = useMemo(() => {
    if (
      lineSheetSet &&
      selectedLineSheet &&
      query.lineSheetTabIndex !== undefined
    ) {
      const tabIndex = query.lineSheetTabIndex;
      return selectedLineSheet.lineSheetTabs.find(
        (tab) => tab.index === tabIndex
      );
    }
  }, [lineSheetSet, selectedLineSheet, query.lineSheetTabIndex]);

  //기본 tabIndex 채워주기
  useEffect(() => {
    if (
      lineSheetSet &&
      selectedLineSheet &&
      query.lineSheetTabIndex === undefined
    ) {
      setQuery(
        (prev) => {
          return {
            ...prev,
            lineSheetTabIndex:
              selectedLineSheet.lineSheetTabs.length > 0
                ? selectedLineSheet.lineSheetTabs[0].index
                : undefined,
          };
        },
        {
          replace: true,
        }
      );
    }
  }, [lineSheetSet, selectedLineSheet, query.lineSheetTabIndex, setQuery]);

  const handleSelectLineSheet = useCallback(
    (value?: LightLineSheet) => {
      if (value) {
        setQuery(
          (prev) => {
            return {
              ...prev,
              lineSheetId: value.id,
              lineSheetTabIndex: undefined,
              ...EMPTY_SHEET_TAB_FILTER_QUERY,
            };
          },
          {
            replace: true,
          }
        );
      }
    },
    [setQuery]
  );

  const handleSelectLineSheetTab = useCallback(
    (value?: LightLineSheetTab) => {
      if (value) {
        setQuery(
          (prev) => {
            return {
              ...prev,
              lineSheetTabIndex: value.index,
              ...EMPTY_SHEET_TAB_FILTER_QUERY,
            };
          },
          {
            replace: true,
          }
        );
      }
    },
    [setQuery]
  );

  const [isOrderRulesPanelOpen, setIsOrderRulesPanelOpen] =
    useState<boolean>(false);

  const handleOrderRulesToggle = useCallback(() => {
    setIsOrderRulesPanelOpen((prev) => !prev);
  }, [setIsOrderRulesPanelOpen]);

  const handleOrderRulesSave = useCallback(
    (response: UpdateLineSheetSetOrderRulesResponse) => {
      mutateGetLineSheetSet(
        (prev) => {
          if (prev) {
            prev.orderRules = [];
            return produce(prev, (draft) => {
              draft.orderRules = response.orderRules;
            });
          }
        },
        { revalidate: false }
      );
    },
    [mutateGetLineSheetSet]
  );

  const useFetchAndSetLineSheetTabsParameter =
    useMemo((): UseFetchAndSetLineSheetTabsParameter => {
      if (lineSheetSet && selectedLineSheet) {
        return {
          data: {
            lineSheetSetId: lineSheetSet.id,
            lineSheetId: selectedLineSheet.id,
            tabIndexes: selectedLineSheet.lineSheetTabs.map((i) => i.index),
          },
          query,
        };
      }
      return {
        data: undefined,
      };
    }, [lineSheetSet, selectedLineSheet, query]);

  const { mutateListAllLineSheetTabs } = useFetchAndSetLineSheetTabs(
    useFetchAndSetLineSheetTabsParameter
  );

  const gridRef = useRef<{
    api: GridApi<FlatProduct>;
    columnApi: ColumnApi;
  } | null>(null);

  const handleGridReady = useCallback((event: GridReadyEvent<FlatProduct>) => {
    gridRef.current = {
      api: event.api,
      columnApi: event.columnApi,
    };
  }, []);

  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);

  const handleFilterSelect = useCallback(
    (query: SheetTabFilterQuery) => {
      setQuery(
        (prev) => {
          return {
            ...prev,
            ...query,
          };
        },
        {
          replace: true,
        }
      );
    },
    [setQuery]
  );

  const _selectedLineSheetId = useMemo(() => {
    return selectedLineSheet?.id || -1;
  }, [selectedLineSheet]);

  const { filterInflatedProducts, resetInflatedSheet } = useInflatedTabs();

  useEffect(() => {
    if (selectedLineSheet && selectedLineSheetTab) {
      filterInflatedProducts(
        {
          lineSheetSetId,
          lineSheetId: selectedLineSheet.id,
        },
        selectedLineSheetTab.index,
        (product) => {
          return isProductFiltered(product, query);
        }
      );
    }
  }, [
    lineSheetSetId,
    selectedLineSheet,
    selectedLineSheetTab,
    filterInflatedProducts,
    query,
  ]);

  const sheetKey = useMemo(() => {
    return {
      lineSheetSetId,
      lineSheetId: _selectedLineSheetId,
    };
  }, [lineSheetSetId, _selectedLineSheetId]);

  useEffect(() => {
    return () => {
      resetInflatedSheet(sheetKey);
    };
  }, [sheetKey, resetInflatedSheet]);

  const handleSheetTabFilterClear = useCallback(() => {
    setQuery(
      (prev) => {
        return {
          ...prev,
          ...EMPTY_SHEET_TAB_FILTER_QUERY,
        };
      },
      {
        replace: true,
      }
    );
  }, [setQuery]);

  return (
    <>
      <Helmet>
        <title>Pathrade | Line Sheets</title>
        <link
          rel="canonical"
          href={window.location.origin + window.location.pathname}
        />
        <meta property="og:title" content="Pathrade | Line Sheets" />
        <meta
          property="og:url"
          content={window.location.origin + window.location.pathname}
        />
      </Helmet>
      <Flex flexDirection={"column"} height={"100%"}>
        <Flex
          flexDirection={"row"}
          p={"8px 16px"}
          gap={"8px"}
          alignItems={"center"}
          borderBottom={"0.5px solid var(--gray, #D4D4D1)"}
          background={"var(--white, #FFF)"}
        >
          <Box flexShrink={"0"} flexGrow={"0"} alignSelf={"start"} pt={"1.5px"}>
            <AppIconButton
              variant={"ghost"}
              ariaLabel={"go to previous page"}
              size={"medium"}
              icon={"left"}
              onClick={() => {
                navigate(-1, "/line-sheets");
              }}
            />
          </Box>
          <LineSheetSetBillboard lineSheetSet={lineSheetSet} />
          <Spacer />
          <Flex flexDirection={"row"} gap={"8px"} wrap={"wrap"} flexGrow={"0"}>
            {company?.type === "AGENCY" && !lineSheetSet?.isDeleted && (
              <>
                <AppButton
                  isDisabled={getLineSheetSetData?.publishedAt !== null}
                  width={"210px"}
                  onClick={async () => {
                    const result = await publishLineSheetSetIfConfirmed([
                      lineSheetSetId,
                    ]);
                    if (result) {
                      mutateGetLineSheetSet();
                    }
                  }}
                >
                  {tTitle("line_sheet_sets:publish_this_line_sheet_set")}
                </AppButton>
              </>
            )}
            <AppDownloadButton value={selectedLineSheet?.file} />
          </Flex>
        </Flex>
        <Flex>
          <Flex
            position={"relative"}
            zIndex={"2"}
            background={"#FCFCFE"}
            flexDirection={"row"}
            alignItems={"center"}
            width={"652px"}
            gap={"8px"}
            pl={"16px"}
          >
            <Box
              fontSize={"13px"}
              fontWeight={"700"}
              lineHeight={"16px"}
              flexGrow={"1"}
            >
              {identity?.company?.type === "AGENCY"
                ? `#${lineSheetSet?.id} ${lineSheetSet?.name}`
                : lineSheetSet?.name}
            </Box>
            <LineSheetSelector
              minWidth={"326px"}
              values={lineSheetSelectValues}
              value={selectedLineSheet}
              onSelect={handleSelectLineSheet}
            />
          </Flex>
          <AppTabSelect
            name={"Line Sheet Tabs"}
            value={selectedLineSheetTab}
            values={lineSheetTabSelectValues}
            onSelect={handleSelectLineSheetTab}
          />
        </Flex>
        <Flex flexGrow={"1"} flexDirection={"column"}>
          <Flex
            flexDirection={"row"}
            padding={"2px 12px 8px 16px"}
            alignItems={"center"}
            gap={"8px"}
          >
            <AppButton variant={"normal"} onClick={handleOrderRulesToggle}>
              {tTitle("order_rules")}
            </AppButton>
            <Spacer />
            <SheetTabFilter
              sheetKey={sheetKey}
              tabIndex={selectedLineSheetTab ? selectedLineSheetTab.index : -1}
              query={query}
              onFilterSelect={handleFilterSelect}
              onClear={handleSheetTabFilterClear}
              isLineSheetProduct={true}
            />
          </Flex>
          <Flex flexGrow={"1"} flexDirection={"row"} gap={"8px"}>
            {isOrderRulesPanelOpen && (
              <Box>
                <OrderRulesPanel
                  isEditable={
                    company?.type === "AGENCY" &&
                    lineSheetSet?.status === "PARSING_COMPLETED"
                  }
                  onClose={handleOrderRulesToggle}
                  orderRules={lineSheetSet?.orderRules || []}
                  lineSheetSetId={lineSheetSet?.id || -1}
                  onUpdate={handleOrderRulesSave}
                />
              </Box>
            )}
            <LineSheetTab
              lineSheetSetId={lineSheetSetId}
              lineSheetId={selectedLineSheet?.id || -1}
              tabIndex={selectedLineSheetTab ? selectedLineSheetTab.index : -1}
              isLoading={false}
              onSelectedProductIdsChange={setSelectedProductIds}
              onGridReady={handleGridReady}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
