import useIdentity from "@/features/ui/hooks/use-identity";
import { useEffect, useMemo, useState } from "react";
import BuyerDashboardPage from "@/features/dashboard/buyer-dashboard-page";
import { Helmet } from "react-helmet-async";
import TagManager from "react-gtm-module";

export default function DashboardPage() {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company;
  }, [identity]);

  const [isGTMPageviewReported, setIsGTMPageviewReported] = useState(false);
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_API_URL != "https://api.pathrade.com" ||
      isGTMPageviewReported
    ) {
      return;
    }
    const tagManagerArgs = {
      dataLayer: {
        event: "pageview",
        page: "Dashboard",
        userId: identity?.id,
        companyId: identity?.company?.id,
      },
      dataLayerName: "PageviewDataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
    setIsGTMPageviewReported(true);
    console.log("Reporting GTM pageview for Dashboard is done.");
  }, [identity]);

  if (company?.type === "BUYER") {
    return (
      <>
        <Helmet>
          <title>Pathrade | Dashboard</title>
          <link
            rel="canonical"
            href={window.location.origin + window.location.pathname}
          />
          <meta property="og:title" content="Pathrade | Dashboard" />
          <meta
            property="og:url"
            content={window.location.origin + window.location.pathname}
          />
        </Helmet>
        <BuyerDashboardPage />
      </>
    );
  } else {
    return null;
  }
}
