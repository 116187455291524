import AppGrid from "@/features/order-sheet-sets/app-grid";
import { useRecoilState } from "recoil";
import { buyerLineSheetSetRowsState } from "@/features/order-sheet-sets/recoils/buyer-line-sheet-set-rows-state";
import GridRowIndicator from "@/features/order-sheet-sets/grid-row-indicator";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useCallback, useEffect } from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useBuyerLineSheetSetRows from "@/features/order-sheet-sets/hooks/use-buyer-line-sheet-set-rows";
import UploadOrderSheetsModal from "@/features/order-sheet-sets/upload-order-sheets-modal";
import { Flex, Text } from "@chakra-ui/react";
import { findParentGridRow } from "@/features/order-sheet-sets/helpers/app-grid-row-helper";
import OrderOrderSheetSetModal from "@/features/order-sheet-sets/order-order-sheet-set-modal";
import OrderSheetRowTooltipLabel from "@/features/order-sheet-sets/order-sheet-row-tooltip-label";
import {
  findYWRInfiniteSegment,
  postProcessYWRInfiniteData,
} from "@/features/ui/helpers/ywr-helpers";
import {
  BuyerAttachmentRow,
  BuyerLineSheetRow,
  BuyerLineSheetSetRow,
} from "@/features/order-sheet-sets/line-sheet-set-row.type";
import useReadLineSheetSetInfinite from "@/features/line-sheet-sets/hooks/use-read-line-sheet-set-infinite";
import { readLineSheetSetKey } from "@/features/line-sheet-sets/hooks/use-read-line-sheet-set";
import { readDenseLineSheetSetProactively } from "@/features/line-sheet-sets/hooks/use-list-dense-line-sheet-sets";
import useAppGridMutate from "@/features/ui/hooks/use-app-grid-mutate";
import { toLower, toTitle, toUpper } from "@/utils/case";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useLineSheetSetGrid from "@/features/order-sheet-sets/hooks/use-line-sheet-set-grid";
import useRouterHelper from "@/features/ui/hooks/use-router-helper";
import AppOrderSheetStatusLabel from "@/features/ui/app-order-sheet-status-label";
import { BuyerOrderSheetRow } from "@/features/order-status/order-sheet-set-row.type";

export default function BuyerLineSheetSetGrid() {
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const [rows, setRows] = useRecoilState(buyerLineSheetSetRowsState);
  const { hover, toggleCollapseIfRoot, toggleCheck } = useAppGridMutate(
    buyerLineSheetSetRowsState
  );
  const { mutateRows } = useBuyerLineSheetSetRows();
  const { discloseTooltip } = useAppGridMutate(buyerLineSheetSetRowsState);
  const {
    t,
    tLocalDateString,
    tLocalDateTimeString,
    tLocalDateTimeString2,
    tCurrencyString,
  } = useI18nHelper();

  const { navigateToLineSheetSetPage, navigateToOrderSheetSetPage } =
    useRouterHelper();

  const {
    disclosureUploadOrderSheetModal,
    disclosureOrderOrderSheetSetModal,
    actions,
    fields,
    extraContents,
  } = useLineSheetSetGrid({
    rows,
    discloseTooltip,
    mutateRows,
  });

  // --- API: Read Line Sheet Set ---
  const {
    fire: fireReadLineSheetSet,
    data: readLineSheetSetData,
    clear: clearReadLineSheetSet,
  } = useReadLineSheetSetInfinite();

  useEffect(() => {
    postProcessYWRInfiniteData(
      readLineSheetSetData,
      clearReadLineSheetSet,
      showError
    );
  }, [readLineSheetSetData, clearReadLineSheetSet, showError]);

  const findReadLineSheetSetSegment = useCallback(
    (row: BuyerLineSheetSetRow) => {
      if (identity?.company) {
        return findYWRInfiniteSegment(
          readLineSheetSetData,
          readLineSheetSetKey,
          {
            by: identity.company.type,
            companyId: identity.company.id,
            lineSheetSetId: row.id,
          }
        );
      }
    },
    [identity, readLineSheetSetData]
  );

  // --- END: API: Read Line Sheet Set ---

  return (
    <>
      <AppGrid
        metas={[
          {
            rowType: "LineSheetSet",
            gap: "4px",
            columns: [
              { name: toTitle(t("type")), width: "56px" },
              { name: toTitle(t("season")), width: "48px" },
              { name: toTitle(t("brand")), width: "144px" },
              { name: toTitle(t("issued_by")), width: "144px" },
              { name: toTitle(t("title")), width: "minmax(224px, 1fr)" },
              {
                name: toTitle(t("ordered_amount")),
                width: "120px",
                textAlign: "end",
                marginRight: "24px",
              },
              { name: toTitle(t("published_on")), width: "88px" },
              { name: toTitle(t("order_valid_until")), width: "128px" },
              { name: toTitle(t("status")), width: "160px" },
            ],
            fields: [
              {
                name: "type",
                value: (_row) => {
                  const type = (_row as BuyerLineSheetSetRow).type;
                  return toUpper(t(`line_sheet_set.type.${toLower(type)}`));
                },
              },
              {
                name: "season",
                value: (r) => {
                  return (r as BuyerLineSheetSetRow).season;
                },
              },
              {
                name: "Brand",
                value: (r) => {
                  return (r as BuyerLineSheetSetRow).issuedByBrandName || "";
                },
              },
              {
                name: "IssuedBy",
                value: (r) => {
                  return (r as BuyerLineSheetSetRow).issuedByBoutiqueName;
                },
              },
              {
                name: "Title",
                isBold: true,
                value: (r) => {
                  return (r as BuyerLineSheetSetRow).name;
                },
              },
              {
                name: "Ordered Amount",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const orderedAmount = (r as BuyerLineSheetSetRow)
                    .orderedAmount;
                  const orderedAmountWithoutCondition = (
                    r as BuyerLineSheetSetRow
                  ).orderedAmountWithoutCondition;
                  const orderedConditionType = (r as BuyerLineSheetSetRow)
                    .orderedConditionType;
                  let conditionStr = "";
                  if (orderedConditionType === "DISCOUNT") {
                    conditionStr = "R- ";
                  } else if (orderedConditionType === "MARKUP") {
                    conditionStr = "C+ ";
                  } else if (orderedConditionType === "MIX") {
                    conditionStr = "R/C ";
                  }
                  return (
                    <Flex
                      flexDirection={"column"}
                      fontSize={"12px"}
                      alignItems={"flex-end"}
                      marginRight={"24px"}
                    >
                      <Text fontWeight={"700"}>
                        {tCurrencyString(orderedAmountWithoutCondition, {
                          type: "SYMBOL",
                          code: (r as BuyerLineSheetSetRow).currency,
                        })}
                      </Text>
                      <Text>
                        ({conditionStr}
                        {tCurrencyString(orderedAmount, {
                          type: "SYMBOL",
                          code: (r as BuyerLineSheetSetRow).currency,
                        })}
                        )
                      </Text>
                    </Flex>
                  );
                },
              },
              {
                name: "publishedOn",
                chakraProps: { fontWeight: "700" },
                value: (r) => {
                  const value = (r as BuyerLineSheetSetRow).publishedAt;
                  return tLocalDateString(value);
                },
              },
              {
                name: "validUntil",
                value: (r) => {
                  const value = (r as BuyerLineSheetSetRow).validUntil;
                  return tLocalDateTimeString2(value);
                },
              },
              {
                name: "status",
                value: (_row, context) => {
                  const row = _row as BuyerLineSheetSetRow;
                  if (row.orderSheetSetStatus) {
                    return (
                      <AppOrderSheetStatusLabel
                        value={row.orderSheetSetStatus}
                      />
                    );
                  }
                  return "";
                },
              },
              // fields.withOrderSheetStatus(true),
            ],
            actions: [
              // actions.orderOrderSheetSet,
              // actions.cancelOrderSheetSet,
              actions.withDownloadUnoFile<BuyerLineSheetSetRow>((row) => {
                return row.compressedImportedFile;
              }),
            ],
            indicator: (row) => {
              return (
                <GridRowIndicator
                  type={"VIEW"}
                  isHovered={row.isHovered}
                  isCollapsed={row.isCollapsed}
                />
              );
            },
            onClick: (row) => {
              const lineSheetSetRow = row as BuyerLineSheetSetRow;
              if (lineSheetSetRow.orderSheetSetId > 0) {
                navigateToOrderSheetSetPage(
                  lineSheetSetRow.id,
                  lineSheetSetRow.orderSheetSetId
                );
              } else {
                navigateToLineSheetSetPage(lineSheetSetRow.id);
              }
            },
          },
          {
            rowType: "LineSheet",
            columns: [
              { name: "type", width: "232px" },
              { name: "name", width: "minmax(224px, 1fr)" },
              { name: "status", width: "144px" },
            ],
            fields: [
              fields.lineSheetType,
              {
                name: "name",
                chakraProps: {
                  fontWeight: 700,
                },
                value: (r) => {
                  return (r as BuyerLineSheetRow).name;
                },
              },
            ],
            actions: [
              actions.writeDraft,
              actions.writeFallbackDraft,
              actions.viewLineSheet,
              actions.withDownloadUnoFile<BuyerLineSheetRow>((row) => {
                return row.file;
              }),
            ],
            tooltip: (_row) => {
              const row = _row as BuyerLineSheetRow;
              if (row.isWritable) {
                return (
                  <OrderSheetRowTooltipLabel isFallback={row.isFallback} />
                );
              }
            },
          },
          {
            rowType: "Attachment",
            columns: [
              { name: "type", width: "232px" },
              { name: "name", width: "minmax(224px, 1fr)" },
              { name: "status", width: "144px" },
            ],
            fields: [
              {
                name: "type",
                value: (row) => {
                  const attachmentRow = row as BuyerAttachmentRow;
                  const i = attachmentRow.name.lastIndexOf(".");
                  let suffix = "";
                  if (i > -1) {
                    suffix =
                      "/" + attachmentRow.name.substring(i + 1).toUpperCase();
                  }
                  return `${toUpper(t("attachment"))}${suffix}`;
                },
              },
              {
                name: "name",
                value: (row) => {
                  const attachmentRow = row as BuyerAttachmentRow;
                  return attachmentRow.name;
                },
              },
            ],
            actions: [
              actions.withDownloadUnoFile<BuyerAttachmentRow>((row) => row),
            ],
          },
          {
            rowType: "OrderSheet",
            columns: [
              { name: "type", width: "152px" },
              { name: "name", width: "minmax(224px, 1fr)" },
              { name: "Ordered Amount", width: "144px" },
              { name: "boutiqueName ", width: "104px" },
              { name: "status", width: "144px" },
            ],
            fields: [
              { name: "type", value: (r) => toUpper(t("order_sheet")) },
              {
                name: "name",
                chakraProps: {
                  fontWeight: 700,
                },
                value: (row) => {
                  const orderSheetRow = row as BuyerOrderSheetRow;
                  const lineSheetRow = findParentGridRow(
                    orderSheetRow,
                    rows
                  ) as BuyerLineSheetRow;
                  return lineSheetRow.name;
                },
              },
              {
                name: "OrderedAmount",
                value: (r) => {
                  const orderSheetRow = r as BuyerOrderSheetRow;
                  return (
                    <Text textAlign={"end"} marginEnd={"24px"}>
                      {tCurrencyString(orderSheetRow.orderedAmount, {
                        type: "SYMBOL",
                        code: (r as BuyerLineSheetSetRow).currency,
                      })}
                    </Text>
                  );
                },
              },
              {
                name: "boutiqueName",
                value: (r) => {
                  const orderSheetRow = r as BuyerOrderSheetRow;
                  return orderSheetRow.toBeSubmitted?.name || "";
                },
              },
              {
                name: "status",
                value: (_row, context) => {
                  const row = _row as BuyerOrderSheetRow;
                  return <AppOrderSheetStatusLabel value={row.status} />;
                },
              },
            ],
            actions: [
              actions.editOrViewOrderSheet,
              actions.replaceOrUploadUploadedFile,
              actions.deleteUploadedFile,
              actions.withDownloadUnoFile<BuyerOrderSheetRow>(
                (row) => row.uploadedFile || row.exportedFile
              ),
            ],
            extraContents: extraContents.orderSheetExtraContents,
          },
        ]}
        rows={rows}
        onHover={(path, row, isHovered) => {
          hover(row, isHovered);
        }}
        onClick={(path, _row) => {
          if (_row._rowType === "LineSheetSet") {
            const row = _row as BuyerLineSheetSetRow;

            if (!row.isRead && identity?.company) {
              readDenseLineSheetSetProactively(row.id);

              const isLoading =
                findReadLineSheetSetSegment(row)?.isLoading || false;
              if (!isLoading) {
                fireReadLineSheetSet(
                  {
                    by: identity.company.type,
                    companyId: identity.company.id,
                    lineSheetSetId: row.id,
                  },
                  {}
                );
              }
            }

            //toggleCollapseIfRoot(row);
          }
        }}
        onCheck={(path, row, isChecked) => {
          toggleCheck(row);
        }}
      ></AppGrid>
      {disclosureUploadOrderSheetModal.data && (
        <UploadOrderSheetsModal
          {...disclosureUploadOrderSheetModal.data}
          isOpen={disclosureUploadOrderSheetModal.isOpen}
          onClose={disclosureUploadOrderSheetModal.onClose}
        />
      )}
      {disclosureOrderOrderSheetSetModal.data && (
        <OrderOrderSheetSetModal
          {...disclosureOrderOrderSheetSetModal.data}
          isOpen={disclosureOrderOrderSheetSetModal.isOpen}
          onClose={disclosureOrderOrderSheetSetModal.onClose}
        />
      )}
    </>
  );
}
