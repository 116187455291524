import { useMemo } from "react";
import AppSelect from "@/features/line-sheet-sets/app-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";

export interface AppCurrencySelectProps {
  value?: string;
  onSelect?: (value?: string) => void;
  width?: string;
  isDisabled?: boolean;
  after?: string;
  before?: string;
  isClearable?: boolean;
  readonly?: boolean;
}

export const CURRENCY_OPTIONS = (() => {
  const options: AppSelectOption<string>[] = [];
  options.push(
    {
      name: "EUR",
      value: "EUR",
    },
    {
      name: "USD",
      value: "USD",
    },
    {
      name: "KRW",
      value: "KRW",
    }
  );
  return options;
})();

export default function AppCurrencySelect({
  value,
  width = "144px",
  onSelect,
  isDisabled = false,
  isClearable = false,
  readonly = false,
}: AppCurrencySelectProps) {
  const { tTitle } = useI18nHelper();
  const options = useMemo(() => {
    return CURRENCY_OPTIONS;
  }, []);

  return (
    <AppSelect<string>
      name={tTitle("currency")}
      options={options}
      width={width}
      value={value}
      isReadOnly={readonly}
      isDisabled={isDisabled}
      onSelect={onSelect}
      isSearchable={false}
      isMultiple={false}
      isClearable={isClearable}
    />
  );
}
