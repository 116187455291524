import { useRecoilValue, useSetRecoilState } from "recoil";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClassParams,
  CellEditingStoppedEvent,
  CellEditRequestEvent,
  CellStyle,
  ColDef,
  EditableCallbackParams,
  GetRowIdParams,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { Box } from "@chakra-ui/react";
import { times, uniq } from "lodash";
import { produce } from "immer";
import {
  CellEditingStartedEvent,
  CellKeyDownEvent,
  CellMouseOutEvent,
  CellMouseOverEvent,
} from "ag-grid-community/dist/lib/events";
import ProductImageCell, {
  PRODUCT_IMAGE_SIZE,
} from "@/features/order-sheet-sets/product-image-cell";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import {
  FlatProduct,
  flatTabsFamily,
  inflatedTabsFamily,
  lineSheetSetFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import useInflatedTabs from "@/features/line-sheet-sets/helpers/use-inflated-tabs";
import ProductCheckboxCell from "@/features/order-sheet-sets/product-checkbox-cell";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import SheetHeader from "@/features/order-sheet-sets/sheet-header";
import SheetCheckBoxHeader from "@/features/order-sheet-sets/sheet-checkbox-header";
import ProductImageHeader from "@/features/order-sheet-sets/product-image-header";
import { MAX_PRODUCT_IMAGE_COUNT } from "@/features/order-sheet-sets/representative-product-image";
import OverlappedValueCell from "@/features/ui/overlapped-value-cell";
import OverlappedNumberEditor from "@/features/ui/overlapped-number-editor";
import OverlappedConditionTypeCell from "@/features/ui/overlapped-condition-type-cell";
import TakeAllProductsCell from "@/features/ui/take-all-products-cell";

interface OrderSheetTabProps {
  lineSheetSetId: number;
  orderSheetSetId: number;
  orderSheetId: number;
  revisionNumber?: number;
  tabIndex: number;
  isLoading: boolean;
  onEditing?: (isEditing: boolean) => void;
  onSelectedProductIdsChange: (ids: number[]) => void;
  onGridReady: (event: GridReadyEvent<FlatProduct>) => void;
  isOrderEditable?: boolean;
  isConfirmedEditable?: boolean;
  isConditionEditable?: boolean;
  onUpdatingInflatedProductComplete: (isCompleted: boolean) => void;
}

function getRowId(params: GetRowIdParams<FlatProduct>) {
  return params.data.rowId;
}

function isEditableCell(isEditable: boolean, params: any) {
  if (isEditable) {
    const rowIndex = params.node.rowIndex;
    const i = parseInt(params.column.getId().split("_")[1]);
    if (rowIndex !== null && rowIndex % 3 === 2) {
      const stockRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
      if (stockRow && stockRow.data && stockRow.data.sizes) {
        const stockQuantity = stockRow.data.sizes[i] as number;
        return stockQuantity === -1 || stockQuantity > 0;
      }
    }
  }
  return false;
}

function OrderSheetTab({
  lineSheetSetId,
  orderSheetSetId,
  orderSheetId,
  revisionNumber,
  tabIndex,
  isLoading,
  isOrderEditable = false,
  onEditing,
  onSelectedProductIdsChange,
  onGridReady,
  isConfirmedEditable = false,
  isConditionEditable = false,
  onUpdatingInflatedProductComplete,
}: OrderSheetTabProps) {
  const { warning: showWarning } = useAppToasts();

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId,
      orderSheetId,
      revisionNumber,
    };
  }, [orderSheetSetId, orderSheetId, revisionNumber]);
  const flatSheetState = useRecoilValue(flatTabsFamily(sheetKey));

  const lineSheetSetState = useRecoilValue(
    lineSheetSetFamily({ lineSheetSetId: lineSheetSetId })
  );

  const rowData = useMemo(() => {
    if (flatSheetState && tabIndex > -1) {
      return flatSheetState.tabs
        .find((tab) => tab.index === tabIndex)
        ?.products.filter((product) => product.isFiltered);
    }
    return undefined;
  }, [flatSheetState, tabIndex]);

  const { updateInflatedProduct, getInflatedProductById } = useInflatedTabs();
  const gridRef = useRef<AgGridReact<FlatProduct> | null>(null);
  const [isGridRefSet, setIsGridRefSet] = useState(false);

  const { t, tTitle, tCurrencyString } = useI18nHelper();

  const [isImagesCollapsed, setIsImagesCollapsed] = useState<boolean>(true);

  const components = useMemo<{ [p: string]: any }>(() => {
    return {
      agColumnHeader: SheetHeader,
    };
  }, []);

  const [colDefs, setColDefs] = useState<ColDef<FlatProduct>[]>([
    {
      cellRenderer: ProductCheckboxCell,
      rowSpan: (params) => {
        if (params.node && params.node.rowIndex !== null) {
          return params.node.rowIndex % 3 === 0 ? 3 : 1;
        }
        return 1;
      },
      width: 40,
      pinned: "left",
      field: "isChecked",
      headerName: "",
      resizable: false,
      suppressMovable: true,
      headerCheckboxSelection: false,
      showDisabledCheckboxes: false,
      headerComponent: SheetCheckBoxHeader,
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 === 0,
        "product-checkbox-cell": (params) => params.rowIndex % 3 === 0,
      },
      headerClass: "product-checkbox-header",
    },
    {
      valueGetter: (params) => {
        if (
          params.data &&
          params.data.imageUrls != null &&
          params.data.imageUrls.length > 0
        ) {
          return params.data.imageUrls;
        }
        return null;
      },
      cellStyle: { fontSize: "12px" },
      cellRenderer: ProductImageCell,
      width: PRODUCT_IMAGE_SIZE,
      autoHeight: false,
      rowSpan: (params) => {
        if (params.node && params.node.rowIndex !== null) {
          return params.node.rowIndex % 3 === 0 ? 3 : 1;
        }
        return 1;
      },
      resizable: false,
      pinned: "left",
      field: "i",
      headerName: "",
      headerClass: "product-image-header",
      headerComponent: ProductImageHeader,
      headerComponentParams: {
        isCollapsed: isImagesCollapsed,
        onClick: (isCollapse: boolean) => {
          setIsImagesCollapsed(isCollapse);
        },
      },
      cellRendererParams: {
        isCollapsed: isImagesCollapsed,
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 === 0,
      },
    },
    {
      field: "brandModelNumberModelName",
      autoHeaderHeight: true,
      width: 120,
      resizable: true,
      pinned: "left",
      cellStyle: { fontSize: "12px" },
      headerComponentParams: {
        items: ["brand", "sku", "model_name"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
      },
    },
    {
      field: "genderCategorySubcategory",
      width: 120,
      resizable: true,
      pinned: "left",
      cellStyle: { fontSize: "12px" },
      headerComponentParams: {
        items: ["gender", "category", "subcategory"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
      },
    },
    {
      field: "colorCodeColorNameMaterial",
      width: 120,
      resizable: true,
      pinned: "left",
      cellStyle: { fontSize: "12px" },
      headerComponentParams: {
        items: ["color_code", "color_name", "material"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
      },
    },
    {
      field: "priceAmount",
      cellStyle: (params): CellStyle => {
        const cellStyle: CellStyle = {
          textAlign: "end",
          fontSize: "12px",
          padding: "0",
        };
        const rowIndex = params.node?.rowIndex;
        if (typeof rowIndex === "number" && rowIndex % 3 === 0) {
          cellStyle.color = "#8F8F8C";
        }
        return cellStyle;
      },
      width: 120,
      resizable: true,
      valueGetter: (params) => {
        const value = params.data?.priceAmount;
        const currency =
          params.data?.priceCurrency ||
          lineSheetSetState?.lineSheetSet?.currency;
        if (value !== undefined) {
          if (typeof value !== "object") {
            if (currency) {
              return tCurrencyString(value, {
                type: "SYMBOL",
                code: currency,
              });
            } else {
              return tCurrencyString(value);
            }
          } else {
            if (currency) {
              return {
                top: tCurrencyString(value.top, {
                  type: "SYMBOL",
                  code: currency,
                }),
                under: tCurrencyString(value.under, {
                  type: "SYMBOL",
                  code: currency,
                }),
              };
            } else {
              return {
                top: tCurrencyString(value.top),
                under: tCurrencyString(value.under),
              };
            }
          }
        }
      },
      cellRendererSelector: (params) => {
        const value = params.data?.priceAmount;
        if (value !== undefined) {
          if (typeof value == "object") {
            {
              return {
                component: OverlappedValueCell,
                params: {
                  topTextAlign: "end",
                },
              };
            }
          }
        }
        return undefined;
      },
      pinned: "left",
      headerComponentParams: {
        items: ["price", "cost", "retail"],
      },
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
        "product-price-cell": (params) => params.rowIndex % 3 === 0,
      },
    },
    {
      field: "priceAdjustedByPartial",
      headerName: "",
      pinned: "left",
      width: 96,
      resizable: false,
      colId: "priceAdjustedByPartial",
      // cellRenderer: PriceAdjustedByCell,
      cellStyle: {
        overflow: "visible",
        padding: "1px",
      },
      editable: (params) => {
        // row 별 설정하려면 여기서?
        return true;
      },
      valueGetter: (params) => {
        //cell re render가 되려면 이 값이 변경되어함,
        //custom cell 에서 value로 접근 가능
        const moldIndex =
          params.node != null && params.node.rowIndex != null
            ? params.node.rowIndex % 3
            : -1;

        if (moldIndex === 2) {
          return params.data?.sizes;
        }

        return params.data?.priceAdjustedByPartial;
      },
      cellRendererSelector: (params) => {
        const rowIndex = params.node.rowIndex;
        if (rowIndex != null) {
          const index = rowIndex % 3;
          if (index === 0) {
            return {
              component: OverlappedConditionTypeCell,
            };
          } else if (index === 1) {
            return {
              component: OverlappedValueCell,
              params: {
                unit: "%",
              },
            };
          } else if (index === 2) {
            return {
              component: TakeAllProductsCell,
            };
          }
        }

        return undefined;
      },
      cellEditor: OverlappedNumberEditor,
      cellEditorParams: {
        unit: "%",
      },
      cellClass: "product-price-adjusted-cell",
      cellClassRules: {
        "product-odd-row": (params) => params.rowIndex % 6 < 3,
        "product-price-adjusted-selectbox-cell": (params) =>
          params.rowIndex % 3 === 0,
        "product-price-adjusted-input-cell": (params) =>
          params.rowIndex % 3 === 1,
        // "product-editable-cell": (params) => {
        //   //여기에서 editable을 접근 못하나?
        //   return params.rowIndex % 3 === 1;
        // },
      },
    },
  ]);

  const maxProductImageCount = useMemo(() => {
    return (
      rowData
        ?.map((i) =>
          Math.min(MAX_PRODUCT_IMAGE_COUNT, (i.imageUrls || []).length)
        )
        .reduce((acc, cur) => Math.max(acc, cur), 0) || 0
    );
  }, [rowData]);

  useEffect(() => {
    setColDefs((prev) => {
      return produce(prev, (draft) => {
        const i = draft.findIndex((colDef) => colDef.field === "i");

        let width;
        if (isImagesCollapsed) {
          width = PRODUCT_IMAGE_SIZE;
        } else {
          width =
            PRODUCT_IMAGE_SIZE * maxProductImageCount +
            (maxProductImageCount - 1) * 4 +
            2;
        }
        if (i > -1) {
          draft[i] = {
            ...draft[i],
            width,
            headerComponentParams: {
              ...draft[i].headerComponentParams,
              isCollapsed: isImagesCollapsed,
              isDisabled:
                maxProductImageCount === 0 || maxProductImageCount === 1,
            },
            cellRendererParams: {
              ...draft[i].cellRendererParams,
              isCollapsed: isImagesCollapsed,
            },
          };
        }
      });
    });
  }, [isImagesCollapsed, maxProductImageCount]);

  useEffect(() => {
    //cell params변경 or colDef수정이 필요한경우,

    setColDefs((prev) => {
      return produce(prev, (draft) => {
        const i = draft.findIndex((c) => c.field === "priceAdjustedByPartial");
        if (i > -1) {
          draft[i].cellRendererParams = {
            tabIndex,
            sheetKey,
            isEditable: isConditionEditable,
            isOrderEditable,
          };

          draft[i].editable = (params) => {
            const moldIndex =
              (params.node.rowIndex != null ? params.node.rowIndex : -1) % 3;
            if (moldIndex === 1) {
              return isConditionEditable;
            } else if (moldIndex === 3) {
              return isOrderEditable;
            }
            return false;
          };

          draft[i].cellClassRules!["product-editable-cell"] = (params) => {
            const moldIndex =
              (params.node.rowIndex != null ? params.node.rowIndex : -1) % 3;
            if (moldIndex === 1) {
              return isConditionEditable;
            }
            return false;
          };

          // draft[i].editable = isOrderEditable || isConfirmedEditable;
        }
      });
    });
  }, [
    tabIndex,
    sheetKey,
    isOrderEditable,
    isConfirmedEditable,
    isConditionEditable,
  ]);

  const sizeLength = useMemo(() => {
    if (rowData) {
      return rowData
        .map((i) => {
          return i.sizes ? i.sizes.length : 0;
        })
        .reduce((acc, cur) => {
          return Math.max(acc, cur);
        }, 0);
    }
    return 0;
  }, [rowData]);

  const isSizeOrQuantityEditable = useCallback(
    (
      params: CellClassParams<FlatProduct> | EditableCallbackParams<FlatProduct>
    ) => {
      const colIndex = parseInt(params.column.getColId().split("_")[1]);
      const index =
        params.node.rowIndex != null ? params.node.rowIndex % 3 : -1;
      if (index === 1) {
        return (
          isConfirmedEditable && params.data?.sizes![colIndex] !== undefined
        );
      } else if (index === 2) {
        return isOrderEditable && params.data?.sizes![colIndex] !== undefined;
      }
      return false;
    },
    [isConfirmedEditable, isOrderEditable]
  );

  useEffect(() => {
    if (sizeLength > 0) {
      setColDefs((prev) => {
        const i = prev.findLastIndex(
          (p) => p.field === "priceAdjustedByPartial"
        );

        if (i > -1) {
          return [
            ...prev.slice(0, i + 1),
            ...((): ColDef<FlatProduct>[] => {
              return times(sizeLength, (index): ColDef<FlatProduct> => {
                return {
                  headerName: `sq${index}`,
                  headerClass: "product-size-or-quantity-cell-header",
                  colId: `sq_${index}`,
                  width: 80,
                  cellStyle: (params) => {
                    return {
                      textAlign: "center",
                      fontSize: "12px",
                      padding: 0,
                    };
                  },
                  valueGetter: (params) => {
                    return params.data?.sizes?.[index] === 0
                      ? ""
                      : params.data?.sizes?.[index];
                  },
                  valueFormatter: (params) => {
                    if (params.value === -1) {
                      return "∞";
                    }
                    return params.value === 0 ? "" : params.value;
                  },
                  valueSetter: (params) => {
                    return true;
                  },
                  cellRenderer: OverlappedValueCell,
                  cellEditor: OverlappedNumberEditor,
                  cellClassRules: {
                    "product-odd-row": (params) => params.rowIndex % 6 < 3,
                    "product-each-last-row": (params) =>
                      params.rowIndex % 3 === 2,
                    "product-editable-cell": (params) => {
                      return isSizeOrQuantityEditable(params);
                    },
                  },
                  editable: (params) => {
                    return isSizeOrQuantityEditable(params);
                  },
                  resizable: true,
                  headerComponentParams: {
                    items: ["size", "stock", "quantity.abbr"],
                  },
                };
              });
            })(),
          ];
        }
        return prev;
      });
    }
  }, [
    tabIndex,
    isOrderEditable,
    sizeLength,
    isConfirmedEditable,
    getInflatedProductById,
    sheetKey,
    updateInflatedProduct,
    isSizeOrQuantityEditable,
  ]);

  const onCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<FlatProduct>) => {
      const data = event.data;
      const colId = event.column.getColId();
      const rowIndex = event.rowIndex === null ? -1 : event.rowIndex;
      const moldIndex = rowIndex % 3;

      const inflatedProduct = getInflatedProductById(
        sheetKey,
        tabIndex,
        data.id
      );

      if (inflatedProduct) {
        if (colId.startsWith("sq")) {
          let newValue = event.newValue;
          if (isNaN(newValue) || newValue === null || newValue === undefined) {
            newValue = 0;
          }
          const colIndex = parseInt(colId.split("_")[1]);
          const newStock = inflatedProduct.newStockWithOptionList
            ? inflatedProduct.newStockWithOptionList[colIndex].quantity
            : null;
          if (
            moldIndex === 2 &&
            newStock != null &&
            newValue > newStock &&
            newStock !== -1
          ) {
            showWarning(
              t("common:apply_order_quantity.messages.error.out_of_range"),
              "apply_price_condition_form"
            );
            return;
          }
          const nextData = produce(inflatedProduct, (draft) => {
            if (moldIndex === 1 && draft.newStockWithOptionList) {
              draft.newStockWithOptionList[colIndex].quantity = newValue;
            } else if (moldIndex === 2) {
              draft.orderQuantityWithOptionList[colIndex].quantity = newValue;
            }
          });

          updateInflatedProduct(sheetKey, tabIndex, nextData);
          onUpdatingInflatedProductComplete(true);
        } else if (colId === "priceAdjustedByPartial") {
          if (moldIndex === 0) {
            //priceAdjustedByPartial
            const nextData = produce(inflatedProduct, (draft) => {
              if (draft.confirmedPriceAdjustedBy) {
                draft.confirmedPriceAdjustedBy.by = event.newValue;
              } else {
                draft.priceAdjustedBy.by = event.newValue;
              }
            });
            updateInflatedProduct(sheetKey, tabIndex, nextData);
            onUpdatingInflatedProductComplete(true);
            return;
          } else if (moldIndex === 1) {
            //nan이면 0처리하자
            let newValue = event.newValue;

            if (
              isNaN(newValue) ||
              newValue === null ||
              newValue === undefined
            ) {
              newValue = 0;
            }

            if (newValue > 100 || newValue < 0) {
              showWarning(
                t(
                  "common:apply_price_condition_form.messages.error.out_of_range"
                ),
                "apply_price_condition_form"
              );
              return;
            }
            const nextData = produce(inflatedProduct, (draft) => {
              if (draft.confirmedPriceAdjustedBy) {
                draft.confirmedPriceAdjustedBy.value = newValue;
              } else {
                draft.priceAdjustedBy.value = newValue;
              }
            });
            updateInflatedProduct(sheetKey, tabIndex, nextData);
            onUpdatingInflatedProductComplete(true);
          } else if (moldIndex === 2) {
            const nextData = produce(inflatedProduct, (draft) => {
              const stockData =
                draft.newStockWithOptionList ||
                draft.stockQuantityWithOptionList;

              const orderData = draft.orderQuantityWithOptionList;

              draft.orderQuantityWithOptionList = stockData.map(
                (stockDatum, index) => {
                  const orderQuantity = orderData[index].quantity;
                  return {
                    name: stockDatum.name,
                    quantity:
                      orderQuantity < stockDatum.quantity
                        ? stockDatum.quantity
                        : orderQuantity,
                  };
                }
              );
            });

            updateInflatedProduct(sheetKey, tabIndex, nextData);
            onUpdatingInflatedProductComplete(true);
          }
        }
      }
    },
    [
      t,
      showWarning,
      sheetKey,
      tabIndex,
      getInflatedProductById,
      updateInflatedProduct,
      onUpdatingInflatedProductComplete,
    ]
  );

  useEffect(() => {
    //page loading => row 변경인데, loading끝 -> rowData미적용 과정이 있네;
    if (gridRef.current && gridRef.current.api !== undefined) {
      const api = gridRef.current.api;
      if (isLoading || rowData === undefined) {
        api.showLoadingOverlay();
      } else if (rowData.length > 0) {
        api.hideOverlay();
      }
    }
  }, [isGridRefSet, isLoading, rowData]);

  const handleGridReady = useCallback(
    (event: GridReadyEvent<FlatProduct>) => {
      onGridReady(event);
    },
    [onGridReady]
  );

  const setInflatedTabsFamily = useSetRecoilState(inflatedTabsFamily(sheetKey));

  /* AG Grid는 cell value의 변경을 감지하려면 onCellEditRequest 이벤트를 사용해야하는데,
   * onCellEditRequest 는 value의 변경이 완료된 이후 발생하는 이벤트임. (커서를 다른 셀로 옮기거나 엔터를 누르거나 등)
   * 따라서 value의 변경을 즉시 감지하려면 onCellKeyDown 이벤트를 사용해야함.
   * onCellValueChanged 콜백이 있으나 동작하지 않음. (https://www.ag-grid.com/javascript-data-grid/grid-events/#reference-editing-cellValueChanged)
   */
  const onCellKeyDown = useCallback(
    (e: CellKeyDownEvent) => {
      if (onEditing) {
        const keyboardEvent = e.event as KeyboardEvent;
        const colDef = e.column.getColDef();
        const isEditable =
          typeof colDef.editable === "function"
            ? colDef.editable(e)
            : colDef.editable === true;
        // 숫자, 백스페이스, . 입력시에만 값이 변경되었다고 보고 isDirty를 true로 변경
        if (
          isEditable &&
          (!isNaN(keyboardEvent.key as any) ||
            ["Backspace", "."].includes(keyboardEvent.key))
        ) {
          setInflatedTabsFamily((prev) => {
            if (prev) {
              const newTabs = prev.tabs.map((tab) => {
                return {
                  ...tab,
                  isDirty: true,
                };
              });
              return {
                key: sheetKey,
                tabs: newTabs,
              };
            }
          });
        }
      }
    },
    [onEditing, setInflatedTabsFamily, sheetKey]
  );

  const onCellEditingStarted = useCallback(
    (e: CellEditingStartedEvent) => {
      if (onEditing) {
        onEditing(true);
      }
    },
    [onEditing]
  );

  const onCellEditingStopped = useCallback(
    (e: CellEditingStoppedEvent) => {
      if (onEditing) {
        onEditing(false);
      }
    },
    [onEditing]
  );

  const defaultColDef = useMemo((): ColDef<FlatProduct> => {
    return {
      autoHeight: false,
      cellStyle: { fontSize: "12px", padding: "0" },
    };
  }, []);

  const handleSetGridRef = useCallback(
    (ref: AgGridReact<FlatProduct> | null) => {
      gridRef.current = ref;
      if (ref && !isGridRefSet) {
        setIsGridRefSet(true);
      }
    },
    [isGridRefSet, setIsGridRefSet]
  );

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent<FlatProduct>) => {
      onSelectedProductIdsChange(
        uniq(event.api.getSelectedRows().map((row) => row.id))
      );
      event.api.refreshCells({
        force: true,
        columns: ["isChecked"],
      });
    },
    [onSelectedProductIdsChange]
  );

  const handleMouseOver = useCallback(
    (event: CellMouseOverEvent<FlatProduct>) => {
      const rowIndex = event.node.rowIndex;
      if (rowIndex !== null && rowIndex % 3 === 0) {
        if (
          event.colDef.headerClass &&
          (event.colDef.headerClass
            .toString()
            .includes("product-checkbox-header") ||
            event.colDef.headerClass
              .toString()
              .includes("product-image-header"))
        ) {
          const agPinnedLeftColsElement = document.querySelector(
            ".ag-pinned-left-cols-container"
          );
          if (agPinnedLeftColsElement !== null) {
            const secondRowElement = agPinnedLeftColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.add("ag-row-hover");
            thirdRowElement?.classList.add("ag-row-hover");
          }
          const agCenterColsElement = document.querySelector(
            ".ag-center-cols-container"
          );
          if (agCenterColsElement !== null) {
            const secondRowElement = agCenterColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.add("ag-row-hover");
            thirdRowElement?.classList.add("ag-row-hover");
          }
        }
      }
    },
    []
  );

  const handleMouseOut = useCallback(
    (event: CellMouseOutEvent<FlatProduct>) => {
      const rowIndex = event.node.rowIndex;
      if (rowIndex !== null && rowIndex % 3 === 0) {
        if (
          event.colDef.headerClass &&
          (event.colDef.headerClass
            .toString()
            .includes("product-checkbox-header") ||
            event.colDef.headerClass
              .toString()
              .includes("product-image-header"))
        ) {
          const agPinnedLeftColsElement = document.querySelector(
            ".ag-pinned-left-cols-container"
          );
          if (agPinnedLeftColsElement !== null) {
            const secondRowElement = agPinnedLeftColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.remove("ag-row-hover");
            thirdRowElement?.classList.remove("ag-row-hover");
          }
          const agCenterColsElement = document.querySelector(
            ".ag-center-cols-container"
          );
          if (agCenterColsElement !== null) {
            const secondRowElement = agCenterColsElement.querySelector(
              `div.ag-row[row-index="${rowIndex + 1}"]`
            );
            const thirdRowElement = secondRowElement?.nextElementSibling;
            secondRowElement?.classList.remove("ag-row-hover");
            thirdRowElement?.classList.remove("ag-row-hover");
          }
        }
      }
    },
    []
  );

  return (
    <Box
      className={"ag-theme-alpine"}
      paddingBottom={"16px"}
      height={"100%"}
      width={"100%"}
    >
      <AgGridReact<FlatProduct>
        ref={handleSetGridRef}
        suppressRowTransform={true}
        readOnlyEdit={true}
        getRowId={getRowId}
        headerHeight={24}
        columnDefs={colDefs}
        rowData={rowData}
        //왜 25를 해야 24가 되는거야?;;
        rowHeight={25}
        rowModelType={"clientSide"}
        onCellKeyDown={onCellKeyDown}
        onCellEditRequest={onCellEditRequest}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        defaultColDef={defaultColDef}
        onGridReady={handleGridReady}
        rowSelection={"multiple"}
        suppressRowClickSelection={true}
        onSelectionChanged={handleSelectionChanged}
        onCellMouseOver={handleMouseOver}
        onCellMouseOut={handleMouseOut}
        enableCellTextSelection={true}
        components={components}
        suppressRowHoverHighlight={false}
        columnHoverHighlight={true}
      />
    </Box>
  );
}

// OrderSheetTab.whyDidYouRender = true;

export default OrderSheetTab;
